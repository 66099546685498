import { useStakingPosition } from '@app/hooks/positions/useStakingPosition';
import { useVaults } from '@app/hooks/plugins/useVaults';
import { useMemo } from 'react';
import { useKpNFTPendingRewards } from '@app/hooks/positions/useKpNFTPendingRewards';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { Address } from '@thirdweb-dev/sdk';
import { PairToken } from '@app/types/pool';
import { useConfig } from '@app/config';
import { useAllProvidersVaults } from '@app/hooks/pools/useAllProvidersVaults';

export function useKpNftPositionDetails(
  tokenId: string,
  poolAddress: Address,
  lpTokenId: string
) {
  const { data, isLoading } = useStakingPosition({
    tokenId,
    poolAddress
  });

  const config = useConfig();
  const { data: vaults } = useAllProvidersVaults();

  const vault = useMemo(() => {
    if (!vaults || !lpTokenId) {
      return;
    }

    return vaults.find(
      item => item.lpToken.id?.toLowerCase() === lpTokenId.toLowerCase()
    );
  }, [vaults, lpTokenId]);

  const { data: pendingRewardsAmount } = useKpNFTPendingRewards(
    poolAddress,
    tokenId
  );

  const pendingRewards = useTokenPriceV4(
    config?.CONTRACTS.KIM!,
    pendingRewardsAmount
  );

  return {
    usdAmount:
      data?.amount && vault ? +data.amount * vault.lpToken.lpTokenUSD : null,
    pendingRewards,
    vault,
    data,
    isLoading
  };
}
