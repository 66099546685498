import React, { FC } from 'react';
import { TokensPair } from '@app/components/TokensPair';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { TokenIcon } from '@app/components/TokenIcon';
import { PairToken } from '@app/types/pool';
import { Vault } from '@app/hooks/plugins/useVaults';

interface Props {
  tokenDetails: PairToken | Vault;
  tokenId: string;
  title: string;
  subtitle: string;
}

export const KpNFTControlsHeader: FC<Props> = ({
  tokenDetails,
  tokenId,
  title,
  subtitle
}) => {
  return (
    <>
      <HStack gap="12px" justifyContent="center">
        {'lpToken' in tokenDetails ? (
          <>
            <TokensPair
              token0Symbol={tokenDetails.token0.symbol}
              token1Symbol={tokenDetails.token1.symbol}
            />
            <VStack alignItems="flex-start" gap={0}>
              <Text fontSize="16px" lineHeight="26px" color="white">
                {tokenDetails.token0.symbol}-{tokenDetails.token1.symbol}
              </Text>
              <Text fontSize="14px" lineHeight="26px" color="gray.4">
                ID: {tokenId}
              </Text>
            </VStack>
          </>
        ) : (
          <>
            <TokenIcon
              symbol={tokenDetails.symbol}
              width="40px"
              height="40px"
            />
            <VStack alignItems="flex-start" gap={0}>
              <Text fontSize="16px" fontWeight={500} color="white">
                {tokenDetails.symbol}
              </Text>
              <Text fontSize="14px" color="gray.4">
                ID: {tokenId}
              </Text>
            </VStack>
          </>
        )}
      </HStack>
      <Text
        fontSize="22px"
        fontWeight={700}
        color="primaryWhite"
        mt={4}
        textAlign="center"
      >
        {title}
      </Text>
      <Text
        fontSize="14px"
        fontWeight={700}
        color="gray.4"
        mt={3}
        mb={10}
        textAlign="center"
      >
        {subtitle}
      </Text>
    </>
  );
};
