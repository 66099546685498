import { VStack, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

type Props = {
  selectedOptionId: string;
  isOpen: boolean;
};

type AuctionInfo = {
  name: string;
  description: string;
};

const AUCTION_TYPES: AuctionInfo[] = [
  {
    name: 'Buy it now',
    description: 'One single price for a buyer'
  },
  {
    name: 'Buy it now with bids:',
    description:
      'There can only be one active offer and each new offer must be higher than the last in a similar way to English auctions.'
  },
  {
    name: 'English Auction:',
    description:
      'English Auctions: During the start and end of the auction bidders will outbid each other until the bidding window closes.' +
      ' The auction can then be settled by anyone if there is a valid bid sending it to the winner or the seller can claim back their NFT.'
  },
  {
    name: 'Dutch Auctions:',
    description:
      'Between the start and end of the auction the price will linearly decrease towards the reserve.' +
      ' The first bid above or equal to the price at the time of the auction automatically wins the auction and will receive the NFT.' +
      ' Price is decreasing per second during the auction dropping from the start price defined in the listing as `price` towards the lowest price defined as the `reserve` in the listing.'
  },
  {
    name: 'Blind Auctions:',
    description:
      'Blind auctions are 2 stages. Between the start and the end, users submit their hashed bids.' +
      ' After the end there will be a period, say `24 hours` or otherwise specified in the contract, to reveal their bid.' +
      ' The smart contract will record the highest bidder at the end of the reveal period (escrowing in the funds) and then anyone can settle the auction with the same function that will settle English auctions too.'
  }
];

const getDescription = (id: number) => {
  return AUCTION_TYPES[id - 1];
};

export const AuctionTypeDescription: FC<Props> = ({
  isOpen,
  selectedOptionId
}) => {
  let selectedAuction = useMemo(
    () => getDescription(Number(selectedOptionId)),
    [selectedOptionId]
  );

  return isOpen ? (
    <VStack padding="0 8px" alignItems="flex-start" gap="4px">
      <Text
        color="neutral.300"
        fontWeight={500}
        fontSize="14px"
        lineHeight="18px"
      >
        {selectedAuction.name}
      </Text>
      <Text fontWeight={400} fontSize="14px" lineHeight="18px">
        {selectedAuction.description}
      </Text>
    </VStack>
  ) : null;
};
