import { IDerivedMintInfoWithProvider } from '@app/state/mintStore';
import { useContract } from '@thirdweb-dev/react-core';
import { useAsync } from 'react-use';

import nftPool from '../../../abis/nftPool.json';

export function useLockDetails(
  nftPoolAddress: string | null | undefined,
  lockDuration: number
) {
  const { contract } = useContract(nftPoolAddress, nftPool);

  const { value } = useAsync(async () => {
    if (!contract) {
      return;
    }

    try {
      const res = await contract.call('getMultiplierSettings');

      const r = (
        (+res.maxLockMultiplier.toString() * (lockDuration * 3600 * 24)) /
        +res.maxLockDuration.toString() /
        100
      ).toFixed(2);

      const bonusMultiplier = (1 + +r / 100).toFixed(2);

      return {
        lockBonus: r,
        maxLockDuration: res.maxLockDuration.toString(), // seconds,
        bonusMultiplier
      };
    } catch (e) {
      console.error(e);
    }
  }, [contract, lockDuration]);

  return {
    lockBonus: value?.lockBonus ?? '',
    maxLockDuration: value?.maxLockDuration ?? '',
    bonusMultiplier: value?.bonusMultiplier ?? ''
  };
}
