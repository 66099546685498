import { useMarketMakerPools } from '@app/hooks/merkl/useMarketMakerPools';
import { Address } from '@thirdweb-dev/sdk';
import { useMemo } from 'react';
import { Campaign } from '@app/hooks/merkl/useMerklCampaigns';
import { MetromCampaign } from '@app/hooks/metrom/useMetromCampaigns';

function getMetromCampaignFromPools(
  marketMakerPools: (MetromCampaign & { amountUSD: number })[],
  token0: string | undefined,
  token1: string | undefined
) {
  return marketMakerPools.filter(
    c =>
      (c.pool.token0.address.toLowerCase() === token0?.toLowerCase() &&
        c.pool.token1.address.toLowerCase() === token1?.toLowerCase()) ||
      (c.pool.token1.address.toLowerCase() === token0?.toLowerCase() &&
        c.pool.token0.address.toLowerCase() === token1?.toLowerCase())
  );
}

function getCampaignFromPools(
  marketMakerPools: (Campaign & { amountUSD: number })[],
  token0: string | undefined,
  token1: string | undefined
) {
  return marketMakerPools.filter(
    c =>
      (c.campaignParameters.token0.toLowerCase() === token0?.toLowerCase() &&
        c.campaignParameters.token1.toLowerCase() === token1?.toLowerCase()) ||
      (c.campaignParameters.token1.toLowerCase() === token0?.toLowerCase() &&
        c.campaignParameters.token0.toLowerCase() === token1?.toLowerCase())
  );
}

export function useGetMarketMakerCampaign(
  token0: Address | undefined,
  token1: Address | undefined,
  poolAddress: Address | undefined
) {
  const marketMakerPools = useMarketMakerPools();

  return useMemo(() => {
    if (!marketMakerPools) {
      return {
        isMarketMaker: false,
        marketMakerCampaign: []
      };
    }

    const merklMarketMakerCampaign = getCampaignFromPools(
      marketMakerPools.merkl,
      token0,
      token1
    );

    const metromMarketMakerCampaign = getMetromCampaignFromPools(
      marketMakerPools.metrom,
      token0,
      token1
    );

    const isMarketMaker = !!(
      merklMarketMakerCampaign?.find(
        item =>
          item.campaignParameters.poolAddress.toLowerCase() ===
            poolAddress?.toLowerCase() ?? ''
      ) ||
      metromMarketMakerCampaign?.find(
        item => item.pool.address.toLowerCase() === poolAddress?.toLowerCase()
      )
    );

    return {
      isMarketMaker,
      merklMarketMakerCampaign,
      metromMarketMakerCampaign
    };
  }, [marketMakerPools, poolAddress, token0, token1]);
}

export function getMarketMakerCampaign(
  marketMakerPools: (Campaign & { amountUSD: number })[],
  token0: string | undefined,
  token1: string | undefined
) {
  return getCampaignFromPools(marketMakerPools, token0, token1);
}

export function getMetromMarketMakerCampaign(
  marketMakerPools: (MetromCampaign & { amountUSD: number })[],
  token0: string | undefined,
  token1: string | undefined
) {
  return getMetromCampaignFromPools(marketMakerPools, token0, token1);
}
