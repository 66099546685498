import { SearchIcon, CloseIcon } from '@chakra-ui/icons';

import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text
} from '@chakra-ui/react';
import cn from 'clsx';
import React, { ChangeEvent, FC } from 'react';

import s from './Search.module.scss';
import { Icon } from '@app/components/Icon';

interface Props {
  className?: string;
  onSearch: (val: string) => void;
  placeholder?: string;
  value: string;
  title?: string;
}

export const SearchControlled: FC<Props> = ({
  className,
  onSearch,
  placeholder = 'Search',
  value,
  title
}) => {
  const changeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;

    onSearch(value?.trim());
  };

  return (
    <Box className={cn(s.root, className)} height="44px">
      {title && (
        <Text fontSize={14} color="neutral.300" position="absolute" top="-24px">
          {title}
        </Text>
      )}
      <HStack className={s.wrapper}>
        <Box className={s.iconWrapper}>
          <SearchIcon w="20px" h="20px" />
        </Box>
        <InputGroup>
          <Input
            variant="filled"
            borderRadius="8px"
            bg="neutral.700"
            fontSize="xl"
            p="8px 12px"
            border="none"
            color="gray.100"
            size="lg"
            gridArea="input"
            _focus={{
              outline: 'none'
            }}
            _hover={{ bg: 'neutral.700' }}
            onChange={changeHandler}
            _placeholder={{ color: 'neutral.500' }}
            placeholder={placeholder}
            value={value}
          />
          {value && (
            <InputRightElement p={3} height="100%">
              <IconButton aria-label="clear" onClick={() => onSearch('')}>
                <CloseIcon />
              </IconButton>
            </InputRightElement>
          )}
        </InputGroup>
      </HStack>
    </Box>
  );
};
