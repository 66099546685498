import React, { useMemo } from 'react';
import {
  Box,
  Button,
  HStack,
  VStack,
  Text,
  ModalOverlay,
  Modal,
  ModalContent
} from '@chakra-ui/react';
import { FaExchangeAlt } from 'react-icons/fa';
import { FaEllipsis, FaSuitcase, FaListUl } from 'react-icons/fa6';
import { APP_ROUTES } from '@app/constants/routes';
import { GiToken } from 'react-icons/gi';
import { useMedia, useToggle } from 'react-use';
import { useRouter } from 'next/router';

import s from './MobileAppNav.module.scss';
import clsx from 'clsx';
import { mobileMenuItems } from '@app/components/AppHeader/components/DesktopMenu';
import Link from 'next/link';
import { AirdropClaimBanner } from '../AirdropClaimBanner/AirdropClaimBanner';
import { ReferralCodeBanner } from '@app/components/ReferralCodeBanner';
import { Socials } from '@app/components/AppHeader/components/Socials';
import { useConfig } from '@app/config';

export const MobileAppNav = () => {
  const isMobile = useMedia('(max-width: 960px)', false);
  const router = useRouter();
  const config = useConfig();

  const [open, toggleOpen] = useToggle(false);

  const navItems = useMemo(() => {
    return [
      {
        label: 'Positions',
        icon: <FaSuitcase />,
        link: APP_ROUTES.positions
      },
      {
        label: 'Pools',
        icon: <FaListUl />,
        link: APP_ROUTES.poolsPage
      },
      {
        label: 'Swap',
        icon: <FaExchangeAlt size={28} />,
        link: APP_ROUTES.swapPage,
        isMain: true
      },
      {
        label: 'xKIM',
        icon: <GiToken />,
        link: APP_ROUTES.kim,
        isDisabled: !config?.FLAGS.showRyoPages
      },
      {
        label: 'More',
        icon: <FaEllipsis />,
        action: toggleOpen
      }
    ];
  }, [config?.FLAGS.showRyoPages, toggleOpen]);

  if (!isMobile) {
    return false;
  }

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      backgroundColor="neutral.800"
      borderTop="1px solid"
      borderColor="neutral.500"
      padding="12px 8px"
      className={s.root}
    >
      <HStack width="100%" alignItems="center" justifyContent="space-between">
        {navItems.map(item => {
          const isActive = item.link && router.pathname.includes(item.link);

          return (
            <Button
              isDisabled={item.isDisabled}
              key={item.label}
              width="100%"
              variant="transparent"
              onClick={() => {
                if (item.action) {
                  item.action();
                } else {
                  router.push(item.link);
                }
              }}
            >
              <VStack
                position="relative"
                className={clsx({
                  [s.main]: item.isMain,
                  [s.active]: isActive
                })}
              >
                {item.icon}
                <Text fontSize={item.isMain ? '14px' : '10px'}>
                  {item.label}
                </Text>
              </VStack>
            </Button>
          );
        })}
        <Modal
          isOpen={open}
          onClose={() => {
            toggleOpen(false);
          }}
          isCentered
          motionPreset="slideInBottom"
          size="xs"
        >
          <ModalOverlay />
          <ModalContent
            sx={{
              maxWidth: ['100vw', '586px'],
              padding: '0px',
              borderRadius: '24px 24px 0 0'
            }}
          >
            <Box p="24px" maxHeight="90dvh" position="relative">
              <Box
                sx={{
                  width: 200,
                  height: '8px',
                  borderRadius: '8px',
                  backgroundColor: 'neutral.500',
                  position: 'absolute',
                  top: '8px',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
              />
              <Box overflow="auto" maxHeight="85dvh">
                {mobileMenuItems(config)
                  .filter(item => item.show)
                  .map(item => {
                    return (
                      <Box key={item.label} padding="10px 12px">
                        <Link
                          href={item.link}
                          onClick={() => toggleOpen(false)}
                        >
                          <VStack alignItems="flex-start" gap={1}>
                            <Text fontSize="16px">{item.label}</Text>
                            <Text fontSize="12px" color="neutral.400">
                              {item.description}
                            </Text>
                          </VStack>
                        </Link>
                      </Box>
                    );
                  })}
                {config?.FLAGS.showAirdropClaim && (
                  <Box padding="16px 12px">
                    <AirdropClaimBanner type="mobile" />
                  </Box>
                )}

                <Box
                  padding="16px 12px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <ReferralCodeBanner type="mobile" />
                  <Socials />
                </Box>
              </Box>
            </Box>
          </ModalContent>
        </Modal>
      </HStack>
    </Box>
  );
};
