import { useChain } from '@thirdweb-dev/react';
import {
  Scroll,
  Base,
  Ethereum,
  Optimism,
  Arbitrum,
  Mode,
  Chain
} from '@thirdweb-dev/chains';

export function useChainExplorer(chainId?: number | null) {
  const _chain = useChain();
  const chain = chainId ? getChainById(chainId) : _chain;

  const explorer = chain?.explorers ? chain?.explorers[0] : null;

  return { explorer };
}

function getChainById(chainId: number): Chain | undefined {
  return [Scroll, Base, Ethereum, Optimism, Arbitrum, Mode].find(
    c => c.chainId === chainId
  );
}
