import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  HStack,
  Button
} from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { LabeledValue } from '@app/components/LabeledValue';
import { DebouncedInput } from '@app/components/DebouncedInput';
import { Icon } from '@app/components/Icon';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { SubmitButton } from '@app/components/SubmitButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { Vault } from '@app/hooks/plugins/useVaults';
import { useBoost } from '@app/screens/booster/components/AddToPosition/hooks';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { formatNumber } from '@app/helpers/format';
import { useBoosterAllocation } from '@app/hooks/booster/useBoosterAllocation';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
  totalApr: number | undefined;
  lockBonusApr: number | undefined;
  farmBaseApr: number | undefined;
  swpaFeesApr: number | undefined;
}

export const AddToPositionModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  poolAddress,
  onResult,
  totalApr,
  farmBaseApr,
  swpaFeesApr
}) => {
  const isMismatchedNetwork = useNetworkMismatch();

  const {
    state,
    handleAmountChange,
    loadingTokenBalance,
    tokenBalance,
    resetStateValues,
    submit,
    submitting,
    requiresApproval,
    getAllowance,
    approve,
    approving
  } = useBoost(tokenDetails, poolAddress, tokenId);

  const {
    positionPoolShare,
    poolBoostShare,
    multiplier,
    currentMultiplier,
    userAllocation
  } = useBoosterAllocation(poolAddress, tokenId, +state.amount);

  const isBalanceExceeded =
    tokenBalance !== undefined && +tokenBalance < +state.amount;

  function renderInfoRow(label: string, value0: string, value1?: string) {
    return (
      <HStack justifyContent="space-between">
        <Text
          fontSize="14px"
          fontWeight={400}
          lineHeight="26px"
          color="neutral.400"
        >
          {label}
        </Text>
        <HStack>
          <Text
            fontSize="14px"
            fontWeight={500}
            lineHeight="26px"
            color="neutral.400"
          >
            {value0}
          </Text>
          {value1 && (
            <>
              <Icon
                name="arrowRight"
                style={{ color: 'var(--chakra-colors-gray-4)' }}
              />
              <Text
                fontSize="14px"
                fontWeight={500}
                lineHeight="26px"
                color="white"
              >
                {value1}
              </Text>
            </>
          )}
        </HStack>
      </HStack>
    );
  }

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    if (requiresApproval && !isBalanceExceeded) {
      return (
        <SubmitButton
          mt={0}
          loadingText="Processing..."
          onClick={async () => {
            try {
              const res = await approve();

              await getAllowance();

              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Successfully approved KIM`,
                <ExternalLink txHash={txHash} />
              );
            } catch (e) {
              if (e instanceof TransactionError) {
                showErrorToast(e.reason);
              } else {
                showErrorToast(`Error approving KIM`);
              }
            }
          }}
          isLoading={approving}
          label="Approve"
        />
      );
    }

    return (
      <SubmitButton
        mt={0}
        disabled={isBalanceExceeded || !+state.amount}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              resetStateValues();

              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction successful`,
                <ExternalLink txHash={txHash} />
              );

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Boost"
      />
    );
  }

  const bonusAprCurrent =
    farmBaseApr && currentMultiplier ? farmBaseApr * currentMultiplier : 0;
  const bonusAprNext = farmBaseApr && multiplier ? farmBaseApr * multiplier : 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '0px' }}>
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Boost your position"
            subtitle="Allocate xKIM to your kpNFT for more yield"
          />
          <HStack justifyContent="space-between" mt={6}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Amount
            </Text>
            <LabeledValue
              label={`My xKIM:`}
              isLoading={loadingTokenBalance}
              value={
                tokenBalance !== undefined ? formatNumber(+tokenBalance) : '0'
              }
            />
          </HStack>
          <HStack
            justifyContent="space-between"
            mt="12px"
            sx={{
              paddingRight: '14px',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: 'gray.5',
              background: 'black'
            }}
          >
            <DebouncedInput
              amount={state.amount}
              onChange={handleAmountChange}
              textAlign="left"
            />
            <Button
              variant="tertiary"
              onClick={() => {
                if (tokenBalance === undefined) {
                  return;
                }

                handleAmountChange(tokenBalance.toString());
              }}
              sx={{
                color: 'orange',
                bg: 'gray.2',
                width: 'fit-content',
                fontSize: '14px',
                padding: '4px 12px'
              }}
            >
              MAX
            </Button>
          </HStack>
          <HStack justifyContent="space-between" mt={6}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Estimates
            </Text>
          </HStack>
          {renderInfoRow(
            'Boost multiplier',
            `x1`,
            `x${formatNumber(1 + (multiplier ?? 0), 2)}`
          )}
          <br />
          {renderInfoRow(
            'Boost allocation',
            `${formatNumber(userAllocation ? userAllocation : 0)} xKIM`,
            `${formatNumber(
              userAllocation ? +userAllocation + +state.amount : +state.amount
            )} xKIM`
          )}
          {renderInfoRow(
            'Position pool share',
            `${formatNumber(positionPoolShare ? positionPoolShare : 0)}%`
          )}
          {renderInfoRow(
            'Pool boost share',
            `${formatNumber(poolBoostShare ? poolBoostShare : 0)}%`
          )}
          <br />
          {renderInfoRow(
            'Total APR',
            `${totalApr ? formatNumber(totalApr + bonusAprCurrent) : 0}%`,
            `${totalApr ? formatNumber(totalApr + bonusAprNext) : 0}%`
          )}
          {renderInfoRow(
            'Farm Base APR',
            `${farmBaseApr ? formatNumber(farmBaseApr) : 0}%`
          )}
          {renderInfoRow(
            'Bonus APR',
            `${formatNumber(bonusAprCurrent)}%`,
            `${formatNumber(bonusAprNext)}%`
          )}
          {renderInfoRow(
            'Swap Fees APR',
            `${swpaFeesApr ? formatNumber(swpaFeesApr) : 0}%`
          )}
          <HStack mt={6}>
            <Button
              variant="secondary"
              onClick={() => {
                resetStateValues();
                onClose();
              }}
              w="100%"
            >
              Cancel
            </Button>
            {renderSubmit()}
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};
