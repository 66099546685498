import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { TokenInfo } from '@app/types/token';
import axios from 'axios';
import { useConfig } from '@app/config';

export async function fetcher(
  tokenAddress: string | undefined,
  apiUrl: string | undefined
) {
  if (!tokenAddress || !apiUrl) {
    return null;
  }

  const { data } = await axios.get<TokenInfo>(
    `${apiUrl}/tokens/${tokenAddress}`
  );

  return data;
}

type AllowanceParams = {
  tokenAddress: string | undefined;
};

export function useTokenInfo({ tokenAddress }: AllowanceParams) {
  const config = useConfig();

  return useQuery(
    [QueryKeys.TOKEN_INFO, tokenAddress, config?.URLS.explorerApiUrl],
    () => fetcher(tokenAddress, config?.URLS.explorerApiUrl),
    {
      enabled: Boolean(tokenAddress)
    }
  );
}
