import { useAddress, useContract } from '@thirdweb-dev/react';
import { useAsyncFn } from 'react-use';
import nftPoolAbi from 'abis/nftPool.json';
import { QueryKeys } from '@app/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';

export function useStakeIntoNitroPool(
  poolAddress: string,
  tokenId: string,
  nitroPoolAddress: string
) {
  const address = useAddress();
  const queryClient = useQueryClient();

  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address || !nitroPoolAddress) {
      return;
    }

    // on NFT pool => safeTransferFrom (myAddr, nitroPoolAddress, nft token Id, '')

    const res = await poolContract?.call('safeTransferFrom', [
      address,
      nitroPoolAddress,
      tokenId
    ]);

    await queryClient.invalidateQueries([QueryKeys.FETCH_NFT_TOKENS]);
    await queryClient.invalidateQueries([QueryKeys.NITRO_POSITIONS_LIST]);
    await queryClient.invalidateQueries([QueryKeys.SP_NFTS_POSITIONS]);
    await queryClient.invalidateQueries([QueryKeys.POOL_TOKEN_IDS]);

    return res;
  }, [poolContract, address, nitroPoolAddress, tokenId, queryClient]);

  return {
    submit,
    submitting
  };
}
