import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants/queryKeys';

import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { useConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export type MetromCampaign = {
  chainId: string;
  id: string;
  from: number;
  to: number;
  createdAt: number;
  snapshottedAt: number;
  pool: {
    amm: 'kim';
    address: string;
    token0: {
      address: string;
      decimals: string;
      symbol: string;
      name: string;
    };
    token1: {
      address: string;
      decimals: string;
      symbol: string;
      name: string;
    };
    fee: string;
    tvl: null;
  };
  specification: null;
  rewards: [
    {
      address: string;
      decimals: string;
      symbol: string;
      name: string;
      amount: string;
      remaining: string;
      usdPrice: null;
    }
  ];
  apr: null;
};

export type MetromCampaignsResponse = {
  campaigns: MetromCampaign[];
};

const fetcher = async (
  apiUrl: string | undefined,
  chainId: number | undefined,
  params: { showExpired: boolean } = { showExpired: false }
) => {
  if (!chainId || !apiUrl) {
    return null;
  }

  const queryParams = {
    chainIds: chainId,
    amm: 'kim'
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true
  });

  const { data } = await axios.get<
    void,
    AxiosResponse<MetromCampaignsResponse>
  >(`${apiUrl}/campaigns?${query}`);

  const now = Date.now();

  return data.campaigns.filter(
    c =>
      c.from * 1000 <= now &&
      (params.showExpired ? true : c.to * 1000 >= now) &&
      +c.chainId === +chainId
  );
};

export const useMetromCampaigns = (params?: { showExpired: boolean }) => {
  const config = useConfig();
  const apiUrl = config?.URLS.metromApiUrl;
  const chainId = useSupportedChain()?.chainId;

  return useQuery<MetromCampaign[] | undefined | null>(
    [QueryKeys.METROM_CAMPAIGNS, apiUrl, params?.showExpired],
    async () => {
      return await fetcher(apiUrl, chainId, params);
    },
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      enabled: !!(apiUrl && chainId)
    }
  );
};
