import React, { FC, useMemo } from 'react';
import { HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { formatNumber, toOptionalFixed } from '@app/helpers/format';
import { formatUSD } from '@app/helpers/formatUSD';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@app/components/Tooltip';

interface Props {
  totalApr: number | undefined;
  swapFeesApr?: number | undefined;
  lockBonusApr: number | undefined;
  farmBaseApr: number;
  marketMakerAPR?: number | undefined;
  yieldBonusApr?: number;
}

export const APRSection: FC<Props> = ({
  totalApr,
  farmBaseApr,
  lockBonusApr,
  swapFeesApr,
  marketMakerAPR,
  yieldBonusApr
}) => {
  const aprValues = useMemo(() => {
    const tooltip = [];
    let _apr = ((totalApr || swapFeesApr) ?? 0) + (marketMakerAPR ?? 0);

    if (_apr) {
      tooltip.push({
        label: `Total APR`,
        value: `${formatNumber(_apr, 2)}%`,
        color: 'white'
      });
    }

    if (swapFeesApr) {
      tooltip.push({
        label: `Swap Fees APR`,
        value: `${formatNumber(swapFeesApr, 2)}%`,
        color: 'white'
      });
    }

    if (farmBaseApr) {
      tooltip.push({
        label: `Farm base APR`,
        value: `${formatNumber(farmBaseApr, 2)}%`,
        color: 'white'
      });
    }

    if (yieldBonusApr) {
      tooltip.push({
        label: `Yield Booster APR`,
        value: `${formatNumber(yieldBonusApr, 2)}%`,
        color: 'white'
      });
    }

    if (marketMakerAPR) {
      tooltip.push({
        label: `Market Maker APR`,
        value: `${formatNumber(marketMakerAPR, 2)}%`,
        color: 'warning.100'
      });
    }

    if (lockBonusApr) {
      tooltip.push({
        label: `Lock bonus APR`,
        value: `${formatNumber(lockBonusApr, 2)}%`,
        color: 'white'
      });
    }

    return {
      apr: _apr,
      tooltip
    };
  }, [
    totalApr,
    swapFeesApr,
    marketMakerAPR,
    farmBaseApr,
    lockBonusApr,
    yieldBonusApr
  ]);

  return (
    <HStack alignItems="center" gap="4px">
      <Text fontSize="14px" lineHeight="26px" color="gray.4">
        {aprValues.apr ? `${formatNumber(aprValues.apr, 2)}%` : '-'}
      </Text>
      <Tooltip
        tooltip={
          <Stack p={0}>
            {aprValues.tooltip?.map(item => {
              return (
                <HStack
                  key={item.label}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={8}
                >
                  <Text fontSize="14px" color="neutral.400">
                    {item.label}
                  </Text>
                  <Text color={item.color} fontSize="14px">
                    {item.value}
                  </Text>
                </HStack>
              );
            })}
          </Stack>
        }
        placement="top"
        zIndex={10000}
      >
        <FaInfoCircle color="#707070" />
      </Tooltip>
    </HStack>
  );
};
