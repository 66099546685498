import { useContract } from '@thirdweb-dev/react-core';
import nftPoolAbi from '../../../abis/nftPool.json';
import yieldBoosterAbi from '../../../abis/YieldBooster.json';
import { useAsync } from 'react-use';
import { ethers } from 'ethers';
import { useAddress } from '@thirdweb-dev/react';
import { useConfig } from '@app/config';

export function useBoosterAllocation(
  nftPoolAddress: string,
  tokenId: string,
  amount: number
) {
  const config = useConfig();
  const { contract: nftPoolContract } = useContract(nftPoolAddress, nftPoolAbi);
  const { contract: boosterContract } = useContract(
    config?.CONTRACTS.YIELD_BOOSTER,
    yieldBoosterAbi.abi
  );
  const address = useAddress();

  const { value: nftPoolValues } = useAsync(async () => {
    if (!nftPoolContract || !nftPoolAddress || !tokenId) {
      return;
    }

    try {
      const stakingPosition = await nftPoolContract.call('getStakingPosition', [
        tokenId
      ]);

      const poolInfo = await nftPoolContract.call('getPoolInfo');

      const multiplierSettings = await nftPoolContract.call(
        'getMultiplierSettings'
      );

      return {
        positionPoolShare:
          (+stakingPosition.amount.toString() / +poolInfo.lpSupply.toString()) *
          100,
        multiplierSettings,
        stakingPositionAmount: stakingPosition.amount,
        lpSupply: poolInfo.lpSupply,
        maxBoostMultiplier: multiplierSettings.maxBoostMultiplier
      };
    } catch (e) {
      console.error(e);
    }
  }, [nftPoolContract, nftPoolAddress, tokenId]);

  const { value: boosterValues } = useAsync(async () => {
    if (!boosterContract || !nftPoolAddress || !nftPoolValues || !address) {
      return;
    }

    try {
      const totalAllocation = await boosterContract.call(
        'getPoolTotalAllocation',
        [nftPoolAddress]
      );

      const userAllocation = await boosterContract.call(
        'getUserPositionAllocation',
        [address, nftPoolAddress, tokenId]
      );

      const currentMultiplier = await boosterContract.call('getMultiplier', [
        nftPoolAddress,
        nftPoolValues.maxBoostMultiplier,
        nftPoolValues.stakingPositionAmount,
        nftPoolValues.lpSupply,
        userAllocation
      ]);

      const multiplier = await boosterContract.call('getMultiplier', [
        nftPoolAddress,
        nftPoolValues.maxBoostMultiplier,
        nftPoolValues.stakingPositionAmount,
        nftPoolValues.lpSupply,
        ethers.utils.parseUnits(amount.toString(), 18).add(userAllocation)
      ]);

      const boostShare =
        ((+amount + +ethers.utils.formatUnits(userAllocation, 18)) /
          +ethers.utils.formatUnits(totalAllocation, 18)) *
        100;

      return {
        poolBoostShare: boostShare > 100 ? 100 : boostShare,
        multiplier: multiplier.toString() / 10000,
        userAllocation: +ethers.utils.formatUnits(userAllocation, 18),
        currentMultiplier: currentMultiplier.toString() / 10000
      };
    } catch (e) {
      console.error(e);
    }
  }, [boosterContract, nftPoolAddress, amount, nftPoolValues, address]);

  return {
    positionPoolShare: nftPoolValues?.positionPoolShare,
    poolBoostShare: boosterValues?.poolBoostShare,
    multiplier: boosterValues?.multiplier,
    userAllocation: boosterValues?.userAllocation,
    currentMultiplier: boosterValues?.currentMultiplier
  };
}
