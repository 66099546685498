import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { formatNumber } from '@app/helpers/format';
import { useBoostDetails } from '@app/hooks/booster/useBoostDetails';
import { Address } from '@thirdweb-dev/sdk';

interface Props {
  tokenId: string;
  poolAddress: Address;
}

export const UserAllocationSection: FC<Props> = ({ tokenId, poolAddress }) => {
  const { data: boostDetails } = useBoostDetails(poolAddress, tokenId);

  return (
    <Text fontSize="14px" lineHeight="26px" color="gray.4">
      {boostDetails?.userPositionAllocation
        ? formatNumber(+boostDetails.userPositionAllocation)
        : '0'}{' '}
      xKIM
    </Text>
  );
};
