import React, { ReactNode } from 'react';

import { getConfig } from '@app/config';
import { APP_ROUTES } from '@app/constants/routes';

export const footerNav: (
  chainId: number | undefined,
  address: string | undefined,
  chainName: string | undefined
) => {
  title: string;
  options: {
    label: string | ReactNode;
    link: string;
    isExternal?: boolean;
  }[];
}[] = (chainId, address, chainName) => [
  {
    title: 'Resources',
    options: [
      {
        label: 'Audit',
        link: 'https://github.com/kim-protocol/public-reports/blob/main/audits/Kim_Dex_Final_Report-December-2023.pdf',
        isExternal: true
      },
      {
        label: 'Contracts',
        link: 'https://github.com/kim-protocol',
        isExternal: true
      },
      {
        label: 'Documentation',
        link: 'https://docs.kim.exchange',
        isExternal: true
      },
      {
        label: 'DefiLlama',
        link: 'https://defillama.com/protocol/kim-exchange',
        isExternal: true
      },
      {
        label: 'Leaderboard',
        link: getConfig(chainId)?.FLAGS.showLeaderboardPage
          ? APP_ROUTES.leaderboard
          : ''
      }
    ]
  },
  {
    title: 'Tools',
    options: [
      {
        label: 'Mode Bridge',
        link: 'https://app.mode.network/',
        isExternal: true
      },
      {
        label: 'Symbiosis Bridge',
        link: 'https://app.symbiosis.finance/swap',
        isExternal: true
      },
      {
        label: 'Bridge to Base by Galaxy',
        link: 'https://galaxy.exchange/swap?affiliate=kim-exchange',
        isExternal: true
      },
      {
        label: 'Retro Bridge',
        link: 'https://app.retrobridge.io/?utm_source=kim',
        isExternal: true
      },
      {
        label: 'Meson Bridge',
        link:
          chainName && address
            ? `https://m2.meson.fi/${chainName?.toLowerCase()}/${address}`
            : '',
        isExternal: true
      },

      {
        label: 'Router',
        link: APP_ROUTES.addLiquidityRouter,
        isExternal: false
      }
    ]
  },
  {
    title: 'Social',
    options: [
      {
        label: 'Discord',
        link: 'https://discord.com/invite/kimprotocol',
        isExternal: true
      },
      {
        label: 'X',
        link: 'https://x.com/kimprotocol',
        isExternal: true
      },
      // {
      //   label: 'Telegram',
      //   link: 'https://t.me/kim_protocol',
      //   isExternal: true
      // },
      {
        label: 'Blog',
        link: 'https://mirror.xyz/kim-exchange.eth',
        isExternal: true
      }
    ]
  }
];
