import { Button, IconButton } from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';
import styles from './InfoButton.module.scss';
import { FC } from 'react';

type Props = {
  onClick: () => void;
};

export const InfoButton: FC<Props> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} aria-label="info">
      <Icon name="info" className={styles.infoButton} />
    </IconButton>
  );
};
