import { useNitroPoolsList } from '@app/hooks/nitro/useNitroPoolsList';
import { useMemo } from 'react';
import { HIDDEN_NITRO_POOLS } from '@app/constants/common';

const NOW = new Date().getTime();

export function useNitroPool(
  nftPoolAddress: string | undefined,
  nitroPoolId?: string
) {
  // todo - get nitro pool id somehow
  const { data, isLoading } = useNitroPoolsList();

  const pools = useMemo(() => {
    if (!data || !nftPoolAddress) {
      return [];
    }

    // get vault NFT pool
    // find nitro by NFT pool address
    // it must be published, in start /end time range

    if (
      nitroPoolId &&
      !HIDDEN_NITRO_POOLS.includes(nitroPoolId.toLowerCase())
    ) {
      return data.filter(
        item => item.id.toLowerCase() === nitroPoolId.toLowerCase()
      );
    }

    return data.filter(item => {
      if (HIDDEN_NITRO_POOLS.includes(item.id)) {
        return false;
      }

      if (nitroPoolId && item.id !== nitroPoolId) {
        return false;
      }

      if (!item.published) {
        return false;
      }

      if (item.nftPool.toLowerCase() !== nftPoolAddress.toLowerCase()) {
        return false;
      }

      return NOW <= +item.endTime * 1000;
    });
  }, [data, nftPoolAddress, nitroPoolId]);

  const p = pools.sort((a, b) => {
    if (+a.endTime < +b.endTime) {
      return 1;
    }

    if (+a.endTime > +b.endTime) {
      return -1;
    }

    return 0;
  });

  // console.log(p);

  return {
    data: p[0],
    isLoading
  };
}
