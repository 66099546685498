import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

import { CopyButton } from '@app/components/CopyButton';
import { ExternalLink } from '@app/components/ExternalLink';
import { TokenIcon } from '@app/components/TokenIcon';
import { TokensPair } from '@app/components/TokensPair';
import { InfoRow } from '@app/components/TransactionsHistory/components/InfoRow';
import { formatWalletAddress, toOptionalFixed } from '@app/helpers/format';
import { Swap } from '@app/types/transaction';

interface Props {
  swaps: Swap[];
  timestamp: string;
  txId: string;
}

export const SwapTransaction: FC<Props> = ({ txId, swaps, timestamp }) => {
  const [open, toggleOpen] = useToggle(false);

  const { tokenFrom, tokenTo, amountIn, amountOut } = useMemo(() => {
    if (swaps.length > 1) {
      const first = swaps[0];
      const last = swaps[swaps.length - 1];

      const _tokenFrom = +first.amount0In > 0 ? 'token0' : 'token1';
      const _tokenTo = +last.amount0Out > 0 ? 'token0' : 'token1';
      const amountIn = +first.amount0In > 0 ? first.amount0In : first.amount1In;
      const amountOut =
        +last.amount0Out > 0 ? last.amount0Out : first.amount1Out;

      return {
        tokenFrom: first.pair[_tokenFrom],
        tokenTo: last.pair[_tokenTo],
        amountIn: toOptionalFixed(+amountIn, 5),
        amountOut: toOptionalFixed(+amountOut, 5)
      };
    }

    const _swap = swaps[0];

    const _tokenFrom = +_swap.amount0In > 0 ? 'token0' : 'token1';
    const _tokenTo = +_swap.amount0Out > 0 ? 'token0' : 'token1';
    const amountIn = +_swap.amount0In > 0 ? _swap.amount0In : _swap.amount1In;
    const amountOut =
      +_swap.amount0Out > 0 ? _swap.amount0Out : _swap.amount1Out;

    return {
      tokenFrom: _swap.pair[_tokenFrom],
      tokenTo: _swap.pair[_tokenTo],
      amountIn: toOptionalFixed(+amountIn, 5),
      amountOut: toOptionalFixed(+amountOut, 5)
    };
  }, [swaps]);

  return (
    <Box
      sx={{
        backgroundColor: 'gray.10',
        padding: '16px 20px',
        borderRadius: '6px',
        mb: '16px'
      }}
      onClick={toggleOpen}
    >
      <HStack width="100%" gap="12px" cursor="pointer" userSelect="none">
        <TokensPair
          token0Symbol={tokenFrom.symbol}
          token1Symbol={tokenTo?.symbol}
        />
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'whiteOpacity.50'
              }}
            >
              Swapped
            </Text>
            <Text
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: 'white'
              }}
            >
              {tokenFrom.symbol} - {tokenTo.symbol}
            </Text>
          </VStack>

          <VStack alignItems="flex-end">
            <Text
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'whiteOpacity.50'
              }}
            >
              {amountIn} {tokenFrom.symbol}
            </Text>
            <Text
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: 'white'
              }}
            >
              {amountOut} {tokenTo.symbol}
            </Text>
          </VStack>
        </HStack>
      </HStack>
      {open && (
        <>
          <Divider
            sx={{
              width: 'calc(100% + 40px)',
              height: '1px',
              background: 'whiteOpacity.10',
              margin: '16px -20px'
            }}
          />
          <Box>
            <InfoRow
              label={`Swapped ${tokenFrom.name || tokenFrom.symbol}`}
              value={`${amountIn} ${tokenFrom.symbol}`}
            />
            <InfoRow
              label={`Received ${tokenTo.name || tokenTo.symbol}`}
              value={`${amountOut} ${tokenTo.symbol}`}
            />
            <InfoRow
              label={`Date and time`}
              value={`${format(
                new Date(+timestamp * 1000),
                'dd MMM, yyyy, hh:mm a'
              )}`}
            />
            <InfoRow
              label={`Transaction hash`}
              value={
                <HStack>
                  <Text
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'whiteOpacity.50'
                    }}
                  >
                    {formatWalletAddress(txId)}
                  </Text>
                  <CopyButton text={txId} />
                </HStack>
              }
            />
            <InfoRow label={<ExternalLink txHash={txId} />} value="" />
          </Box>
        </>
      )}
    </Box>
  );
};
