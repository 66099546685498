import { SmartContract, useAddress } from '@thirdweb-dev/react';
import { useContract } from '@thirdweb-dev/react-core';
import yieldBooster from '../../../abis/YieldBooster.json';
import { ethers } from 'ethers';
import xKimToken from '../../../abis/XKimToken.json';
import { Address } from '@thirdweb-dev/sdk';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';

async function fetcher(
  contract: SmartContract<ethers.BaseContract> | undefined,
  xKimContract: SmartContract<ethers.BaseContract> | undefined,
  address: Address | undefined,
  poolAddress: Address,
  tokenId: string
) {
  if (!contract || !xKimContract || !address || !poolAddress || !tokenId) {
    return;
  }

  const userPositionAllocation = await contract.call(
    'getUserPositionAllocation',
    [address, poolAddress, tokenId]
  );

  const parsed = ethers.utils.formatUnits(userPositionAllocation, 18);

  return {
    userPositionAllocation: parsed,
    isBoosted: userPositionAllocation.toString() !== '0'
  };
}

export function useBoostDetails(poolAddress: Address, tokenId: string) {
  const address = useAddress();
  const config = useConfig();

  const { contract } = useContract(
    config?.CONTRACTS.YIELD_BOOSTER,
    yieldBooster.abi
  );

  const { contract: xKimContract } = useContract(
    config?.CONTRACTS.X_KIM,
    xKimToken.abi
  );
  const { data, isLoading } = useQuery(
    [QueryKeys.BOOST_DETAILS, address, poolAddress, tokenId],
    () => fetcher(contract, xKimContract, address, poolAddress, tokenId),
    {
      enabled: !!(contract && xKimContract && address && poolAddress && tokenId)
    }
  );

  return {
    data,
    isLoading
  };
}
