import React, { FC } from 'react';
import { BoostPosition } from '@app/screens/booster/components/AddToPosition';
import { UnboostPosition } from '@app/screens/booster/components/UnboostPosition';
import { HarvestKpNftRewards } from '@app/screens/positions/components/HarvestKpNftRewards';
import { AddToPosition } from '@app/screens/positions/components/SpNftControls/AddToPosition';
import { WithdrawFromPosition } from '@app/screens/positions/components/SpNftControls/WithdrawFromPosition';
import { LockPosition } from '@app/screens/positions/components/SpNftControls/LockPosition';
import { Vault } from '@app/hooks/plugins/useVaults';
import { PairToken } from '@app/types/pool';
import { Address } from '@thirdweb-dev/sdk';
import { useBoostDetails } from '@app/hooks/booster/useBoostDetails';
import { StakeToNitroPool } from '@app/screens/positions/components/SpNftControls/StakeToNitroPool';
import { UnstakeFromNitroPool } from '@app/screens/positions/components/SpNftControls/UntakeFromNitroPool';

interface Props {
  boosterView: boolean | undefined;
  vault: Vault | undefined;
  tokenDetails: PairToken;
  tokenId: string;
  amount: string;
  poolAddress: Address;
  totalApr: number | undefined;
  lockBonusApr: number | undefined;
  farmBaseApr: number;
  pendingRewards: number | null;
  nitroPoolPositionPendingRewards: number | null;
  isStakedToNitro: boolean;
  nitroPoolId?: string;
}

export const ActionsSection: FC<Props> = ({
  boosterView,
  vault,
  tokenDetails,
  tokenId,
  poolAddress,
  amount,
  totalApr,
  lockBonusApr,
  farmBaseApr,
  pendingRewards,
  isStakedToNitro,
  nitroPoolPositionPendingRewards,
  nitroPoolId
}) => {
  const { data: boostDetails } = useBoostDetails(poolAddress, tokenId);

  if (!vault && !tokenDetails) {
    return null;
  }

  return boosterView ? (
    <>
      <BoostPosition
        disabled={isStakedToNitro}
        tokenDetails={vault ?? tokenDetails}
        tokenId={tokenId}
        deposit={amount}
        poolAddress={poolAddress}
        totalApr={totalApr}
        lockBonusApr={lockBonusApr}
        farmBaseApr={farmBaseApr}
        swpaFeesApr={vault?.apr}
      />
      <UnboostPosition
        tokenDetails={vault ?? tokenDetails}
        tokenId={tokenId}
        deposit={boostDetails?.userPositionAllocation}
        poolAddress={poolAddress}
      />
    </>
  ) : (
    <>
      <HarvestKpNftRewards
        tokenDetails={vault ?? tokenDetails}
        tokenId={tokenId}
        poolAddress={poolAddress}
        pendingRewards={pendingRewards}
        nitroPoolPositionPendingRewards={nitroPoolPositionPendingRewards}
        isStakedToNitro={isStakedToNitro}
        nitroPoolId={nitroPoolId}
      />
      <AddToPosition
        tokenDetails={vault ?? tokenDetails}
        tokenId={tokenId}
        deposit={amount}
        poolAddress={poolAddress}
        disabled={isStakedToNitro}
      />
      <WithdrawFromPosition
        tokenDetails={vault ?? tokenDetails}
        tokenId={tokenId}
        deposit={amount}
        poolAddress={poolAddress}
        disabled={isStakedToNitro}
      />
      <LockPosition
        tokenDetails={vault ?? tokenDetails}
        tokenId={tokenId}
        deposit={amount}
        poolAddress={poolAddress}
        disabled={isStakedToNitro}
      />
      {/*<TransferPosition*/}
      {/*  tokenDetails={vault ?? tokenDetails}*/}
      {/*  tokenId={tokenId}*/}
      {/*  deposit={amount}*/}
      {/*  poolAddress={poolAddress}*/}
      {/*/>*/}
      {/*<SplitPosition*/}
      {/*  tokenDetails={vault ?? tokenDetails}*/}
      {/*  tokenId={tokenId}*/}
      {/*  deposit={amount}*/}
      {/*  poolAddress={poolAddress}*/}
      {/*/>*/}
      {isStakedToNitro ? (
        <UnstakeFromNitroPool
          tokenDetails={vault ?? tokenDetails}
          tokenId={tokenId}
          deposit={amount}
          poolAddress={poolAddress}
          nitroPoolId={nitroPoolId}
        />
      ) : (
        <StakeToNitroPool
          tokenDetails={vault ?? tokenDetails}
          tokenId={tokenId}
          deposit={amount}
          poolAddress={poolAddress}
          nitroPoolId={nitroPoolId}
        />
      )}
    </>
  );
};
