import React, { FC } from 'react';
import { Vault } from '@app/hooks/plugins/useVaults';
import { Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { getStrategyAcronim } from '@app/helpers/vaults';

interface Props {
  vault: Vault | undefined;
}

export const StrategySection: FC<Props> = ({ vault }) => {
  return (
    <VStack gap={1} alignItems="flex-end">
      {vault && (
        <>
          {vault.provider === 'Steer' && (
            <Image
              src="/assets/images/providers/steer.svg"
              height={24}
              width={50}
              alt="steer"
            />
          )}
          {vault.provider === 'Ichi' && (
            <Image
              src="/assets/images/providers/ichi.svg"
              height={24}
              width={50}
              alt="ichi"
            />
          )}
          <Text fontSize="14px" color="gray.4">
            {getStrategyAcronim(vault.name)}
          </Text>
        </>
      )}
    </VStack>
  );
};
