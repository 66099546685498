export function getStrategyAcronim(name: string) {
  switch (name) {
    case 'Classic Rebalance Strategy V2': {
      return 'CRS V2';
    }
    case 'Static Stable Strategy V2': {
      return 'SSS V2';
    }
    case 'Elastic Expansion Strategy V2': {
      return 'EES V2';
    }
    case 'Fluid Liquidity Strategy 0.3.0': {
      return 'FLS';
    }
    default: {
      return name;
    }
  }
}
