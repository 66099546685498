import { ChakraProps, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';

import { TokenIcon } from '@app/components/TokenIcon';

interface Props extends ChakraProps {
  token0Symbol: string | undefined;
  token1Symbol: string | undefined;
  token0Icon?: string;
  token1Icon?: string;
  size?: 'sm' | 'md' | 'lg';
}

export const TokensPair: FC<Props> = ({
  token0Symbol,
  token1Symbol,
  token0Icon,
  token1Icon,
  size = 'md',
  ...rest
}) => {
  const dimension = size === 'lg' ? '48px' : size === 'md' ? '32px' : '18px';

  return (
    <HStack gap={0} {...rest}>
      <TokenIcon
        symbol={token0Symbol}
        iconUrl={token0Icon ?? ''}
        width={dimension}
        height={dimension}
      />
      <TokenIcon
        symbol={token1Symbol}
        iconUrl={token1Icon ?? ''}
        sx={{ transform: 'translateX(-4px)' }}
        width={dimension}
        height={dimension}
      />
    </HStack>
  );
};
