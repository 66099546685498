import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  HStack,
  Button,
  Flex,
  Grid
} from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { SubmitButton } from '@app/components/SubmitButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { AddToPosition } from '@app/screens/positions/components/SpNftControls/AddToPosition';
import { WithdrawFromPosition } from '@app/screens/positions/components/SpNftControls/WithdrawFromPosition';
import { LockPosition } from '@app/screens/positions/components/SpNftControls/LockPosition';
import { useHarvestPosition } from '@app/screens/positions/components/SpNftRowDetailsModal/hooks';
import { useToggle } from 'react-use';
import { TransferPosition } from '@app/screens/positions/components/SpNftControls/TransferPosition';
import { SplitPosition } from '@app/screens/positions/components/SpNftControls/SplitPosition';
import { Vault } from '@app/hooks/plugins/useVaults';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { formatUSD } from '@app/helpers/formatUSD';
import { useBoostDetails } from '@app/hooks/booster/useBoostDetails';
import { BoostPosition } from '@app/screens/booster/components/AddToPosition';
import { useFarmBaseApr } from '@app/hooks/plugins/useFarmBaseApr';
import { useKpNftPositionDetails } from '@app/screens/positions/components/SpNftPositionListRowItem/hooks';
import { UnboostPosition } from '@app/screens/booster/components/UnboostPosition';
import { StakeToNitroPool } from '@app/screens/positions/components/SpNftControls/StakeToNitroPool';
import { UnstakeFromNitroPool } from '@app/screens/positions/components/SpNftControls/UntakeFromNitroPool';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';
import { LockDetails } from '@app/hooks/plugins/useKpNftLockDetails';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
  pendingRewards: number | null;
  lockDetails: LockDetails;
  isNitro: boolean;
  isStakedToNitro: boolean;
  nitroPoolId?: string;
}

export const SpNftRowDetailsModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  onResult,
  pendingRewards,
  lockDetails,
  isNitro,
  isStakedToNitro,
  nitroPoolId
}) => {
  const [childOpen, toggleChildOpen] = useToggle(false);
  const isMismatchedNetwork = useNetworkMismatch();
  const { data: boostDetails } = useBoostDetails(poolAddress, tokenId);
  const { isLocked } = lockDetails;

  const { vault, usdAmount, data, isLoading } = useKpNftPositionDetails(
    tokenId,
    poolAddress,
    'lpToken' in tokenDetails ? tokenDetails.lpToken.id : tokenDetails.id
  );

  const { totalApr, lockBonusApr, farmBaseApr } = useFarmBaseApr(
    poolAddress,
    vault?.tvl,
    vault?.apr,
    (data?.lockDuration ?? 0) / 1000 / 3600 / 24
  );

  const { submit, submitting } = useHarvestPosition(
    tokenDetails,
    poolAddress,
    tokenId,
    isStakedToNitro
  );

  function renderInfoRow(label: string, isActive: boolean) {
    return (
      <Flex
        sx={{
          backgroundColor: isActive ? 'orange' : 'neutral.700',
          color: isActive ? 'white' : 'gray.4',
          borderRadius: '24px',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px 12px'
        }}
      >
        <Text
          fontSize="12px"
          fontWeight={500}
          textTransform="capitalize"
          textAlign="center"
        >
          {label}
        </Text>
      </Flex>
    );
  }

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        disabled={!pendingRewards}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction Confirmed`,
                <ExternalLink txHash={txHash} />
              );

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Harvest"
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          maxWidth: ['100vw', '586px'],
          padding: '0px',
          opacity: `${childOpen ? 0 : 1} !important`
        }}
      >
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title=""
            subtitle=""
          />
          <Text
            fontSize="14px"
            fontWeight={700}
            color="gray.4"
            mt={0}
            textAlign="center"
          >
            This position has{' '}
            <b style={{ color: 'var(--chakra-colors-orange)' }}>
              {pendingRewards ? formatUSD.format(pendingRewards) : '$0'}
            </b>{' '}
            pending farming rewards
          </Text>

          <Flex
            // gridTemplateColumns="repeat(auto-fill, minmax(90px, 1fr))"
            // gridGap="12px"
            gap="12px"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            width="100%"
            mt={6}
            sx={{
              '> div': {
                width: '120px',
                minWidth: '120px'
              }
            }}
          >
            <AddToPosition
              disabled={isStakedToNitro}
              tokenDetails={tokenDetails}
              tokenId={tokenId}
              deposit={deposit}
              poolAddress={poolAddress}
              onOpen={toggleChildOpen}
              showLabel
            />
            <WithdrawFromPosition
              disabled={isStakedToNitro}
              tokenDetails={tokenDetails}
              tokenId={tokenId}
              deposit={deposit}
              poolAddress={poolAddress}
              onOpen={toggleChildOpen}
              showLabel
            />
            <LockPosition
              disabled={isStakedToNitro}
              tokenDetails={tokenDetails}
              tokenId={tokenId}
              deposit={deposit}
              poolAddress={poolAddress}
              onOpen={toggleChildOpen}
              showLabel
            />
            <TransferPosition
              disabled={isStakedToNitro}
              tokenDetails={tokenDetails}
              tokenId={tokenId}
              deposit={deposit}
              poolAddress={poolAddress}
              onOpen={toggleChildOpen}
              showLabel
            />
            <SplitPosition
              disabled={isStakedToNitro}
              tokenDetails={tokenDetails}
              tokenId={tokenId}
              deposit={deposit}
              poolAddress={poolAddress}
              onOpen={toggleChildOpen}
              showLabel
            />
            {boostDetails?.isBoosted ? (
              <UnboostPosition
                showLabel
                tokenDetails={vault ?? tokenDetails}
                tokenId={tokenId}
                deposit={boostDetails?.userPositionAllocation}
                poolAddress={poolAddress}
              />
            ) : (
              <BoostPosition
                showLabel
                disabled={isStakedToNitro}
                tokenDetails={tokenDetails}
                tokenId={tokenId}
                deposit={deposit}
                poolAddress={poolAddress}
                totalApr={totalApr}
                lockBonusApr={lockBonusApr}
                farmBaseApr={farmBaseApr}
                swpaFeesApr={vault?.apr}
              />
            )}
            {isStakedToNitro ? (
              <UnstakeFromNitroPool
                tokenDetails={vault ?? tokenDetails}
                tokenId={tokenId}
                deposit={deposit}
                poolAddress={poolAddress}
                showLabel
                nitroPoolId={nitroPoolId}
              />
            ) : (
              <StakeToNitroPool
                tokenDetails={vault ?? tokenDetails}
                tokenId={tokenId}
                deposit={deposit}
                poolAddress={poolAddress}
                showLabel
                nitroPoolId={nitroPoolId}
              />
            )}
          </Flex>

          <HStack justifyContent="space-between" mt={6}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Properties
            </Text>
          </HStack>
          <Grid
            mt={3}
            gridTemplateColumns="repeat(auto-fill, minmax(160px, 1fr))"
            gridGap={3}
          >
            {renderInfoRow(
              boostDetails?.isBoosted
                ? 'Yield-boosted'
                : 'Yield Booster compatible',
              boostDetails?.isBoosted ?? false
            )}
            {renderInfoRow(isLocked ? 'Locked' : 'Can be Locked', isLocked)}
            {renderInfoRow(
              isStakedToNitro
                ? 'Staked in Nitro pool'
                : 'Nitro Pool compatible',
              isStakedToNitro
            )}
          </Grid>

          {/*<HStack justifyContent="space-between" mt={6}>*/}
          {/*  <Text fontSize="16px" fontWeight={400} lineHeight="26px">*/}
          {/*    Data breakdown*/}
          {/*  </Text>*/}
          {/*</HStack>*/}
          {/*{renderInfoRow('Value', `???`)}*/}
          {/*{renderInfoRow('APR', `???`)}*/}
          {/*{renderInfoRow('Pending reward', `???`)}*/}
          <HStack mt={6}>
            <Button
              variant="secondary"
              py="10px"
              px="20px"
              borderRadius="12px"
              onClick={() => {
                onClose();
              }}
              w="100%"
            >
              <Text
                fontSize="16px"
                fontFamily="'Inter', sans-serif"
                fontWeight="500"
                lineHeight="20px"
              >
                Cancel
              </Text>
            </Button>
            {renderSubmit()}
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};
