import { useMemo } from 'react';
import { useNitroPool } from '@app/hooks/nitro/useNitroPool';
import { useSingleTokenQuery } from '@app/hooks/v3/useSingleTokenQuery';
import { useContract } from '@thirdweb-dev/react-core';

import Erc20 from 'abis/erc20.json';
import { useAsync } from 'react-use';
import { formatUnits } from 'ethers/lib/utils';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { useNftPoolDetails } from '@app/hooks/nitro/useNftPoolDetails';
import { useLpTokenTvl } from '@app/hooks/plugins/useLpTokenTvl';
import { differenceInDays } from 'date-fns';
import { Address } from 'thirdweb';

export function useNitroPoolDetails(
  nftPoolAddress: string | undefined,
  nitroPoolId?: Address
) {
  const { data: nitroPool, isLoading: isLoadingPool } = useNitroPool(
    nftPoolAddress,
    nitroPoolId
  );

  const { data: rewardToken1, isLoading: isLoadingReward1 } =
    useSingleTokenQuery({
      tokenId: nitroPool?.rewardsToken1.id.toLowerCase()
    });
  const { data: rewardToken2, isLoading: isLoadingReward2 } =
    useSingleTokenQuery({
      tokenId: nitroPool?.rewardsToken2.id.toLowerCase()
    });

  const { lpToken, isLoading: isLoadingNftPoolDetails } =
    useNftPoolDetails(nftPoolAddress);

  const { contract: lpTokenContract } = useContract(lpToken, Erc20);

  const { value: lpTokenTotalSupply, loading: isLoadingTotalSupply } =
    useAsync(async () => {
      if (!lpTokenContract) {
        return;
      }

      const res = await lpTokenContract.call('totalSupply');

      return formatUnits(res, 18);
    }, [lpTokenContract]);

  const { tvlUsd: lpTokenTvlInUsd, priceUsd } = useLpTokenTvl(lpToken);

  const details = useMemo(() => {
    if (!nitroPool || !lpTokenTotalSupply) {
      return;
    }

    const diffInDays = differenceInDays(
      new Date(+nitroPool.endTime * 1000),
      new Date()
    );

    // token1
    const token1RewardsPerYear =
      +formatUnits(
        nitroPool.rewardsToken1PerSecond,
        nitroPool.rewardsToken1.decimals
      ) *
      3600 *
      24 *
      (diffInDays < 0 ? 0 : diffInDays);
    const token2RewardsPerYear =
      +formatUnits(
        nitroPool.rewardsToken2PerSecond,
        nitroPool.rewardsToken2.decimals
      ) *
      3600 *
      24 *
      (diffInDays < 0 ? 0 : diffInDays);

    // create hook to get tvl in usd for lpToken

    // nitro pools apr
    // ODG token
    //
    // price
    // $4.46
    //
    // rewards per second in ODG token
    // 0.001612855176800236
    //
    // rewards per second * 60 * 60 * 24 * 365 = 50863 ODG per year
    //
    // in usd: 50863 * 4.46 = 226848,9838
    //
    // rewards per year / tvlUSD(of the pool) * 100%
    //
    // 226848,9838 / 403800 * 100% = 56.17%

    return {
      nitroPool,
      rewardToken1,
      rewardToken2,
      token1RewardsPerYear,
      token2RewardsPerYear
    };
  }, [nitroPool, rewardToken1, rewardToken2, lpTokenTotalSupply]);

  const ratio =
    nitroPool && lpTokenTotalSupply
      ? +formatUnits(nitroPool?.totalDepositAmount, 18) / +lpTokenTotalSupply
      : 0;

  const tvlUsd = lpTokenTvlInUsd ? lpTokenTvlInUsd * ratio : 0;
  const totalDeposits =
    nitroPool && priceUsd
      ? +formatUnits(nitroPool?.totalDepositAmount, 18) * priceUsd
      : 0;

  const token1RewardsPerYearUsd = useTokenPriceV4(
    details?.nitroPool.rewardsToken1?.id,
    details?.token1RewardsPerYear
  );
  const token2RewardsPerYearUsd = useTokenPriceV4(
    details?.nitroPool.rewardsToken2?.id,
    details?.token2RewardsPerYear
  );

  const token1Apr =
    token1RewardsPerYearUsd && tvlUsd
      ? (token1RewardsPerYearUsd / tvlUsd) * 100
      : 0;

  const token2Apr =
    token2RewardsPerYearUsd && tvlUsd
      ? (token2RewardsPerYearUsd / tvlUsd) * 100
      : 0;

  const pendingRewardToken1Usd = useTokenPriceV4(
    details?.nitroPool.rewardsToken1?.id,
    formatUnits(
      details?.nitroPool.rewardsToken1RemainingAmount ?? '0',
      +(details?.nitroPool.rewardsToken1?.decimals ?? 18)
    )
  );
  const pendingRewardToken2Usd = useTokenPriceV4(
    details?.nitroPool.rewardsToken2?.id,
    formatUnits(
      details?.nitroPool.rewardsToken2RemainingAmount ?? '0',
      +(details?.nitroPool.rewardsToken2?.decimals ?? 18)
    )
  );

  const pendingRewards =
    (pendingRewardToken1Usd ?? 0) + (pendingRewardToken2Usd ?? 0);

  const isLoading =
    isLoadingPool ||
    isLoadingReward1 ||
    isLoadingReward2 ||
    isLoadingNftPoolDetails ||
    isLoadingTotalSupply;

  return {
    isLoading,
    data: details,
    token1Apr,
    token2Apr,
    totalApr: token1Apr + token2Apr,
    tvlUsd,
    totalDeposits,
    pendingRewards
  };
}
