import React, { FC } from 'react';
import { SpNftPositionsListRowItem } from '@app/screens/positions/components/SpNftPositionListRowItem';
import { useKpNftDetails } from '@app/screens/positions/components/SpNftPositionListRow/hooks';
import { SpNftModalRowItem } from '@app/screens/nft-marketplace/SpNftOffersModal/SpNftModalRow';
import { Box } from '@chakra-ui/react';

const MY_KP_NFT_TAB = 'my_kp_nft_tab';
const POSITIONS_LIST_PAGE = 'positions_list_page';

interface Props {
  poolAddress: string;
  tokenAddress: string;
  boosterView?: boolean;
  currentPage?: typeof MY_KP_NFT_TAB | typeof POSITIONS_LIST_PAGE;
}

export const SpNftPositionsListRow: FC<Props> = ({
  poolAddress,
  tokenAddress,
  boosterView,
  currentPage = POSITIONS_LIST_PAGE
}) => {
  const { data, isLoading, tokenDetails } = useKpNftDetails(
    poolAddress,
    tokenAddress
  );

  if (isLoading) {
    return null;
  }

  if (!tokenDetails) {
    return null;
  }

  const renderPositionRow = (tokenId: string) => {
    if (currentPage === POSITIONS_LIST_PAGE) {
      return (
        <SpNftPositionsListRowItem
          key={tokenId}
          poolAddress={poolAddress}
          tokenId={tokenId}
          tokenDetails={tokenDetails}
          boosterView={boosterView}
        />
      );
    }

    return (
      <>
        <SpNftModalRowItem
          key={tokenId}
          poolAddress={poolAddress}
          tokenId={tokenId}
          tokenDetails={tokenDetails}
          boosterView={boosterView}
        />
      </>
    );
  };

  return (
    <>
      {data?.map(tokenId => {
        return <Box key={tokenId}>{renderPositionRow(tokenId)}</Box>;
      })}
    </>
  );
};
