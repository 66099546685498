import { useAddress, useContract } from '@thirdweb-dev/react';
import { useAsyncFn } from 'react-use';
import NitroPool from 'abis/NitroPool.json';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';

export function useUnstakeFromNitroPool(
  poolAddress: string,
  tokenId: string,
  nitroPoolAddress: string
) {
  const address = useAddress();
  const queryClient = useQueryClient();

  const { contract: poolContract } = useContract(
    nitroPoolAddress,
    NitroPool.abi
  );

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address || !nitroPoolAddress) {
      return;
    }

    const res = await poolContract?.call('withdraw', [tokenId]);

    await queryClient.invalidateQueries([QueryKeys.FETCH_NFT_TOKENS]);
    await queryClient.invalidateQueries([QueryKeys.NITRO_POSITIONS_LIST]);
    await queryClient.invalidateQueries([QueryKeys.SP_NFTS_POSITIONS]);
    await queryClient.invalidateQueries([QueryKeys.POOL_TOKEN_IDS]);

    return res;
  }, [poolContract, address, nitroPoolAddress, tokenId, queryClient]);

  return {
    submit,
    submitting
  };
}
