export const calculateDiscountOrPremium = (
  originalPrice: number,
  newPrice: number
) => {
  if (originalPrice === 0) {
    throw new Error('Original price cannot be zero.');
  }

  const percentageChange = ((newPrice - originalPrice) / originalPrice) * 100;

  if (newPrice < originalPrice) {
    return Math.abs(percentageChange).toFixed(2);
  } else {
    return (100 + percentageChange).toFixed(2);
  }
};
