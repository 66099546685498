import { useMemo } from 'react';
import { useTokensPricesV4 } from '@app/hooks/merkl/useTokensPricesV4';
import {
  Campaign,
  useMerklCampaigns
} from '@app/hooks/merkl/useMerklCampaigns';
import { formatUnits } from 'ethers/lib/utils';
import {
  MetromCampaign,
  useMetromCampaigns
} from '@app/hooks/metrom/useMetromCampaigns';
import uniq from 'lodash/uniq';

export function useMarketMakerPools() {
  const { data: merklCampaigns } = useMerklCampaigns();
  const { data: metromCampaigns } = useMetromCampaigns();

  const rewardTokens = useMemo(() => {
    if (!merklCampaigns) {
      return [];
    }

    const merklTokens = (merklCampaigns ?? []).map(
      c => c.campaignParameters.symbolRewardToken
    );

    const metromTokens = (metromCampaigns ?? []).map(c => {
      return c.rewards.map(r => r.symbol);
    });

    return uniq([...merklTokens, ...metromTokens.flat()]);
  }, [merklCampaigns, metromCampaigns]);

  const prices = useTokensPricesV4(rewardTokens);

  return useMemo<{
    merkl: (Campaign & { amountUSD: number })[];
    metrom: (MetromCampaign & { amountUSD: number })[];
  }>(() => {
    const merkl =
      (merklCampaigns ?? []).map(item => {
        const symbol = item.campaignParameters.symbolRewardToken;
        const priceUsd = prices[symbol];
        const formattedAmount = formatUnits(
          item.amount,
          item.campaignParameters.decimalsRewardToken
        );

        return {
          ...item,
          amountUSD: priceUsd ? priceUsd * Number(formattedAmount) : 0
        };
      }) ?? [];

    const metrom =
      (metromCampaigns ?? []).map(item => {
        const symbol = item.rewards[0].symbol;
        const priceUsd = prices[symbol];

        const formattedAmount = formatUnits(
          item.rewards[0].amount,
          item.rewards[0].decimals
        );

        return {
          ...item,
          amountUSD: priceUsd ? priceUsd * Number(formattedAmount) : 0
        };
      }) ?? [];

    return {
      merkl,
      metrom
    };
  }, [merklCampaigns, metromCampaigns, prices]);
}
