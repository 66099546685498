import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { getConfig, useConfig } from '@app/config';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { Token } from '@app/types/token';
import { Vault } from '@app/hooks/plugins/useVaults';
import {
  computePoolAddress,
  Token as AlgebraToken
} from '@cryptoalgebra/kim-sdk';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';
import axios, { AxiosResponse } from 'axios';

import { fetcher as getNativePrice } from 'src/hooks/v3/useNativePriceQuery';
import { fetcher as getTokenQuery } from 'src/hooks/v3/useMultipleTokensQuery';

type AggregatedIchiVault = {
  allowTokenA: boolean;
  allowTokenB: boolean;
  holdersCount: number;
  id: string;
  metrics: {
    timeInterval: number;
    lpPriceChange: number;
    lpApr: number;
    avgDtr: number;
    feeApr: number;
  }[];
  tokenA: string;
  tokenB: string;
  tvl: string;
};

async function fetcher(
  apiUrl: string | undefined,
  TOKENS_WHITELIST: Token[],
  chainId: number | undefined
) {
  if (!apiUrl || !chainId) {
    return;
  }

  const { data } = await axios.get<void, AxiosResponse<AggregatedIchiVault[]>>(
    `${apiUrl}?perPage=20`
  );

  const bundle = await getNativePrice(chainId);

  const res = data.map(item => {
    const { id: ichiVaultId, tokenA, tokenB, allowTokenA, metrics, tvl } = item;

    const lastWeekMetrics = metrics?.find(item => item?.timeInterval === 7);

    const depositTokenId = allowTokenA ? tokenA : tokenB;
    const pairedTokenId = allowTokenA ? tokenB : tokenA;

    const depositTokenData = TOKENS_WHITELIST.find(
      item =>
        item.contractAddress.toLowerCase() === depositTokenId.toLowerCase()
    );
    const pairedTokenData = TOKENS_WHITELIST.find(
      item =>
        item.contractAddress.toLowerCase() === pairedTokenId?.toLowerCase()
    );

    if (!(depositTokenData && pairedTokenData)) {
      return null;
    }

    const pool = computePoolAddress({
      tokenA: new AlgebraToken(
        chainId,
        depositTokenData.contractAddress,
        depositTokenData.decimals
      ),
      tokenB: new AlgebraToken(
        chainId,
        pairedTokenData.contractAddress,
        pairedTokenData.decimals
      )
    });

    return {
      id: ichiVaultId,
      apr: lastWeekMetrics?.feeApr,
      image: '',
      link: '',
      lpToken: {
        id: ichiVaultId,
        name: '',
        symbol: '',
        decimals: 18,
        lpTokenUSD: 0
      },
      name: ``,
      pool,
      strategyDescription: '',
      token0: {
        symbol: depositTokenData.symbol,
        id: depositTokenData.contractAddress,
        decimals: +depositTokenData.decimals,
        name: depositTokenData.name,
        logoUri: ''
      },
      token0Balance: 0,
      token1: {
        symbol: pairedTokenData.symbol,
        id: pairedTokenData.contractAddress,
        decimals: +pairedTokenData.decimals,
        name: pairedTokenData.name,
        logoUri: ''
      },
      token1Balance: 0,
      tvl: tvl ?? ''
    };
  });

  const _res = res.filter(Boolean) as unknown as Vault[];

  return _res;

  // const config = getConfig(chainId);

  // const tokensInfo = await getTokenQuery(
  //   {
  //     tokenIds: _res
  //       ?.map(v => {
  //         return `"${v.token0.id.toLowerCase()}"`;
  //       })
  //       .join(',')
  //   },
  //   config
  // );

  // return _res.map(v => {
  //   const poolTokenInfo = tokensInfo.find(
  //     t => t.id.toLowerCase() === v.token0.id.toLowerCase()
  //   );
  //
  //   if (!poolTokenInfo || !bundle) {
  //     return v;
  //   }
  //
  //   const tokenUSDValue =
  //     Number(poolTokenInfo.derivedMatic) * Number(bundle.maticPriceUSD);
  //
  //   return {
  //     ...v,
  //     tvl: tokenUSDValue * v.tvl
  //   };
  // });
}

export function useIchiVaultsList() {
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;
  const TOKENS_WHITELIST = getTokensWhitelist(config);

  return useQuery<Vault[] | undefined>(
    [QueryKeys.GET_ICHI_VAULTS_LIST, config?.URLS.ichiAggregatorUrl, chainId],
    () => fetcher(config?.URLS.ichiAggregatorUrl, TOKENS_WHITELIST, chainId),
    {
      refetchOnWindowFocus: false,
      staleTime: 15000,
      enabled: !!config?.URLS.ichiAggregatorUrl && !!chainId
    }
  );
}
