import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from 'src/constants/queryKeys';

import { getContract, useAddress } from '@thirdweb-dev/react';
import { Chain } from '@thirdweb-dev/chains';

import nftPoolAbi from 'abis/nftPool.json';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';
import { formatUnits } from 'ethers/lib/utils';

type QueryParams = {
  tokenId: string;
  poolAddress: string;
};

export type StakingPosition = {
  amount: string;
  startLockTime: number;
  lockDuration: number;
};

const fetcher = async (
  params: QueryParams,
  network: Chain | undefined,
  walletAddress: string | undefined
) => {
  if (!network || !walletAddress) {
    return undefined;
  }

  const poolContract = await getContract({
    address: params.poolAddress,
    network,
    contractTypeOrAbi: nftPoolAbi
  });

  // @ts-ignore
  const res = await poolContract.call('getStakingPosition', [params.tokenId]);

  const startLockTime = Number(res.startLockTime.toString()) * 1000;
  const lockDuration = Number(res.lockDuration.toString()) * 1000;

  return {
    amount: formatUnits(res.amount, 18),
    startLockTime,
    lockDuration
  };
};

export const useStakingPosition = (
  params: QueryParams,
  options: UseQueryOptions<StakingPosition | undefined> = {}
) => {
  const chain = useSupportedChain();
  const address = useAddress();

  return useQuery<StakingPosition | undefined>(
    [QueryKeys.STAKING_POSITION, { params }, chain?.chainId],
    async () => {
      return fetcher(params, chain, address);
    },
    {
      staleTime: 5000,
      refetchOnWindowFocus: true,
      enabled: Boolean(chain),
      ...options
    }
  );
};
