import React, { FC, useMemo } from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { formatUSD } from '@app/helpers/formatUSD';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@app/components/Tooltip';
import { toOptionalFixed } from '@app/helpers/format';

interface Props {
  pendingRewards: number | null;
  nitroPoolPositionPendingRewards?: number;
}

export const RewardsSection: FC<Props> = ({
  pendingRewards,
  nitroPoolPositionPendingRewards
}) => {
  const total = (pendingRewards ?? 0) + (nitroPoolPositionPendingRewards ?? 0);

  const tooltip = useMemo(() => {
    return [
      {
        label: 'kpNFT rewards',
        value: pendingRewards
      },
      {
        label: 'Nitro rewards',
        value: nitroPoolPositionPendingRewards,
        color: 'nitro'
      }
    ].filter(t => t.value);
  }, [pendingRewards, nitroPoolPositionPendingRewards]);

  return (
    <HStack alignItems="center" gap="4px">
      <Text fontSize="14px" lineHeight="26px" color="gray.4">
        {total ? formatUSD.format(total) : '$0'}
      </Text>
      <Tooltip
        tooltip={
          <Stack p={0}>
            {tooltip.map(item => {
              return (
                <HStack
                  key={item.label}
                  alignItems="center"
                  justifyContent="space-between"
                  gap={8}
                >
                  <Text fontSize="14px" color="neutral.400">
                    {item.label}
                  </Text>
                  <Text color={item.color} fontSize="14px">
                    {item.value ? `$${toOptionalFixed(item.value, 2)}` : ''}
                  </Text>
                </HStack>
              );
            })}
          </Stack>
        }
        placement="top"
        zIndex={10000}
      >
        <FaInfoCircle color="#707070" />
      </Tooltip>
    </HStack>
  );
};
