import React, { FC } from 'react';
import { Vault } from '@app/hooks/plugins/useVaults';
import { PairToken } from '@app/types/pool';
import { Text } from '@chakra-ui/react';

interface Props {
  vault: Vault | undefined;
  tokenDetails: PairToken | undefined;
}

export const SymbolsSection: FC<Props> = ({ vault, tokenDetails }) => {
  return (
    <Text fontSize="16px" lineHeight="26px" color="white">
      {vault ? (
        <>
          {vault.token0.symbol}-{vault.token1.symbol}
        </>
      ) : (
        tokenDetails?.symbol ?? ''
      )}
    </Text>
  );
};
