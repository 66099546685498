import { useContract } from '@thirdweb-dev/react-core';

import nftPoolAbi from '../../../abis/nftPool.json';
import { useAsync } from 'react-use';

import kimMasterAbi from '../../../abis/KimMaster.json';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import { useLockDetails } from '@app/hooks/plugins/useLockDetails';
import { useConfig } from '@app/config';

export function useFarmBaseApr(
  nftPoolAddress: string | null | undefined,
  vaultTvl: string | number | null | undefined,
  vaultApr: number | null | undefined,
  lockDuration: number
) {
  const { contract: nftPoolContract } = useContract(nftPoolAddress, nftPoolAbi);
  const config = useConfig();

  const { contract: kimMasterContract } = useContract(
    config?.CONTRACTS.KIM_MASTER,
    kimMasterAbi.abi
  );

  const { value } = useAsync(async () => {
    if (!nftPoolContract || !kimMasterContract || !nftPoolAddress) {
      return {
        kimEmissionPerYear: undefined,
        lpSupplyRate: undefined
      };
    }

    try {
      const nftPoolInfo = await nftPoolContract.call('getPoolInfo');

      const lpSupplyRate =
        nftPoolInfo.lpSupply.toString() /
        nftPoolInfo.lpSupplyWithMultiplier.toString();
      const poolInfo = await kimMasterContract.call('getPoolInfo', [
        nftPoolAddress
      ]);

      const poolEmissionRate = poolInfo.poolEmissionRate;
      const kimEmissionPerYear = poolEmissionRate
        .mul(60)
        .mul(60)
        .mul(24)
        .mul(365);

      return {
        kimEmissionPerYear: ethers.utils.formatUnits(kimEmissionPerYear, 18),
        lpSupplyRate
      };
    } catch (e) {
      console.error(e);

      return {
        kimEmissionPerYear: undefined,
        lpSupplyRate: undefined
      };
    }
  }, [nftPoolContract, kimMasterContract, nftPoolAddress]);

  const kimEmissionPerYearUSD = useTokenPriceV4(
    config?.CONTRACTS.KIM!,
    value?.kimEmissionPerYear
  );

  const { lockBonus } = useLockDetails(nftPoolAddress, lockDuration);

  const { farmBaseApr, lockBonusApr, totalApr } = useMemo(() => {
    if (kimEmissionPerYearUSD === null || !value?.lpSupplyRate) {
      return { farmBaseApr: 0 };
    }

    const _vaultTvl = vaultTvl ?? 1;
    const _vaultApr = vaultApr ?? 0;

    const _farmBaseApr =
      (kimEmissionPerYearUSD / +_vaultTvl) * 100 * value.lpSupplyRate;

    const lockBonusApr = (+lockBonus * _farmBaseApr) / 100;

    const totalApr = lockBonusApr + _farmBaseApr + +_vaultApr;

    return {
      farmBaseApr: _farmBaseApr,
      lockBonusApr,
      totalApr
    };
  }, [
    kimEmissionPerYearUSD,
    lockBonus,
    value?.lpSupplyRate,
    vaultApr,
    vaultTvl
  ]);

  return {
    farmBaseApr,
    lockBonusApr,
    totalApr
  };
}
