import { Address } from '@thirdweb-dev/sdk';
import { useNativePriceQuery } from '@app/hooks/v3/useNativePriceQuery';
import { useMemo } from 'react';
import { useVaults } from '@app/hooks/plugins/useVaults';
import { useSingleTokenQuery } from '@app/hooks/v3/useSingleTokenQuery';
import { useConfig } from '@app/config';
import { useAllProvidersVaults } from '@app/hooks/pools/useAllProvidersVaults';

export function useLpTokenTvl(tokenAddress: Address) {
  const { data: bundle } = useNativePriceQuery();
  const config = useConfig();

  const preparedTokenAddress = useMemo(() => {
    if (!tokenAddress || !config) {
      return;
    }

    if (tokenAddress.toLowerCase() === config?.CONTRACTS.X_KIM?.toLowerCase()) {
      return config?.CONTRACTS.KIM?.toLowerCase();
    }

    return tokenAddress.toLowerCase();
  }, [tokenAddress, config]);

  const { data: vaults } = useAllProvidersVaults();

  const { data: poolTokenInfo } = useSingleTokenQuery({
    tokenId: preparedTokenAddress
  });

  return useMemo(() => {
    if (!bundle || !vaults || !preparedTokenAddress) {
      return { tvlUsd: null, priceUsd: null };
    }

    const vaultData = vaults?.find(
      v => v.lpToken.id.toLowerCase() === preparedTokenAddress.toLowerCase()
    );

    if (vaultData) {
      return { tvlUsd: +vaultData.tvl, priceUsd: vaultData.lpToken.lpTokenUSD };
    }

    if (!poolTokenInfo) {
      return { tvlUsd: 0, priceUsd: 0 };
    }

    const tokenUSDValue =
      Number(poolTokenInfo.derivedMatic) * Number(bundle.maticPriceUSD);

    return {
      tvlUsd: tokenUSDValue * +poolTokenInfo.totalValueLocked,
      priceUsd: tokenUSDValue
    };
  }, [bundle, poolTokenInfo, vaults, preparedTokenAddress]);
}
