import { useNativePriceQuery } from '@app/hooks/v3/useNativePriceQuery';
import { useSingleTokenQuery } from '@app/hooks/v3/useSingleTokenQuery';
import { useMemo } from 'react';
import { Address } from '@thirdweb-dev/sdk';
import { useConfig } from '@app/config';
import { useVaults } from '@app/hooks/plugins/useVaults';

export function useTokenPriceV4(
  tokenAddress: Address | undefined,
  amount: string | number | undefined
) {
  const { data: bundle } = useNativePriceQuery();
  const config = useConfig();

  const preparedTokenAddress = useMemo(() => {
    if (!tokenAddress || !config?.CONTRACTS.X_KIM) {
      return;
    }

    if (tokenAddress.toLowerCase() === config?.CONTRACTS.X_KIM?.toLowerCase()) {
      return config?.CONTRACTS.KIM?.toLowerCase();
    }

    return tokenAddress.toLowerCase();
  }, [tokenAddress, config]);

  const { data: vaults } = useVaults();

  const { data: poolTokenInfo } = useSingleTokenQuery({
    tokenId: preparedTokenAddress
  });

  return useMemo(() => {
    if (!bundle || !amount || !vaults || !preparedTokenAddress) {
      return null;
    }

    const vaultData = vaults?.find(
      v => v.lpToken.id.toLowerCase() === preparedTokenAddress.toLowerCase()
    );

    if (vaultData) {
      const usdAmount = Number(vaultData.lpToken.lpTokenUSD) * +amount;

      return usdAmount;
    }

    if (!poolTokenInfo) {
      return 0;
    }

    const tokenUSDValue =
      Number(poolTokenInfo.derivedMatic) * Number(bundle.maticPriceUSD);

    return tokenUSDValue * +amount;
  }, [bundle, amount, poolTokenInfo, vaults, preparedTokenAddress]);
}
