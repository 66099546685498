import { useAddress, useContract } from '@thirdweb-dev/react';
import { useCallback, useEffect, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import {
  bigNumberToDecimal,
  numberToBigNumberFixed
} from '@app/helpers/format';
import { PairToken } from '@app/types/pool';
import nftPoolAbi from 'abis/nftPool.json';
import erc20 from 'abis/erc20.json';
import { Vault } from '@app/hooks/plugins/useVaults';

export function useAddToPosition(
  tokenDetails: PairToken | Vault,
  poolAddress: string,
  tokenId: string
) {
  const address = useAddress();
  const [state, setState] = useState<{
    amount: string;
  }>({
    amount: ''
  });

  const handleAmountChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      amount: val
    }));
  }, []);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      amount: ''
    }));
  }, []);

  const { contract: tokenContract } = useContract(
    'lpToken' in tokenDetails ? tokenDetails.lpToken.id : tokenDetails.id,
    erc20
  );
  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const { value, loading: loadingTokenBalance } = useAsync(async () => {
    if (!tokenContract || !address) {
      return;
    }

    const tokenBalance = await tokenContract.call('balanceOf', [address]);

    return {
      tokenBalance: bigNumberToDecimal(
        tokenBalance,
        +('lpToken' in tokenDetails
          ? tokenDetails.lpToken.decimals
          : tokenDetails.decimals)
      )
    };
  }, [tokenContract, address, tokenDetails]);

  const [{ value: allowance, loading: isFetchingAllowance }, getAllowance] =
    useAsyncFn(async () => {
      if (!tokenContract || !address) {
        return;
      }

      try {
        const res = await tokenContract.call('allowance', [
          address,
          poolAddress
        ]);

        return bigNumberToDecimal(
          res,
          +('lpToken' in tokenDetails
            ? tokenDetails.lpToken.decimals
            : tokenDetails.decimals)
        );
      } catch (e) {
        // do nothing
      }
    }, [tokenContract, address, poolAddress, tokenDetails]);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address) {
      return;
    }

    return poolContract?.call('addToPosition', [
      tokenId,
      numberToBigNumberFixed(
        +state.amount,
        +('lpToken' in tokenDetails
          ? tokenDetails.lpToken.decimals
          : tokenDetails.decimals)
      )
    ]);
  }, [poolContract, address, state.amount, tokenId]);

  const [{ loading: approving }, approve] = useAsyncFn(async () => {
    if (!tokenContract) {
      return;
    }

    const valueToApprove = Number(state.amount);

    return tokenContract.call('approve', [
      poolAddress,
      numberToBigNumberFixed(
        valueToApprove,
        +('lpToken' in tokenDetails
          ? tokenDetails.lpToken.decimals
          : tokenDetails.decimals)
      )
    ]);
  }, [tokenContract, state, allowance]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  return {
    requiresApproval:
      !isFetchingAllowance &&
      allowance !== undefined &&
      Number(state.amount) > allowance,
    allowance,
    getAllowance,
    state,
    handleAmountChange,
    loadingTokenBalance,
    tokenBalance: value?.tokenBalance,
    resetStateValues,
    submit,
    submitting,
    approving,
    approve
  };
}
