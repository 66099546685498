import { useAddress } from '@thirdweb-dev/react';
import { useMemo, useState } from 'react';
import { useNftTokens } from '@app/hooks/positions/useNftTokens';
import { usePoolAddresses } from '@app/hooks/positions/usePoolAddresses';
import { EMPTY_ADDRESS } from '@app/constants/common';

export function useKpNftFilteredList() {
  const address = useAddress();
  const [searchTerm, setSearchTerm] = useState('');

  const { tokens } = useNftTokens(address);

  const { data, isLoading } = usePoolAddresses(
    {
      tokens: tokens?.filter(Boolean)
    },
    {
      enabled: !!tokens
    }
  );

  const filteredData = useMemo(() => {
    if (!data || data.length === 0) {
      return null;
    }

    return data.filter(item => {
      if (item.poolAddress === EMPTY_ADDRESS) {
        return false;
      }

      if (!searchTerm) {
        return true;
      }

      const value = searchTerm.toLowerCase();

      return item.tokenAddress.toLowerCase().includes(value);
    });
  }, [data, searchTerm]);

  return {
    filteredData,
    isLoading,
    searchTerm,
    setSearchTerm
  };
}
