import React, { FC, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useToggle } from 'react-use';
import { PairToken } from '@app/types/pool';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { LockPositionModal } from '@app/screens/positions/components/SpNftControls/LockPosition/LockPositionModal';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { Vault } from '@app/hooks/plugins/useVaults';
import { Tooltip } from '@app/components/Tooltip';

interface Props {
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  onOpen?: (val: boolean) => void;
  showLabel?: boolean;
  disabled: boolean;
}

export const LockPosition: FC<Props> = ({
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  onOpen,
  showLabel,
  disabled
}) => {
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <Tooltip tooltip="Lock Positon" disabled={disabled}>
        <Button
          isDisabled={disabled}
          variant="secondary"
          onClick={e => {
            e.stopPropagation();
            toggleOpen(true);
            onOpen?.(true);
          }}
          _hover={{
            color: 'orange'
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '12px',
            width: '100%',
            gap: 1
          }}
        >
          <HttpsOutlinedIcon sx={{ width: '16px', height: '16px' }} />
          {showLabel && 'Lock'}
        </Button>
      </Tooltip>
      <LockPositionModal
        isOpen={open}
        onClose={() => {
          toggleOpen(false);
          onOpen?.(false);
        }}
        tokenDetails={tokenDetails}
        tokenId={tokenId}
        deposit={deposit}
        poolAddress={poolAddress}
        onResult={res => setFeedback(res)}
      />
      <TransactionResultModal
        isOpen={!!feedback}
        status={feedback?.status}
        title={feedback?.title}
        message={feedback?.message}
        txHash={feedback?.txHash}
        onClose={() => {
          setFeedback(null);
          toggleOpen(false);
          onOpen?.(false);
        }}
      />
    </>
  );
};
