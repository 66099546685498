import React, { FC } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';

interface Props {
  label: string;
  value0: string;
  value1?: string | undefined;
}

export const InfoRow: FC<Props> = ({ label, value1, value0 }) => {
  return (
    <HStack justifyContent="space-between">
      <Text fontSize="14px" fontWeight={400} color="neutral.400">
        {label}
      </Text>
      <HStack>
        <Text fontSize="14px" fontWeight={500} color="neutral.200">
          {value0}
        </Text>
        {value1 && (
          <>
            <Icon
              name="arrowRight"
              style={{ color: 'var(--chakra-colors-gray-4)' }}
            />
            <Text fontSize="14px" fontWeight={500} color="gray.4">
              {value1}
            </Text>
          </>
        )}
      </HStack>
    </HStack>
  );
};
