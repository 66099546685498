import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ImportTokenButton } from '@app/screens/positions/components/ImportTokenButton';

export const NoKpNftView = () => {
  return (
    <Flex
      minHeight="300px"
      width="100%"
      align="center"
      justify="center"
      flexDirection="column"
      gap={3}
    >
      <Text color="neutral.400" fontSize="32px">
        No data found
      </Text>
      <Text
        color="neutral.400"
        fontSize="16px"
        maxWidth="400px"
        textAlign="center"
        lineHeight={1.4}
      >
        If you have created kpNFT positions you may need to{' '}
        <ImportTokenButton inlineView />
        corresponding lpTokens first
      </Text>
    </Flex>
  );
};
