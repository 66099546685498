import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { SellNftForm } from '@app/screens/nft-marketplace/SellNftForm';
import { Position } from '@app/screens/nft-marketplace/SpNftOffersModal/SpNftModalRow';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

export const SellNftModal: FC<Props> = ({ isOpen, onClose, position }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="md"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '859px'], padding: '32px' }}>
        <HStack justifyContent="space-between">
          <ModalHeader>Sell KpNFT</ModalHeader>
          <ModalCloseButton />
        </HStack>
        <ModalBody>
          <SellNftForm position={position} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
