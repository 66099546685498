import { usePoolTokenIds } from '@app/hooks/positions/usePoolTokenIds';
import { useMemo } from 'react';
import { PairToken } from '@app/types/pool';
import { Address } from '@thirdweb-dev/sdk';
import { useTokenInfo } from '@app/hooks/token/useTokenInfo';

export function useKpNftDetails(poolAddress: Address, lpTokenAddress: Address) {
  const { data, isLoading } = usePoolTokenIds({ poolAddress });

  const { data: tokenInfo, isLoading: isLoadingTokenInfo } = useTokenInfo({
    tokenAddress: lpTokenAddress
  });

  const tokenDetails = useMemo(() => {
    if (!tokenInfo) {
      return undefined;
    }

    return {
      name: tokenInfo.name,
      symbol: tokenInfo.symbol,
      decimals: Number(tokenInfo.decimals),
      id: tokenInfo.address
    } as unknown as PairToken;
  }, [tokenInfo]);

  return {
    isLoading: isLoading || isLoadingTokenInfo,
    tokenDetails,
    data
  };
}
