import { HStack, Input, InputGroup, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { SellNftTooltip } from '@app/screens/nft-marketplace/SellNftForm/SellNftTooltip';
import { usePopperHook } from '@app/hooks/other/usePopperHook';

type Props = {
  title: string;
  tooltipMessage: string;
  tokenSymbol: string;
  priceUsd: number;
  inputName: string;
  register: UseFormRegister<any>;
  required: boolean;
};

export const NftPriceInput: FC<Props> = ({
  inputName,
  title,
  tooltipMessage,
  priceUsd,
  tokenSymbol,
  register,
  required
}) => {
  const { referenceElement, setReferenceElement, setShowTooltip, showTooltip } =
    usePopperHook();

  return (
    <>
      <SellNftTooltip
        isOpen={showTooltip}
        referenceElement={referenceElement}
        message={tooltipMessage}
      />
      <VStack
        w="100%"
        bg="neutral.1000"
        padding="16px"
        borderRadius="12px"
        overflow="hidden"
        ref={setReferenceElement}
      >
        <HStack
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text
            fontWeight="400"
            fontSize="16px"
            color="neutral.300"
            lineHeight="16px"
            cursor="pointer"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {title}
          </Text>
          <HStack justifyContent="flex-end">
            <InputGroup>
              <Input
                type="number"
                variant="unstyled"
                width="100%"
                textAlign="right"
                bg="transparent"
                fontSize="24px"
                lineHeight="25px"
                fontWeight="400"
                color="white"
                height="40px"
                placeholder="0.00"
                _placeholder={{
                  color: 'white',
                  fontWeigh: '400',
                  fontWeight: '25px'
                }}
                _focus={{ boxShadow: 'none', outline: 'none' }}
                {...register(inputName, { required })}
              />
            </InputGroup>
            <Text
              fontWeight="400"
              fontSize="24px"
              lineHeight="25px"
              color="white"
            >
              {tokenSymbol}
            </Text>
          </HStack>
        </HStack>
        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="18px"
          color="neutral.400"
          alignSelf="flex-end"
        >
          ${priceUsd}
        </Text>
      </VStack>
    </>
  );
};
