import { Address } from '@thirdweb-dev/sdk';

export const SWAP_PAGE = '/swap';

export const APP_ROUTES = {
  swapPage: '/swap',
  addLiquidityV4: '/liquidity/v4',
  addLiquidityV2: '/liquidity/v2',
  addLiquidityRouter: '/liquidity/router',
  poolsPage: '/pools',
  pairDetailsPage: (pairId: string, tab?: string) => {
    return `/pools/v2/${pairId}?tab=${tab ?? ''}`;
  },
  poolDetailsPage: (poolId: string) => {
    return `/pools/v4/${poolId}`;
  },
  nitroPoolDetailsPage: (poolId: string) => {
    return `/nitro/${poolId}`;
  },
  vaultDetails: (id: string) => {
    return `/pools/vaults/${id}`;
  },
  ichiVaultDetails: (id: string) => {
    return `/pools/vaults/ichi/${id}`;
  },
  magicAddress: (id: string) => {
    return `/pools/vaults/ichi/${id}/global-address`;
  },
  leaderboard: '/leaderboard',
  referrals: '/leaderboard/referrals',
  positions: '/positions',
  partners: '/partners',
  partnerDetailsPage: (id: string) => {
    return `/partners/${id}`;
  },
  kim: '/kim',
  rewards: '/rewards',
  boost: '/booster',
  transfer: (positionId: number, token0: Address, token1: Address) => {
    return `/transfer-position?positionId=${positionId}&token0=${token0}&token1=${token1}`;
  },
  airdrop: '/airdrop',
  info: '/info',
  poolsV4: '/pools/v4',
  poolsV2: '/pools/v2',
  poolsVaults: '/pools/vaults',
  nitroPools: '/nitro',
  dosirak: '/dosirak',
  nftMarketplace: '/nft-marketplace',
  nftDetailsPage: (id: string) => {
    return `/nft-marketplace/${id}`;
  }
};
