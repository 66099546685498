import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Token } from '@app/types/token';
import { VStack, Button, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { NftPriceInput } from '@app/screens/nft-marketplace/SellNftForm/NftPriceInput';
import { NftFormAssetSection } from '@app/screens/nft-marketplace/SellNftForm/NftFormAssetSection';
import { Position } from '@app/screens/nft-marketplace/SpNftOffersModal/SpNftModalRow';
import { Discount } from '@app/screens/nft-marketplace/SellNftForm/Discount';
import { AuctionPeriodPicker } from '@app/screens/nft-marketplace/SellNftForm/AuctionPeridodPicker';
import { useConfig } from '@app/config';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { DropdownMenu } from '@app/components/DropdownMenu';
import styles from './SellNftForm.module.scss';
import { InfoButton } from '@app/screens/nft-marketplace/SellNftForm/InfoButton';
import { AuctionTypeDescription } from '@app/screens/nft-marketplace/SellNftForm/AuctionTypeDescription';
import { useToggle } from 'react-use';

interface SellNftFormFields {
  auctionType: string;
  sellPrice: number;
  reserve: number;
  token: Token;
  dateRange: Date[];
}

type Props = {
  position: Position;
};

export const BUY_IT_NOW_OPTION = '1';
export const BUY_IT_NOW_WITH_BIDS_OPTION = '2';
export const ENGLISH_AUCTION_OPTION = '3';
export const DUTCH_AUCTION_OPTION = '4';
export const BLIND_AUCTION_OPTION = '5';

type AuctionOption = {
  title: string;
  value: string;
};

const auctionOptions: AuctionOption[] = [
  {
    title: 'Buy it now',
    value: BUY_IT_NOW_OPTION
  },
  {
    title: 'Buy it now with bids',
    value: BUY_IT_NOW_WITH_BIDS_OPTION
  },
  {
    title: 'English Auction',
    value: ENGLISH_AUCTION_OPTION
  },
  {
    title: 'Dutch Auction',
    value: DUTCH_AUCTION_OPTION
  },
  {
    title: 'Blind Auction',
    value: BLIND_AUCTION_OPTION
  }
];

const SELL_NOW_PRICE_TOOLTIP_MESSAGE =
  'The price you are willing to sell without accepting bids';
const RESERVE_TOOLTIP_MESSAGE =
  'The minimum amount that a seller is willing to accept as the winning bid.';
const DISCOUNT_TOOLTIP_MESSAGE =
  'The difference between the deposit amount and sell price.';
const SELECT_AUCTION_PERIOD_TOOLTIP_MESSAGE =
  'Refers to the duration of time during which bidding takes place for a specific lot or item.';
const PRICE_OPTIONAL_TOOLTIP_MESSAGE =
  'The minimum amount above the current bid that the auctioneer asks other bidders to bid.';

export const SellNftForm: FC<Props> = ({ position }) => {
  const appConfig = useConfig();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm<SellNftFormFields>({
    defaultValues: {
      sellPrice: 0,
      reserve: 0,
      token: appConfig?.CONTENT.tokensWhitelist[11],
      auctionType: auctionOptions[0].value,
      dateRange: []
    }
  });
  const selectedToken = watch('token');
  const sellPrice = watch('sellPrice');
  const reserve = watch('reserve');
  const selectedAuction = watch('auctionType');
  const [startDate, endDate] = watch('dateRange');

  const sellPriceUsd = useTokenPriceV4(
    selectedToken.contractAddress,
    sellPrice
  );
  const reserveUsd = useTokenPriceV4(selectedToken.contractAddress, reserve);
  const [showAuctionInfo, setShowAuctionInfo] = useToggle(false);

  const formIsValid = () => {
    return (
      (selectedAuction === ENGLISH_AUCTION_OPTION || sellPrice > 0) &&
      (selectedAuction === BUY_IT_NOW_OPTION || reserve > 0) &&
      startDate instanceof Date &&
      endDate instanceof Date
    );
  };
  const renderReserveInput = () => {
    if (selectedAuction !== BUY_IT_NOW_OPTION) {
      return (
        <NftPriceInput
          title="Reserve"
          tokenSymbol={selectedToken.symbol}
          priceUsd={reserveUsd ?? 0}
          register={register}
          inputName="reserve"
          required={false}
          tooltipMessage={RESERVE_TOOLTIP_MESSAGE}
        />
      );
    }

    return null;
  };

  const renderButItNowDescription = () => {
    if (selectedAuction === BUY_IT_NOW_OPTION) {
      return (
        <Text
          color="neutral.300"
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
        >
          One single price for a buyer
        </Text>
      );
    }

    return null;
  };

  const renderInfoButton = () => {
    if (selectedAuction !== BUY_IT_NOW_OPTION) {
      return <InfoButton onClick={setShowAuctionInfo} />;
    }

    return null;
  };

  const renderAuctionCancellationWarning = () => {
    if (
      selectedAuction !== BUY_IT_NOW_OPTION &&
      selectedAuction !== BUY_IT_NOW_WITH_BIDS_OPTION
    ) {
      return (
        <Text
          color="orange"
          fontWeight="500"
          fontSize="16px"
          lineHeight="18px"
          alignSelf="center"
        >
          Auction cannot be cancelled between the start and end timestamps
        </Text>
      );
    }

    return null;
  };

  const onSubmit: SubmitHandler<SellNftFormFields> = data => console.log(data);

  return (
    <VStack
      paddingTop="32px"
      paddingBottom="32px"
      gap="16px"
      as="form"
      alignItems="flex-start"
      onSubmit={handleSubmit(onSubmit)}
    >
      <NftFormAssetSection position={position} />
      <VStack width="100%" alignItems="flex-start">
        <HStack alignItems="center">
          <Text
            fontWeight="400"
            fontSize="16px"
            color="neutral.300"
            lineHeight="16px"
          >
            Select Auction type
          </Text>
          {renderInfoButton()}
        </HStack>
        <Controller
          name="auctionType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DropdownMenu
              title="Select Auction type"
              items={auctionOptions}
              onSelect={onChange}
              selected={value}
              classes={{
                heading: styles.auctionTypeDropdownHeading,
                dropdownContainer: styles.auctionTypeDropdownContainer
              }}
            />
          )}
        />
        {renderButItNowDescription()}
        <AuctionTypeDescription
          isOpen={showAuctionInfo}
          selectedOptionId={selectedAuction}
        />
      </VStack>
      <Controller
        name="token"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TokenSelector
            selected={value}
            onSelect={onChange}
            filterByPools={true}
            disabled={false}
          />
        )}
      />
      <NftPriceInput
        title={
          selectedAuction === ENGLISH_AUCTION_OPTION
            ? 'Price (optional)'
            : 'Sell now price'
        }
        tokenSymbol={selectedToken.symbol}
        priceUsd={sellPriceUsd ?? 0}
        register={register}
        inputName="sellPrice"
        required={selectedAuction !== ENGLISH_AUCTION_OPTION}
        tooltipMessage={
          selectedAuction === ENGLISH_AUCTION_OPTION
            ? PRICE_OPTIONAL_TOOLTIP_MESSAGE
            : SELL_NOW_PRICE_TOOLTIP_MESSAGE
        }
      />
      {renderReserveInput()}
      <Discount
        depositedAmountUsd={position.usdAmount ?? 0}
        newPrice={sellPriceUsd ?? 0}
        tooltipMessage={DISCOUNT_TOOLTIP_MESSAGE}
      />
      <Controller
        control={control}
        name="dateRange"
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <AuctionPeriodPicker
            onChange={field.onChange}
            selectedDates={field.value ?? []}
            tooltipMessage={SELECT_AUCTION_PERIOD_TOOLTIP_MESSAGE}
          />
        )}
      />
      <Button
        borderRadius="10px"
        width="100%"
        backgroundColor="orange"
        color="white"
        padding="16px 0"
        type="submit"
        isDisabled={!formIsValid()}
        _disabled={{
          backgroundColor: 'neutral.700',
          cursor: 'not-allowed'
        }}
      >
        Sell kpNFT
      </Button>
      {renderAuctionCancellationWarning()}
    </VStack>
  );
};
