import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import qs from 'qs';

import { QueryKeys } from 'src/constants/queryKeys';

import {
  getWhitelistedCoinIcon,
  mapTokenResponseItem
} from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import { uniqWith } from 'lodash';
import { ApplicationConfig } from '@app/config/types';
import { useConfig } from '@app/config';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';

const LIMIT = 20;

interface QueryParams {
  q: string;
}

let nppFetched;

async function fetcher(
  pageParams: Record<string, string>,
  queryParams: QueryParams,
  config: ApplicationConfig | undefined
) {
  const TOKENS_WHITELIST = getTokensWhitelist(config);

  if (config?.CONTENT.useWhiteListTokens) {
    const q = queryParams.q?.toLowerCase() ?? '';

    return {
      tokens: TOKENS_WHITELIST.filter(
        item =>
          item.name?.toLowerCase().includes(q) ||
          item.symbol?.toLowerCase().includes(q) ||
          item.contractAddress?.toLowerCase().includes(q)
      ),
      next_page_params: undefined
    };
  }

  const params = queryParams.q
    ? {
        type: 'ERC-20',
        ...queryParams,
        ...pageParams
      }
    : { type: 'ERC-20', ...pageParams };

  const query = qs.stringify(params, {
    encodeValuesOnly: true
  });

  const response = await axios.get(
    `${config?.URLS.explorerApiUrl}/tokens?${query}`
  );

  return {
    tokens: uniqBy<Token>(
      [...response.data.items.map(mapTokenResponseItem)],
      'contractAddress'
    ),
    next_page_params: response.data.next_page_params
  };
}

export function useInfiniteTokensList(params: QueryParams, enabled: boolean) {
  const config = useConfig();
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    isLoading
  } = useInfiniteQuery<{
    tokens: Token[];
    next_page_params: Record<string, string>;
  }>(
    [QueryKeys.TOKENS_LIST, params.q],
    ({ pageParam }) => {
      return fetcher(pageParam, params, config);
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.next_page_params ?? undefined;
      },
      // staleTime: 60000,
      refetchOnWindowFocus: false,
      enabled
    }
  );

  let dataFlat = data?.pages.reduce<Token[]>((res, page) => {
    res.push(...page.tokens);

    return res;
  }, []);

  const q = params.q?.toLowerCase() ?? '';

  const TOKENS_WHITELIST = getTokensWhitelist(config);

  const wlTokensIds = TOKENS_WHITELIST.map(t =>
    t.contractAddress.toLowerCase()
  );

  const wlTokensSymbols = TOKENS_WHITELIST.map(t => t.symbol.toLowerCase());

  const whitelisted = TOKENS_WHITELIST.filter(
    item =>
      item.name?.toLowerCase().includes(q) ||
      item.symbol?.toLowerCase().includes(q) ||
      item.contractAddress?.toLowerCase().includes(q)
  );
  const whiteListedOrderedIds = whitelisted.map(w =>
    w.contractAddress.toLowerCase()
  );

  dataFlat = dataFlat
    ? uniqWith(
        [
          ...whitelisted.map(item => ({
            ...item,
            logoUri: getWhitelistedCoinIcon(item),
            whitelisted: true
          })),
          ...dataFlat
        ],
        (a, b) =>
          a.contractAddress.toLowerCase() === b.contractAddress.toLowerCase()
      )
        .filter(item => {
          if (!item.symbol) {
            return false;
          }

          if (wlTokensSymbols.includes(item.symbol.toLowerCase())) {
            return wlTokensIds.includes(item.contractAddress.toLowerCase());
          }

          return true;
        })
        .filter(item => {
          if (
            item.contractAddress.toLowerCase() ===
            config?.CONTRACTS.KIM?.toLowerCase()
          ) {
            return true;
          }

          if (
            item.contractAddress.toLowerCase() ===
            config?.CONTRACTS.X_KIM?.toLowerCase()
          ) {
            return false;
          }

          if (
            item.contractAddress.toLowerCase() ===
            '0xDfc7C877a950e49D2610114102175A06C2e3167a'.toLowerCase()
          ) {
            return true;
          }

          if (
            item.symbol.toLowerCase().startsWith('kim') ||
            item.symbol.toLowerCase().startsWith('mode')
          ) {
            return false;
          }

          return true;
        })
        .sort((a, b) => {
          if (wlTokensIds.includes(a.contractAddress.toLowerCase())) {
            return -1;
          } else {
            return 1;
          }
        })
    : undefined;

  dataFlat = dataFlat?.sort((a, b) => {
    if (whiteListedOrderedIds.includes(a.contractAddress.toLowerCase())) {
      return -1;
    } else {
      return 1;
    }
  });

  const totalLoaded =
    data?.pages.reduce((res, page) => {
      const pageLength = page.tokens.length;

      return res + pageLength;
    }, 0) ?? 0;

  return {
    dataFlat,
    totalLoaded,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    isLoading
  };
}
