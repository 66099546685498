import { useContract } from '@thirdweb-dev/react-core';
import { useNitroPool } from '@app/hooks/nitro/useNitroPool';

import NitroPool from 'abis/NitroPool.json';
import { useAsync } from 'react-use';
import { useAddress } from '@thirdweb-dev/react';
import { formatUnits } from 'ethers/lib/utils';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';

export function useNitroPoolPositionPendingRewards(
  nftPoolAddress: string | undefined,
  isStakedToNitro: boolean,
  nitroPoolId?: string
) {
  const { data } = useNitroPool(nftPoolAddress, nitroPoolId);
  const address = useAddress();

  const { contract } = useContract(data?.id, NitroPool.abi);

  const { value } = useAsync(async () => {
    if (!contract || !address || !isStakedToNitro || !data) {
      return;
    }

    const res = await contract.call('pendingRewards', [address]);

    const pendingRewards1 = formatUnits(
      res.pending1,
      data.rewardsToken1.decimals
    );
    const pendingRewards2 = formatUnits(
      res.pending2,
      data.rewardsToken2.decimals
    );

    return {
      pendingRewards1,
      pendingRewards2
    };
  }, [contract, address, isStakedToNitro, data]);

  const reward1Usd = useTokenPriceV4(
    data?.rewardsToken1.id,
    value?.pendingRewards1
  );

  const reward2Usd = useTokenPriceV4(
    data?.rewardsToken2.id,
    value?.pendingRewards2
  );

  const totalRewardsUsd = (reward1Usd ?? 0) + (reward2Usd ?? 0);

  return {
    totalRewardsUsd,
    reward1Usd,
    reward2Usd,
    pendingRewards1: value?.pendingRewards1,
    pendingRewards2: value?.pendingRewards2
  };
}
