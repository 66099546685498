import { useContract } from '@thirdweb-dev/react';
import { useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import xKimToken from '../../../../../abis/XKimToken.json';
import { ethers } from 'ethers';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';

export function useUnboostPosition(poolAddress: string, tokenId: string) {
  const queryClient = useQueryClient();
  const [state, setState] = useState<{
    amount: string;
  }>({
    amount: ''
  });

  const handleAmountChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      amount: val
    }));
  }, []);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      amount: ''
    }));
  }, []);
  const config = useConfig();

  const { contract: tokenContract } = useContract(
    config?.CONTRACTS.X_KIM,
    xKimToken.abi
  );

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!tokenContract) {
      return;
    }

    const res = await tokenContract?.call('deallocate', [
      config?.CONTRACTS.YIELD_BOOSTER,
      ethers.utils.parseUnits(state.amount, 18),
      ethers.utils.defaultAbiCoder.encode(
        ['address', 'uint'],
        [poolAddress, tokenId]
      )
    ]);

    await queryClient.invalidateQueries([QueryKeys.BOOSTER_STATS]);
    await queryClient.invalidateQueries([QueryKeys.BOOST_DETAILS]);

    return res;
  }, [state.amount, tokenId, tokenContract, poolAddress, queryClient, config]);

  return {
    state,
    handleAmountChange,
    resetStateValues,
    submit,
    submitting
  };
}
