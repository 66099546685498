import React, { FC, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Box,
  Text,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  HStack,
  Button
} from '@chakra-ui/react';
import cn from 'clsx';
import s from '@app/components/DropdownMenu/DropdownMenu.module.scss';
import { OffersModalTab } from '@app/screens/nft-marketplace/SpNftOffersModal/OffersModalTab';
import { MySpNftTab } from '@app/screens/nft-marketplace/SpNftOffersModal/MySpNftTab';
import { Icon } from '@app/components/Icon';

type Props = {
  isOpen: boolean;
  onOpenClick: (value: boolean) => void;
  referenceElement: HTMLElement | null;
};

const OFFERS_MADE_TAB = 'Offers made';
const OFFERS_RECEIVED_TAB = 'Offers received';
const MY_KP_NFT_TAB = 'My kpNFT’s';

export const SpNftOffersModalPopper: FC<Props> = ({
  isOpen,
  onOpenClick,
  referenceElement
}) => {
  const tabNames = [OFFERS_MADE_TAB, OFFERS_RECEIVED_TAB, MY_KP_NFT_TAB];
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);
  const [currentSelectedTabName, setCurrentSelectedTabName] =
    useState(OFFERS_MADE_TAB);
  const [popperElement, setPopperElement] = useState<HTMLElement>();

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    setMounted(true);
  }, []);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  const handleTabChange = (index: number) => {
    setCurrentSelectedTabName(tabNames[index]);
  };

  const renderTabs = () => {
    return (
      <Box
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        style={styles.popper}
        {...attributes.popper}
        className={cn(s.dropdown)}
        backgroundColor="gray.16"
        borderRadius="12px"
      >
        <motion.div
          transition={{ delay: 0, duration: 0.2 }}
          initial={{ opacity: 0, transform: 'translateY(10px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          exit={{
            opacity: 0
          }}
        >
          <Tabs
            width="920px"
            maxWidth="920px"
            position="relative"
            variant="unstyled"
            padding="16px 32px 32px 32px"
            onChange={handleTabChange}
          >
            <HStack justifyContent="space-between" padding="0 16px 16px 16px">
              <Text>{currentSelectedTabName}</Text>
              <Button onClick={() => onOpenClick(false)}>
                <Icon name="cross" />
              </Button>
            </HStack>
            <TabList
              justifyContent="space-around"
              borderRadius="12px"
              gap="3px"
              padding="8px"
              backgroundColor="gray.1"
            >
              <OffersModalTab title={OFFERS_MADE_TAB} />
              <OffersModalTab title={OFFERS_RECEIVED_TAB} />
              <OffersModalTab title={MY_KP_NFT_TAB} />
            </TabList>
            <TabPanels
              height="400px"
              overflowY="auto"
              paddingTop="16px"
              sx={{
                '&::-webkit-scrollbar': {
                  display: 'none'
                },
                scrollbarWidth: 'none'
              }}
            >
              <TabPanel>
                <MySpNftTab />
              </TabPanel>
              <TabPanel>
                <MySpNftTab />
              </TabPanel>
              <TabPanel>
                <MySpNftTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </motion.div>
      </Box>
    );
  };

  return (
    mounted &&
    referenceElement &&
    ReactDOM.createPortal(
      <AnimatePresence>{isOpen && renderTabs()}</AnimatePresence>,
      referenceElement
    )
  );
};
