import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Box,
  HStack,
  Button,
  Text,
  VStack
} from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { SubmitButton } from '@app/components/SubmitButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { Vault } from '@app/hooks/plugins/useVaults';
import { TokensPair } from '@app/components/TokensPair';
import { useNitroPoolDetails } from '@app/hooks/nitro/useNitroPoolDetails';
import { formatNumber, kFormatter } from '@app/helpers/format';
import Countdown from 'react-countdown';
import { format, formatDistanceStrict } from 'date-fns';
import { useUnstakeFromNitroPool } from '@app/screens/positions/components/SpNftControls/UntakeFromNitroPool/hooks';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';
import { formatUSD } from '@app/helpers/formatUSD';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  poolAddress: string;
  nitroPoolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
}

export const UnstakeFromNitroPoolModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  poolAddress,
  onResult,
  nitroPoolAddress
}) => {
  const isMismatchedNetwork = useNetworkMismatch();

  const { submit, submitting } = useUnstakeFromNitroPool(
    poolAddress,
    tokenId,
    nitroPoolAddress
  );

  const {
    data: nitroPoolDetails,
    tvlUsd,
    totalApr,
    isLoading
  } = useNitroPoolDetails(poolAddress);

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        disabled={submitting}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction confirmed`,
                <ExternalLink txHash={txHash} />
              );

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Unstake"
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '0px' }}>
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Unstake from Nitro pool"
            subtitle=""
          />
          {isLoading ? (
            <div>Loading...</div>
          ) : nitroPoolDetails ? (
            <VStack
              justifyContent="space-between"
              mt="18px"
              padding="12px"
              gap="12px"
              sx={{
                borderRadius: '10px',
                border: '1px solid',
                borderColor: 'gray.5',
                background: 'black'
              }}
            >
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  Rewards
                </Text>
                <TokensPair
                  token0Symbol={nitroPoolDetails?.rewardToken1?.symbol ?? ''}
                  token1Symbol={nitroPoolDetails?.rewardToken2?.symbol ?? ''}
                />
              </HStack>
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  TVL
                </Text>
                <Text fontSize="14px" color="white">
                  {formatUSD.format(tvlUsd ? +tvlUsd : 0)}
                </Text>
              </HStack>
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  APR
                </Text>
                <Text fontSize="14px" color="white">
                  {`${formatNumber(totalApr, 2)}%`}
                </Text>
              </HStack>
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  Ends in
                </Text>
                <Box fontSize="14px" color="neutral.400">
                  <Countdown
                    date={new Date(+nitroPoolDetails.nitroPool.endTime * 1000)}
                    renderer={({
                      days,
                      hours,
                      minutes,
                      seconds,
                      completed
                    }) => {
                      if (completed) {
                        return (
                          <Text fontSize="16px" fontWeight={400} color="white">
                            Time is up!
                          </Text>
                        );
                      } else {
                        return (
                          <>
                            <Text
                              fontSize="16px"
                              fontWeight={400}
                              color="white"
                            >
                              {days ? `${days}d ` : ''}
                            </Text>
                          </>
                        );
                      }
                    }}
                  />
                </Box>
              </HStack>
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  Minimum amount
                </Text>
                <Text fontSize="14px" color="white">
                  {nitroPoolDetails.nitroPool.requiredDepositAmount}
                </Text>
              </HStack>
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  Minimum lock
                </Text>
                <Text fontSize="14px" color="white">
                  {nitroPoolDetails?.nitroPool.requiredLockDuration &&
                  nitroPoolDetails?.nitroPool.requiredLockDuration !== '0'
                    ? formatDistanceStrict(
                        new Date(0),
                        new Date(
                          new Date(0).getTime() +
                            +nitroPoolDetails.nitroPool.requiredLockDuration *
                              1000
                        )
                      )
                    : '-'}
                </Text>
              </HStack>
              <HStack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text fontSize="14px" color="neutral.400">
                  Locked until
                </Text>
                <Text fontSize="14px" color="white">
                  {format(
                    +nitroPoolDetails.nitroPool.depositEndTime * 1000,
                    'dd MMM yyyy, hh:mm'
                  )}
                </Text>
              </HStack>
            </VStack>
          ) : null}

          <HStack mt={6}>
            <Button
              variant="secondary"
              onClick={() => {
                onClose();
              }}
              w="100%"
            >
              Cancel
            </Button>
            {renderSubmit()}
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};
