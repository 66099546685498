import { HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { calculateDiscountOrPremium } from '@app/helpers/calculateDiscountOrPremium';
import { usePopperHook } from '@app/hooks/other/usePopperHook';
import { SellNftTooltip } from '@app/screens/nft-marketplace/SellNftForm/SellNftTooltip';

type Props = {
  depositedAmountUsd: number;
  newPrice: number;
  tooltipMessage: string;
};

export const Discount: FC<Props> = ({
  depositedAmountUsd,
  newPrice,
  tooltipMessage
}) => {
  const { referenceElement, setReferenceElement, setShowTooltip, showTooltip } =
    usePopperHook();
  const renderPercent = () => {
    return depositedAmountUsd > 0 && newPrice > 0
      ? `${calculateDiscountOrPremium(depositedAmountUsd, newPrice)}%`
      : '0%';
  };

  return (
    <HStack
      w="100%"
      bg="neutral.1000"
      padding="16px"
      borderRadius="12px"
      overflow="hidden"
      justifyContent="space-between"
      ref={setReferenceElement}
    >
      <Text
        fontWeight="400"
        fontSize="16px"
        color="neutral.300"
        lineHeight="16px"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        Discount/ Premium
      </Text>
      <Text fontSize="24px" lineHeight="25px" fontWeight="400" color="white">
        {renderPercent()}
      </Text>
      <SellNftTooltip
        isOpen={showTooltip}
        referenceElement={referenceElement}
        message={tooltipMessage}
      />
    </HStack>
  );
};
