import { ModeEnvs } from '@app/constants/chains';
import { ApplicationConfig } from '@app/config/types';
import { Token } from '@app/types/token';

export const baseMainnetConfig: ApplicationConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ?? '',
  CONTENT: {
    tokensWhitelist: process.env.NEXT_PUBLIC_BASE_WHITELIST_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_BASE_WHITELIST_TOKENS
        ) as unknown as Token[])
      : [],
    useWhiteListTokens: true,
    publicSaleLink: '',
    poolsOrder: [],
    hiddenPools: [],
    defaultTokens: {
      from: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      to: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913'
    }
  },
  ALGEBRA_ADDRESSES: {
    POOL_INIT_CODE_HASH:
      '0xf96d2474815c32e070cd63233f06af5413efc5dcb430aee4ff18cc29007c562d',
    ALGEBRA_FACTORY: '0x2F0d41f94d5D1550b79A83D2fe85C82d68c5a3ca',
    ALGEBRA_POOL_DEPLOYER: '0x872f5746B3D8CC46A876cBa2269813733A56eB1D',
    ALGEBRA_POSITION_MANAGER: '0xB7cF725E5b697F8338B929e6A52df823F2120a6A',
    ALGEBRA_QUOTER: '0x59844C00227fD2A65db8fccD55b0e242d6A93777',
    ALGEBRA_QUOTER_V2: '0x901F8036d8d3edf19ef1CF69B05bC4dA41C7aa8f',
    ALGEBRA_ROUTER: '0x44447789719A1fAE137b385fB7326Cdcd3f49c49'
  },
  METROM: {
    DISTRIBUTOR: '0xD1D3Cf05Ef211C71056f0aF1a7FD1DF989E109c3'
  },
  MERKL: {
    DISTRIBUTOR: ''
  },
  AIRDROP_CONTRACTS: {},
  AIRDROP_TOKENS: {},
  STEER: {
    PROXY_HELPER: ''
  },
  ICHI: {
    PROXY_HELPER: ''
  },
  CONTRACTS: {
    KIM_MASTER: '',
    FACTORY: '0x14658340D7D1c112b62509bbF449be1897e8dE01',
    ROUTER: '0xC479f0C3E98a8Aff27F43615e24824fB8f5b98C4',
    WETH: '0x4200000000000000000000000000000000000006',
    KIM: '0x5dc25aa049837b696d1dc0f966ac8df1491f819b',
    X_KIM: '0x7B8C5d97c25B65f89817E8046851A32e963fc9cD',
    DIVIDENDS: '0x61A395f822eD86660EB37adCE4bFcd4748945AA8',
    NFT_POOL_FACTORY: '',
    YIELD_BOOSTER: '',
    NITRO_POOL_FACTORY: '',
    NFT_MARKETPLACE: ''
  },
  FLAGS: {
    showPointsBadge: false,
    showLeaderboardPage: false,
    showReferralsPage: false,
    showRyoPages: true,
    showInfoPage: false,
    showNitroPage: false,
    showBoosterPage: false,
    showRewardsPage: true,
    showPartnersPage: false,
    showStake: false,
    showSpNft: false,
    showAnnouncement: false,
    showAirdropClaim: false,
    showBadges: [],
    showVaults: false,
    showRouterIntentPage: true,
    showDosirakPage: false,
    showNftMarketplace: false,
    showMagicAddress: false
  },
  URLS: {
    ichiAggregatorUrl: '',
    ichiSubgraphUrl: '',
    subgraphUrl:
      'https://api.goldsky.com/api/public/project_clwz69zvvp7ix01w76khbha61/subgraphs/kim-amm/0.0.1/gn',
    middlewareUrl: '',
    explorerApiUrl: '',
    subgraphUrlV3:
      'https://api.goldsky.com/api/public/project_clwz69zvvp7ix01w76khbha61/subgraphs/analytics/1.0.0/gn',
    subgraphUrlSteer: '',
    middlewareUrlV3: 'https://backend.kim.exchange/api',
    merklApiUrl: '',
    metromApiUrl: 'https://api.metrom.xyz',
    steerApiUrl: '',
    subgraphUrlNitroPools: '',
    nftPoolsSubgraph: '',
    subgraphUrlNftMarketplace: ''
  }
};
