import React, { FC, useCallback } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Button,
  Text,
  Box
} from '@chakra-ui/react';
import { useToggle } from 'react-use';
import { SelectTokenContent } from '@app/screens/positions/components/SelectTokenContent';
import { Token } from '@app/types/token';
import { useNftTokens } from '@app/hooks/positions/useNftTokens';
import { useAddress } from '@thirdweb-dev/react';
import { Vault } from '@app/hooks/plugins/useVaults';

interface Props {
  inlineView?: boolean;
}

export const ImportTokenButton: FC<Props> = ({ inlineView }) => {
  const address = useAddress();
  const [isOpen, toggleIsOpen] = useToggle(false);

  const { updateNftTokens, tokens } = useNftTokens(address);

  const handleImport = useCallback(
    async (selectedToken: Token | Vault) => {
      await updateNftTokens([
        ...(tokens ?? []),
        'contractAddress' in selectedToken
          ? selectedToken.contractAddress
          : selectedToken.lpToken.id
      ]);
      toggleIsOpen(false);
    },
    [updateNftTokens, tokens, toggleIsOpen]
  );

  return (
    <>
      {inlineView ? (
        <Box
          as="span"
          fontSize="16px"
          color="orange"
          cursor="pointer"
          onClick={() => toggleIsOpen()}
          display="inline"
        >
          import&nbsp;
        </Box>
      ) : (
        <Button
          variant="secondary"
          isLoading={false}
          disabled={false}
          onClick={() => toggleIsOpen()}
          loadingText="Processing..."
          maxWidth="200px"
          mt={0}
          _hover={{ filter: 'brightness(120%)' }}
        >
          <Text
            fontSize="14px"
            fontFamily="'Inter', sans-serif"
            fontWeight="500"
            // lineHeight="20px"
          >
            Import token
          </Text>
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          toggleIsOpen(false);
        }}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent sx={{ maxWidth: ['100vw', '600px'] }}>
          <SelectTokenContent
            onClose={() => {
              toggleIsOpen(false);
            }}
            onSelect={token => handleImport(token)}
          />
        </ModalContent>
      </Modal>
    </>
  );
};
