import React, { FC } from 'react';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';
import { useLockPosition } from '@app/screens/positions/components/SpNftControls/LockPosition/hooks';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { SubmitButton } from '@app/components/SubmitButton';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';
import { DebouncedInput } from '@app/components/DebouncedInput';
import { InfoRow } from '@app/screens/positions/components/SpNftControls/InfoRow/InfoRow';
import { PairToken } from '@app/types/pool';
import { Vault } from '@app/hooks/plugins/useVaults';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';

interface Props {
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  reqLockDurationInDays: number;
  lockDurationInDays: number;
  onSuccess: () => void;
}

export const LockPositionStep: FC<Props> = ({
  onClose,
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  reqLockDurationInDays,
  lockDurationInDays,
  onSuccess
}) => {
  const isMismatchedNetwork = useNetworkMismatch();
  const isLpToken = 'lpToken' in tokenDetails;
  const {
    state,
    resetStateValues,
    submit,
    submitting,
    handleDurationChange,
    getLockDuration
  } = useLockPosition(tokenDetails, poolAddress, tokenId, true);

  const queryClient = useQueryClient();

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction Confirmed`,
                <ExternalLink txHash={txHash} />
              );

              await queryClient.invalidateQueries({
                queryKey: [QueryKeys.STAKING_POSITION]
              });

              await getLockDuration();

              onSuccess();
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }
          }
        }}
        isLoading={submitting}
        label="Lock"
      />
    );
  }

  return (
    <>
      <HStack justifyContent="space-between" my={3}>
        <Text fontSize="16px" fontWeight={400} lineHeight="26px">
          Lock duration
        </Text>
        <HStack w="100%" justifyContent="flex-end">
          <Button
            variant="tertiary"
            onClick={() => {
              if (+state.duration !== 0) {
                handleDurationChange((+state.duration - 1).toString());
              }
            }}
            sx={{
              color: 'orange',
              bg: 'gray.2',
              width: 'fit-content',
              fontSize: '12px',
              padding: '4px 12px'
            }}
          >
            <Icon name="minus" />
          </Button>
          <HStack
            sx={{
              paddingRight: '14px',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: 'gray.5',
              background: 'black',
              flex: 0.5
            }}
          >
            <DebouncedInput
              amount={state.duration}
              onChange={handleDurationChange}
              placeholder="0"
            />
          </HStack>
          <Button
            variant="tertiary"
            onClick={() => {
              handleDurationChange((+state.duration + 1).toString());
            }}
            sx={{
              color: 'orange',
              bg: 'gray.2',
              width: 'fit-content',
              fontSize: '12px',
              padding: '4px 12px'
            }}
          >
            <Icon name="plus" />
          </Button>
          <Text fontSize="16px" fontWeight={400} lineHeight="26px">
            days
          </Text>
        </HStack>
      </HStack>
      <VStack gap={2} alignItems="flex-start" mt={4}>
        <InfoRow
          label="Minimum lock duration:"
          value0={`${reqLockDurationInDays} days`}
        />
        <InfoRow
          label="Currently locked for:"
          value0={`${lockDurationInDays} days`}
        />
      </VStack>

      <HStack mt={6}>
        <Button
          variant="secondary"
          py="10px"
          px="20px"
          borderRadius="12px"
          onClick={() => {
            resetStateValues();
            onClose();
          }}
          w="100%"
        >
          Cancel
        </Button>
        {renderSubmit()}
      </HStack>
    </>
  );
};
