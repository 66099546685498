import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import styles from './TokenSymbolSection.module.scss';
import cn from 'clsx';

interface Props {
  token0Symbol: string;
  token1Symbol: string | undefined;
  className?: string;
  separator?: string;
}

export const TokenSymbolSection: FC<Props> = ({
  token0Symbol,
  token1Symbol,
  className,
  separator = '-'
}) => {
  return (
    <Text className={cn(styles.root, className)}>
      {token1Symbol ? (
        <>
          {token0Symbol}
          {separator}
          {token1Symbol}
        </>
      ) : (
        token0Symbol ?? ''
      )}
    </Text>
  );
};
