import React, { FC, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useToggle } from 'react-use';
import { PairToken } from '@app/types/pool';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { Vault } from '@app/hooks/plugins/useVaults';
import { WithdrawFromPositionModal } from '@app/screens/booster/components/UnboostPosition/WithdrawFromPositionModal';
import { Tooltip } from '@app/components/Tooltip';
import { GoRocket } from 'react-icons/go';

interface Props {
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string | undefined;
  poolAddress: string;
  onOpen?: (val: boolean) => void;
  showLabel?: boolean;
}

export const UnboostPosition: FC<Props> = ({
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  onOpen,
  showLabel
}) => {
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <Tooltip tooltip="Unboost Position">
        <Button
          variant="secondary"
          onClick={e => {
            e.stopPropagation();
            toggleOpen(true);
            onOpen?.(true);
          }}
          _hover={{
            color: 'orange'
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '14px',
            width: '100%'
          }}
        >
          <GoRocket size="16px" style={{ transform: 'rotate(90deg)' }} />
          {showLabel && 'Unboost'}
        </Button>
      </Tooltip>
      <WithdrawFromPositionModal
        isOpen={open}
        onClose={() => {
          toggleOpen(false);
          onOpen?.(false);
        }}
        tokenDetails={tokenDetails}
        tokenId={tokenId}
        deposit={deposit}
        poolAddress={poolAddress}
        onResult={res => setFeedback(res)}
      />
      <TransactionResultModal
        isOpen={!!feedback}
        status={feedback?.status}
        title={feedback?.title}
        message={feedback?.message}
        txHash={feedback?.txHash}
        onClose={() => {
          setFeedback(null);
          toggleOpen(false);
          onOpen?.(false);
        }}
      />
    </>
  );
};
