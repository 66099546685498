import { useAddress, useContract } from '@thirdweb-dev/react';
import { useCallback, useEffect, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { PairToken } from '@app/types/pool';
import nftPoolAbi from 'abis/nftPool.json';
import xKimToken from 'abis/XKimToken.json';
import { Vault } from '@app/hooks/plugins/useVaults';
import { ethers } from 'ethers';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';

export function useBoost(
  tokenDetails: PairToken | Vault,
  poolAddress: string,
  tokenId: string
) {
  const config = useConfig();
  const queryClient = useQueryClient();
  const address = useAddress();
  const [state, setState] = useState<{
    amount: string;
  }>({
    amount: ''
  });

  const handleAmountChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      amount: val
    }));
  }, []);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      amount: ''
    }));
  }, []);

  const { contract: tokenContract } = useContract(
    config?.CONTRACTS.X_KIM,
    xKimToken.abi
  );
  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const { value, loading: loadingTokenBalance } = useAsync(async () => {
    if (!tokenContract || !address) {
      return;
    }

    const tokenBalance = await tokenContract.call('balanceOf', [address]);

    return {
      tokenBalance: ethers.utils.formatUnits(tokenBalance, 18)
    };
  }, [tokenContract, address, tokenDetails]);

  const [{ value: allowance, loading: isFetchingAllowance }, getAllowance] =
    useAsyncFn(async () => {
      if (!tokenContract || !address) {
        return;
      }

      try {
        const res = await tokenContract.call('getUsageApproval', [
          address,
          config?.CONTRACTS.YIELD_BOOSTER
        ]);

        return ethers.utils.formatUnits(res, 18);
      } catch (e) {
        // do nothing
        console.error(e);
      }
    }, [tokenContract, address, poolAddress, tokenDetails, config]);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!tokenContract || !address) {
      return;
    }

    const res = await tokenContract?.call('allocate', [
      config?.CONTRACTS.YIELD_BOOSTER,
      ethers.utils.parseUnits(state.amount, 18),
      ethers.utils.defaultAbiCoder.encode(
        ['address', 'uint'],
        [poolAddress, tokenId]
      )
    ]);

    await getAllowance();

    await queryClient.invalidateQueries([QueryKeys.BOOSTER_STATS]);
    await queryClient.invalidateQueries([QueryKeys.BOOST_DETAILS]);

    return res;
  }, [
    poolContract,
    address,
    state.amount,
    tokenId,
    poolAddress,
    tokenContract,
    queryClient,
    config,
    getAllowance
  ]);

  const [{ loading: approving }, approve] = useAsyncFn(async () => {
    if (!tokenContract) {
      return;
    }

    return tokenContract.call('approveUsage', [
      config?.CONTRACTS.YIELD_BOOSTER,
      ethers.utils.parseUnits(state.amount, 18)
    ]);
  }, [tokenContract, state, allowance]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  return {
    requiresApproval:
      !isFetchingAllowance &&
      allowance !== undefined &&
      Number(state.amount) > +allowance,
    allowance,
    getAllowance,
    state,
    handleAmountChange,
    loadingTokenBalance,
    tokenBalance: value?.tokenBalance,
    resetStateValues,
    submit,
    submitting,
    approving,
    approve
  };
}
