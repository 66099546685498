import { Button } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  onClick: () => void;
};

export const ListNftButton: FC<Props> = ({ onClick }) => {
  return (
    <Button
      fontSize="16px"
      fontWeight="500"
      lineHeight="26px"
      border="1px solid"
      borderColor="orange"
      padding="8px 32px"
      borderRadius="8px"
      color="orange"
      backgroundColor="gray.11"
      maxWidth="100px"
      onClick={onClick}
    >
      LIST
    </Button>
  );
};
