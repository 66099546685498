import { useVaults, Vault } from '@app/hooks/plugins/useVaults';
import { useIchiVaultsList } from '@app/hooks/ichi/useIchiVaultsList';
import { useMemo } from 'react';

export function useAllProvidersVaults() {
  const { data: steerVaults, isLoading: isLoadingSteerVaults } = useVaults();
  const { data: ichiVaults, isLoading: isLoadingIchiVaults } =
    useIchiVaultsList();

  const isLoading = isLoadingSteerVaults && isLoadingIchiVaults;

  const data = useMemo(() => {
    const _steerVaults =
      steerVaults?.map(item => {
        return {
          ...item,
          provider: 'Steer'
        };
      }) ?? [];
    const _ichiVaults =
      ichiVaults?.map(item => {
        return {
          ...item,
          provider: 'Ichi'
        };
      }) ?? [];

    return [..._ichiVaults, ..._steerVaults] as Vault[];
  }, [ichiVaults, steerVaults]);

  return { data, isLoading };
}
