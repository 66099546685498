import React, { FC, useMemo } from 'react';
import { Modal, ModalContent, ModalOverlay, Box } from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { Vault } from '@app/hooks/plugins/useVaults';
import { useNitroPoolDetails } from '@app/hooks/nitro/useNitroPoolDetails';
import { formatNumber } from '@app/helpers/format';
import { format } from 'date-fns';
import { formatUSD } from '@app/helpers/formatUSD';
import { useKpNftLockDetails } from '@app/hooks/plugins/useKpNftLockDetails';
import { useStakingPosition } from '@app/hooks/positions/useStakingPosition';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { MultiStepNitroStake } from '@app/screens/positions/components/MultiStepNitroStake';
import { StakeIntoNitroStep } from '@app/screens/positions/components/MultiStepNitroStake/components/StakeIntoNitroStep';

interface Props {
  deposit: string;
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  poolAddress: string;
  nitroPoolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
}

export const StakeToNitroPoolModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  poolAddress,
  onResult,
  nitroPoolAddress,
  deposit
}) => {
  const {
    data: nitroPoolDetails,
    isLoading,
    tvlUsd,
    totalApr
  } = useNitroPoolDetails(poolAddress);

  const { data } = useStakingPosition({
    tokenId,
    poolAddress
  });

  const lockDetails = useKpNftLockDetails(data);

  const { isLockRequired, reqLockDurationInDays, lockDurationInDays } =
    useMemo(() => {
      if (!nitroPoolDetails || !lockDetails) {
        return {
          isLockRequired: true,
          reqLockDurationInDays: 0,
          lockDurationInDays: lockDetails?.lockDurationInDays
        };
      }

      const reqLockDurationInDays = Math.ceil(
        +nitroPoolDetails.nitroPool.requiredLockDuration / 3600 / 24
      );

      const lockedDuration = lockDetails.lockDurationInDays;

      return {
        isLockRequired: reqLockDurationInDays > lockedDuration,
        reqLockDurationInDays,
        lockDurationInDays: lockedDuration
      };
    }, [lockDetails, nitroPoolDetails]);

  function renderContent() {
    if (isLoading) {
      return <ListLoader />;
    }

    if (nitroPoolDetails && isLockRequired) {
      return (
        <MultiStepNitroStake
          onClose={onClose}
          tokenId={tokenId}
          poolAddress={poolAddress}
          onResult={onResult}
          tokenDetails={tokenDetails}
          deposit={deposit}
          reqLockDurationInDays={reqLockDurationInDays}
          lockDurationInDays={lockDurationInDays}
          nitroPoolAddress={nitroPoolAddress}
          token0Symbol={
            nitroPoolDetails?.nitroPool.rewardsToken1Amount !== '0'
              ? nitroPoolDetails?.nitroPool.rewardsToken1?.symbol
              : undefined
          }
          token1Symbol={
            nitroPoolDetails?.nitroPool.rewardsToken2Amount
              ? nitroPoolDetails?.nitroPool.rewardsToken2?.symbol
              : undefined
          }
          tvl={formatUSD.format(tvlUsd ? +tvlUsd : 0)}
          apr={`${formatNumber(totalApr, 2)}%`}
          endTime={new Date(+nitroPoolDetails.nitroPool.endTime * 1000)}
          minimumAmount={
            nitroPoolDetails?.nitroPool.requiredDepositAmount
              ? nitroPoolDetails.nitroPool.requiredDepositAmount || '-'
              : '-'
          }
          minimumLock={
            nitroPoolDetails?.nitroPool.requiredLockDuration &&
            nitroPoolDetails?.nitroPool.requiredLockDuration !== '0'
              ? `${Math.ceil(
                  +nitroPoolDetails.nitroPool.requiredLockDuration / 3600 / 24
                )} days`
              : '-'
          }
          lockedUntil={
            lockDetails.lockedUntilDate
              ? format(lockDetails.lockedUntilDate, 'dd MMM yyyy, hh:mm')
              : '-'
          }
        />
      );
    }

    if (nitroPoolDetails) {
      return (
        <StakeIntoNitroStep
          nitroPoolAddress={nitroPoolAddress}
          onClose={onClose}
          onResult={onResult}
          poolAddress={poolAddress}
          deposit={deposit}
          tokenId={tokenId}
          tokenDetails={tokenDetails}
          token0Symbol={
            nitroPoolDetails?.nitroPool.rewardsToken1Amount !== '0'
              ? nitroPoolDetails?.nitroPool.rewardsToken1?.symbol
              : undefined
          }
          token1Symbol={
            nitroPoolDetails?.nitroPool.rewardsToken2Amount
              ? nitroPoolDetails?.nitroPool.rewardsToken2?.symbol
              : undefined
          }
          tvl={formatUSD.format(tvlUsd ? +tvlUsd : 0)}
          apr={`${formatNumber(totalApr, 2)}%`}
          endTime={new Date(+nitroPoolDetails.nitroPool.endTime * 1000)}
          minimumAmount={
            nitroPoolDetails?.nitroPool.requiredDepositAmount
              ? nitroPoolDetails.nitroPool.requiredDepositAmount || '-'
              : '-'
          }
          minimumLock={
            nitroPoolDetails?.nitroPool.requiredLockDuration &&
            nitroPoolDetails?.nitroPool.requiredLockDuration !== '0'
              ? `${Math.ceil(
                  +nitroPoolDetails.nitroPool.requiredLockDuration / 3600 / 24
                )} days`
              : '-'
          }
          lockedUntil={
            lockDetails.lockedUntilDate
              ? format(lockDetails.lockedUntilDate, 'dd MMM yyyy, hh:mm')
              : '-'
          }
        />
      );
    }

    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '0px' }}>
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Stake into a Nitro pool"
            subtitle="Deposit your kpNFT into a Nitro to earn additional yield"
          />
          {renderContent()}
        </Box>
      </ModalContent>
    </Modal>
  );
};
