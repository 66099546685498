import { Box, Flex, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import s from '@app/screens/positions/components/PositionsList/PositionsList.module.scss';
import { PairToken } from '@app/types/pool';
import { SpNftRowDetailsModal } from '@app/screens/positions/components/SpNftRowDetailsModal';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { useMedia, useToggle } from 'react-use';
import { useRouter } from 'next/router';
import { APP_ROUTES } from '@app/constants/routes';
import { LogoSection } from './components/LogoSection';
import { SymbolsSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/SymbolsSection';
import { AmountSection } from './components/AmountSection';
import { APRSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/APRSection';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import { RewardsSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/RewardsSection';
import { UserAllocationSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/UserAllocationSection';
import { ActionsSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/ActionsSection';
import { StrategySection } from '@app/components/StrategySection';
import { useNitroPool } from '@app/hooks/nitro/useNitroPool';
import { useYieldBoosterApr } from '@app/hooks/booster/useYieldBoosterApr';
import { usePositionKpNftDetailedData } from '@app/hooks/positions/usePositionKpNftDetailedData';
import { usePositionsFiltersState } from '@app/state/positionsFiltersStore';

export interface SpNftPositionListRowItemProp {
  poolAddress: string;
  tokenId: string;
  tokenDetails: PairToken | undefined;
  boosterView?: boolean;
  isStakedToNitro?: boolean;
  nitroPoolId?: string;
}

export const SpNftPositionsListRowItem: FC<SpNftPositionListRowItemProp> = ({
  poolAddress,
  tokenId,
  tokenDetails,
  boosterView,
  isStakedToNitro,
  nitroPoolId
}) => {
  const isMobile = useMedia('(max-width: 960px)', true);
  const { searchTerm, provider } = usePositionsFiltersState();
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);
  const router = useRouter();
  const [open, toggleOpen] = useToggle(false);

  let {
    data,
    vault,
    usdAmount,
    pendingRewards,
    isLoading,
    lockDetails,
    rawTotalApr,
    totalApr,
    bonusApr,
    lockBonusApr,
    farmBaseApr,
    nitroPoolPositionPendingRewards,
    marketMakerAPR,
    nitroPool
  } = usePositionKpNftDetailedData(
    poolAddress,
    tokenId,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId
  );

  function renderRow() {
    if (isLoading) {
      return null;
    }

    if (!data || !tokenDetails) {
      return null;
    }

    return (
      <>
        {isMobile ? (
          <VStack gap={3} width="100%">
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <LogoSection vault={vault} tokenDetails={tokenDetails} />
              <SymbolsSection vault={vault} tokenDetails={tokenDetails} />
              <Text fontSize="14px" color="gray.4">
                ID: {tokenId}
              </Text>
            </HStack>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text fontSize="14px" color="gray.4">
                Strategy
              </Text>
              <StrategySection vault={vault} />
            </HStack>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text fontSize="14px" color="gray.4">
                Amount
              </Text>
              <AmountSection amount={data.amount} usdAmount={usdAmount} />
            </HStack>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text fontSize="14px" color="gray.4">
                APR
              </Text>
              <APRSection
                totalApr={totalApr}
                swapFeesApr={vault?.apr}
                farmBaseApr={farmBaseApr}
                lockBonusApr={lockBonusApr}
                marketMakerAPR={marketMakerAPR}
                yieldBonusApr={bonusApr}
              />
            </HStack>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text fontSize="14px" color="gray.4">
                Pending rewards
              </Text>
              <RewardsSection
                pendingRewards={pendingRewards}
                nitroPoolPositionPendingRewards={
                  nitroPoolPositionPendingRewards
                }
              />
            </HStack>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Text fontSize="14px" color="gray.4">
                User position allocation
              </Text>
              <UserAllocationSection
                poolAddress={poolAddress}
                tokenId={tokenId}
              />
            </HStack>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <PropertiesSection
                lockDetails={lockDetails}
                poolAddress={poolAddress}
                tokenId={tokenId}
                isStakedToNitro={isStakedToNitro}
              />
            </HStack>
            <Grid
              gridTemplateColumns="repeat(auto-fill, minmax(90px, 1fr))"
              gridGap="12px"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <ActionsSection
                boosterView={boosterView}
                vault={vault}
                tokenDetails={tokenDetails}
                tokenId={tokenId}
                amount={data.amount}
                poolAddress={poolAddress}
                totalApr={totalApr}
                lockBonusApr={lockBonusApr}
                farmBaseApr={farmBaseApr}
                pendingRewards={pendingRewards}
                isStakedToNitro={!!isStakedToNitro}
                nitroPoolPositionPendingRewards={
                  nitroPoolPositionPendingRewards
                }
                nitroPoolId={nitroPoolId}
              />
            </Grid>
          </VStack>
        ) : (
          <>
            <Flex width="5%">
              <LogoSection vault={vault} tokenDetails={tokenDetails} />
            </Flex>
            <Flex width="10%">
              <VStack alignItems="flex-start" gap={0}>
                <SymbolsSection vault={vault} tokenDetails={tokenDetails} />
                <Text fontSize="14px" lineHeight="26px" color="gray.4">
                  ID: {tokenId}
                </Text>
              </VStack>
            </Flex>
            <Flex width="5%">
              <StrategySection vault={vault} />
            </Flex>
            <Flex width="5%" justifyContent="flex-end">
              <AmountSection amount={data.amount} usdAmount={usdAmount} />
            </Flex>
            <Flex width="5%">
              <APRSection
                totalApr={totalApr}
                farmBaseApr={farmBaseApr}
                swapFeesApr={vault?.apr}
                lockBonusApr={lockBonusApr}
                marketMakerAPR={marketMakerAPR}
                yieldBonusApr={bonusApr}
              />
            </Flex>
            <Flex width="10%">
              <PropertiesSection
                lockDetails={lockDetails}
                poolAddress={poolAddress}
                tokenId={tokenId}
                isStakedToNitro={isStakedToNitro}
                isNitroAvailable={!!nitroPool}
              />
            </Flex>
            <Flex width="5%">
              <RewardsSection
                pendingRewards={pendingRewards}
                nitroPoolPositionPendingRewards={
                  nitroPoolPositionPendingRewards
                }
              />
            </Flex>
            {boosterView && (
              <Flex width={boosterView ? '15%' : '5%'}>
                <UserAllocationSection
                  poolAddress={poolAddress}
                  tokenId={tokenId}
                />
              </Flex>
            )}
            <Flex width={boosterView ? '15%' : '30%'} justifyContent="flex-end">
              <HStack className={s.actions}>
                <ActionsSection
                  boosterView={boosterView}
                  vault={vault}
                  tokenDetails={tokenDetails}
                  tokenId={tokenId}
                  amount={data.amount}
                  poolAddress={poolAddress}
                  totalApr={totalApr}
                  lockBonusApr={lockBonusApr}
                  farmBaseApr={farmBaseApr}
                  pendingRewards={pendingRewards}
                  isStakedToNitro={!!isStakedToNitro}
                  nitroPoolPositionPendingRewards={
                    nitroPoolPositionPendingRewards
                  }
                  nitroPoolId={nitroPoolId}
                />
              </HStack>
            </Flex>
          </>
        )}
        <SpNftRowDetailsModal
          isOpen={open}
          onClose={toggleOpen}
          tokenDetails={vault ?? tokenDetails}
          tokenId={tokenId}
          deposit={data.amount}
          poolAddress={poolAddress}
          onResult={res => setFeedback(res)}
          pendingRewards={pendingRewards}
          lockDetails={lockDetails}
          isNitro={false}
          isStakedToNitro={!!isStakedToNitro}
          nitroPoolId={nitroPoolId}
        />
        <TransactionResultModal
          isOpen={!!feedback}
          status={feedback?.status}
          title={feedback?.title}
          message={feedback?.message}
          txHash={feedback?.txHash}
          onClose={() => {
            setFeedback(null);
            toggleOpen(false);
          }}
        />
      </>
    );
  }

  // filters
  if (
    searchTerm &&
    !vault?.token0.symbol.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !vault?.token1.symbol.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !`${vault?.token0.symbol}-${vault?.token1.symbol}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  ) {
    return null;
  }

  if (provider && vault?.provider?.toLowerCase() !== provider) {
    return null;
  }

  return (
    <Box
      className={clsx(s.row, s.rowMobile, s.clickable)}
      onClick={() => {
        if (boosterView && vault?.lpToken.id) {
          return router.push(APP_ROUTES.vaultDetails(vault.lpToken.id));
        }

        toggleOpen(true);
      }}
    >
      {renderRow()}
    </Box>
  );
};
