import { useAddress, useContract } from '@thirdweb-dev/react';
import { useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { numberToBigNumberFixed, parseUnits } from '@app/helpers/format';
import { PairToken } from '@app/types/pool';
import nftPoolAbi from 'abis/nftPool.json';
import { Vault } from '@app/hooks/plugins/useVaults';

export function useWithdrawFromPosition(
  tokenDetails: PairToken | Vault,
  poolAddress: string,
  tokenId: string
) {
  const address = useAddress();
  const [state, setState] = useState<{
    amount: string;
  }>({
    amount: ''
  });

  const handleAmountChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      amount: val
    }));
  }, []);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      amount: ''
    }));
  }, []);

  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address) {
      return;
    }

    return poolContract?.call('withdrawFromPosition', [
      tokenId,
      parseUnits(
        state.amount,
        'lpToken' in tokenDetails
          ? +tokenDetails.lpToken.decimals
          : +tokenDetails.decimals
      )
    ]);
  }, [poolContract, address, state.amount, tokenId]);

  return {
    state,
    handleAmountChange,
    resetStateValues,
    submit,
    submitting
  };
}
