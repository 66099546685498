import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants/queryKeys';

import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { useConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export type Campaign = {
  chainId: number;
  index: number;
  campaignId: string;
  creator: string;
  campaignType: number;
  campaignSubType: number;
  rewardToken: string;
  amount: string;
  amountDecimal: string;
  startTimestamp: number;
  endTimestamp: number;
  mainParameter: string;
  campaignParameters: {
    amm: number;
    token0: string;
    token1: string;
    ammAlgo: number;
    poolFee: string;
    duration: number;
    blacklist: [];
    whitelist: string[];
    forwarders: [];
    weightFees: number;
    poolAddress: string;
    symbolToken0: string;
    symbolToken1: string;
    weightToken0: number;
    weightToken1: number;
    boostedReward: number;
    boostedAddress: string;
    decimalsToken0: number;
    decimalsToken1: number;
    symbolRewardToken: string;
    decimalsRewardToken: number;
    isOutOfRangeIncentivized: boolean;
  };
  amm: number;
  ammAlgo: number;
  ammAlgoName: string;
  ammName: string;
  apr: number;
  aprs: {};
  blacklistedBalance0: number;
  blacklistedBalance1: number;
  blacklistedLiquidity: number;
  forwarders: {
    almAddress: string;
    almAPR: number;
  }[];
  isBoosted: boolean;
  isLive: boolean;
  isMock: boolean;
  poolBalanceToken0: number;
  poolBalanceToken1: number;
  poolTotalLiquidity: number;
  symbolToken0: string;
  symbolToken1: string;
  tick: number;
  tvl: number;
  priceRewardToken: string;
};

export type MerklCampaings = Record<
  string,
  Record<string, Record<string, Campaign>>
>;

const fetcher = async (
  apiUrl: string | undefined,
  chainId: number | undefined,
  params: { showExpired: boolean } = { showExpired: false }
) => {
  if (!chainId || !apiUrl) {
    return null;
  }

  const queryParams = {
    chainIds: chainId
  };

  const query = qs.stringify(queryParams, {
    encodeValuesOnly: true
  });

  const { data } = await axios.get<void, AxiosResponse<MerklCampaings>>(
    `${apiUrl}/campaigns?${query}`
  );

  const chainCampaigns = data[chainId];

  const now = Date.now();

  return Object.values(chainCampaigns)
    .map(r => {
      return Object.values(r);
    })
    .flat()
    .filter(
      c =>
        c &&
        !!c.campaignParameters.poolAddress &&
        c.ammName === 'Kim' &&
        c.campaignParameters.symbolRewardToken !== 'aglaMerkl' &&
        c.startTimestamp * 1000 <= now &&
        (params.showExpired ? true : c.endTimestamp * 1000 >= now)
    );
};

export const useMerklCampaigns = (params?: { showExpired: boolean }) => {
  const config = useConfig();
  const apiUrl = config?.URLS.merklApiUrl;
  const chainId = useSupportedChain()?.chainId;

  return useQuery<Campaign[] | undefined | null>(
    [QueryKeys.MERKL_CAMPAIGNS, apiUrl, params],
    async () => {
      return await fetcher(apiUrl, chainId, params);
    },
    {
      staleTime: 60000,
      enabled: !!apiUrl && !!chainId
    }
  );
};
