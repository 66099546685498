import React, { FC } from 'react';
import { Center, HStack, Spinner, Text } from '@chakra-ui/react';

interface Props {
  label: string;
  value: string | number | undefined;
  isLoading: boolean;
  labelColor?: string;
}

export const LabeledValue: FC<Props> = ({
  label,
  value,
  isLoading,
  labelColor = 'white'
}) => {
  return (
    <HStack>
      <Text
        fontSize="14px"
        fontWeight={500}
        lineHeight="26px"
        color={labelColor}
      >
        {label}
      </Text>
      {isLoading ? (
        <Center width="12px" height="12px">
          <Spinner width="9px" height="9px" />
        </Center>
      ) : (
        <Text
          fontSize="14px"
          fontWeight={500}
          lineHeight="26px"
          color={'#ffffff'}
        >
          {value}
        </Text>
      )}
    </HStack>
  );
};

export default LabeledValue;
