import React, { FC } from 'react';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { useHarvestPosition } from '@app/screens/positions/components/SpNftRowDetailsModal/hooks';
import { PairToken } from '@app/types/pool';
import { Vault } from '@app/hooks/plugins/useVaults';
import { TbCoins } from 'react-icons/tb';
import { ImSpinner9 } from 'react-icons/im';
import {
  Button,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { Tooltip } from '@app/components/Tooltip';

import s from './HarvestKpNftRewards.module.scss';
import { useToggle } from 'react-use';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { toOptionalFixed } from '@app/helpers/format';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  tokenDetails: PairToken | Vault;
  tokenId: string;
  poolAddress: string;
  pendingRewards: number | null;
  nitroPoolPositionPendingRewards: number | null;
  isStakedToNitro: boolean;
  nitroPoolId?: string;
}

export const HarvestKpNftRewards: FC<Props> = ({
  tokenId,
  tokenDetails,
  pendingRewards,
  poolAddress,
  nitroPoolPositionPendingRewards,
  isStakedToNitro,
  nitroPoolId
}) => {
  const [open, toggleOpen] = useToggle(false);
  const isMismatchedNetwork = useNetworkMismatch();

  const { submit, submitting, submittingNitro, submitNitro } =
    useHarvestPosition(
      tokenDetails,
      poolAddress,
      tokenId,
      isStakedToNitro,
      nitroPoolId
    );

  if (isMismatchedNetwork) {
    return null;
  }

  return (
    <>
      <Tooltip tooltip="Harvest rewards">
        <Button
          disabled={!pendingRewards || submitting}
          variant="secondary"
          onClick={async e => {
            e.stopPropagation();

            toggleOpen();
          }}
          _hover={{
            color: 'orange'
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '14px',
            width: '100%'
          }}
        >
          {submitting ? <ImSpinner9 className={s.rotate} /> : <TbCoins />}
        </Button>
      </Tooltip>
      <Modal
        isOpen={open}
        onClose={() => {
          toggleOpen(false);
        }}
        isCentered
        motionPreset="slideInBottom"
        size="xs"
      >
        <ModalOverlay />
        <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '24px' }}>
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Harvest your position"
            subtitle="Collect your farming and Nitro rewards"
          />
          <Button
            variant="transparent"
            disabled={!pendingRewards || submitting}
            onClick={async e => {
              e.stopPropagation();

              try {
                const res = await submit();

                if (res['reason'] !== undefined) {
                  showErrorToast(res.reason);
                } else if (res instanceof Error) {
                  showErrorToast(res.message);
                } else {
                  const txHash = res?.receipt?.transactionHash;

                  showSuccessToast(
                    `Transaction Confirmed`,
                    <ExternalLink txHash={txHash} />
                  );
                }
              } catch (e) {
                if (e instanceof TransactionError) {
                  showErrorToast(e.reason);
                } else {
                  showErrorToast(`Transaction error`);
                }
              }
            }}
            sx={{
              border: '1px solid',
              borderColor: 'neutral.500',
              padding: '24px',
              borderRadius: '12px',
              backgroundColor: 'neutral.800',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              transition: 'borderColor 0.2s ease',
              '&:hover': {
                borderColor: 'orange'
              }
            }}
          >
            <VStack alignItems="flex-start">
              <Text sx={{ color: 'neutral.200' }}>Harvest farming rewards</Text>
              {/*<Text sx={{ color: 'neutral.400' }} fontSize="12px">*/}
              {/*  Harvest Nitro rewards*/}
              {/*</Text>*/}
            </VStack>
            {submitting ? (
              <Spinner color="orange" width={4} height={4} />
            ) : (
              <Text sx={{ color: 'white' }}>
                {pendingRewards ? `$${toOptionalFixed(pendingRewards, 2)}` : ''}
              </Text>
            )}
          </Button>
          {!!nitroPoolPositionPendingRewards && (
            <Button
              variant="transparent"
              disabled={!nitroPoolPositionPendingRewards || submittingNitro}
              onClick={async e => {
                e.stopPropagation();

                try {
                  const res = await submitNitro();

                  if (res['reason'] !== undefined) {
                    showErrorToast(res.reason);
                  } else if (res instanceof Error) {
                    showErrorToast(res.message);
                  } else {
                    const txHash = res?.receipt?.transactionHash;

                    showSuccessToast(
                      `Transaction Confirmed`,
                      <ExternalLink txHash={txHash} />
                    );
                  }
                } catch (e) {
                  if (e instanceof TransactionError) {
                    showErrorToast(e.reason);
                  } else {
                    showErrorToast(`Transaction error`);
                  }
                }
              }}
              sx={{
                border: '1px solid',
                borderColor: 'neutral.500',
                padding: '24px',
                borderRadius: '12px',
                backgroundColor: 'neutral.800',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                transition: 'all 0.3s ease',
                '&:hover': {
                  borderColor: 'orange'
                },
                mt: '12px'
              }}
            >
              <VStack alignItems="flex-start">
                <Text sx={{ color: 'neutral.200' }}>Harvest Nitro rewards</Text>
                {/*<Text sx={{ color: 'neutral.400' }} fontSize="12px">*/}
                {/*  Harvest Nitro rewards*/}
                {/*</Text>*/}
              </VStack>

              {submittingNitro ? (
                <Spinner color="orange" width={4} height={4} />
              ) : (
                <Text sx={{ color: 'white' }}>
                  {nitroPoolPositionPendingRewards
                    ? `$${toOptionalFixed(nitroPoolPositionPendingRewards, 2)}`
                    : ''}
                </Text>
              )}
            </Button>
          )}
          <HStack mt={6}>
            <Button
              variant="secondary"
              onClick={e => {
                e.stopPropagation();
                toggleOpen(false);
              }}
              w="100%"
            >
              Cancel
            </Button>
          </HStack>
        </ModalContent>
      </Modal>
    </>
  );
};
