import { FaRegCopy, FaCheck } from 'react-icons/fa6';

import { LinkProps, IconButton } from '@chakra-ui/react';
import { useState } from 'react';

interface Props extends LinkProps {
  text: string;
}

export const CopyButton = ({ text }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  return (
    <IconButton onClick={handleCopy} aria-label="copy">
      {copied ? (
        <FaCheck size="16px" color="var(--chakra-colors-success)" />
      ) : (
        <FaRegCopy size="16px" color="var(--chakra-colors-neutral-400)" />
      )}
    </IconButton>
  );
};
