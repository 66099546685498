import { useBoosterAllocation } from '@app/hooks/booster/useBoosterAllocation';

export function useYieldBoosterApr({
  poolAddress,
  tokenId,
  farmBaseApr
}: {
  poolAddress: string;
  tokenId: string;
  farmBaseApr: number;
}) {
  const { currentMultiplier } = useBoosterAllocation(poolAddress, tokenId, 0);

  return farmBaseApr && currentMultiplier ? farmBaseApr * currentMultiplier : 0;
}
