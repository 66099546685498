import axios, { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export type VaultToken = {
  id: string;
  name: string;
  symbol: string;
  decimals: string;
};

export type Vault = {
  id?: string;
  apr: number;
  image: string;
  link: string;
  lpToken: {
    id: string;
    name: string;
    symbol: string;
    decimals: string;
    lpTokenUSD: number;
  };
  name: string;
  pool: string;
  strategyDescription: string;
  token0: VaultToken;
  token0Balance: string;
  token1: VaultToken;
  token1Balance: string;
  tvl: number;
  provider?: string;
};

type Response = {
  vaults: Vault[];
};

async function fetcher(config: ApplicationConfig | undefined) {
  const { data } = await axios.get<void, AxiosResponse<Response>>(
    `${config?.URLS.steerApiUrl}/kim/vaults`
  );

  let result: Vault[] = [];

  config?.CONTENT.poolsOrder.forEach((poolId: string) => {
    const found = data.vaults?.filter(
      p => p.pool.toLowerCase() === poolId.toLowerCase()
    );

    if (found) {
      result = [...result, ...found];
    }
  });

  const rest = data.vaults.filter(
    v => !result.map(e => e.pool).includes(v.pool.toLowerCase())
  );

  return [...result, ...rest].map(item => ({ ...item, id: item.lpToken.id }));
}

export function useVaults() {
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;

  return useQuery(
    [QueryKeys.STEER_VAULTS, chainId],
    () => {
      return fetcher(config);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      enabled: !!config?.URLS.steerApiUrl
    }
  );
}
