import { HStack, VStack, Text } from '@chakra-ui/react';
import { TokensLogoSection } from '@app/components/TokensLogoSection';
import React, { FC } from 'react';
import { AmountSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/AmountSection';
import styles from './NftFormAssetSection.module.scss';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import { Position } from '@app/screens/nft-marketplace/SpNftOffersModal/SpNftModalRow';

type Props = {
  position: Position;
};

export const NftFormAssetSection: FC<Props> = ({ position }) => {
  let {
    data,
    vault,
    lockDetails,
    usdAmount,
    poolAddress,
    tokenId,
    isStakedToNitro,
    tokenDetails
  } = position;

  if (!data || !tokenDetails) {
    return null;
  }

  if (!vault) {
    return null;
  }

  return (
    <VStack alignItems="flex-start" width="100%">
      <Text
        fontWeight="400"
        fontSize="16px"
        color="neutral.300"
        lineHeight="16px"
      >
        Asset
      </Text>
      <HStack
        w="100%"
        bg="neutral.1000"
        padding="8px 8px 8px 16px"
        borderRadius="12px"
        overflow="hidden"
        justifyContent="flex-start"
        gap="16px"
      >
        <TokensLogoSection
          token0Symbol={vault.token0.symbol}
          token1Symbol={vault.token1.symbol}
          size="md"
        />
        <PropertiesSection
          lockDetails={lockDetails}
          poolAddress={poolAddress}
          tokenId={tokenId}
          isStakedToNitro={isStakedToNitro}
        />
        <AmountSection
          classes={{ root: styles.root, amount: styles.amount }}
          amount={data.amount}
          usdAmount={usdAmount}
        />
      </HStack>
    </VStack>
  );
};
