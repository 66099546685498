import React, { FC, PropsWithChildren } from 'react';
import { HStack, Text } from '@chakra-ui/react';

interface Props extends PropsWithChildren {
  label: string;
}

export const DetailsRow: FC<Props> = ({ label, children }) => {
  return (
    <HStack width="100%" alignItems="center" justifyContent="space-between">
      <Text fontSize="14px" color="neutral.400">
        {label}
      </Text>
      {children}
    </HStack>
  );
};
