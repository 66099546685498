import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { gql, request } from 'graphql-request';
import { NitroPool } from '@app/types/nitro';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';

function getQuery() {
  return gql`
    query {
      nitroPools {
        id
        published
        nftPool
        whitelist
        totalDepositAmount
        requiredLockDuration
        requiredDepositAmount
        requiredLockEnd
        rewardsToken1PerSecond
        rewardsToken2PerSecond
        rewardsToken1RemainingAmount
        rewardsToken2RemainingAmount
        rewardsToken1 {
          id
          symbol
          decimals
          name
          totalSupply
        }
        rewardsToken2 {
          id
          symbol
          decimals
          name
          totalSupply
        }
        description
        startTime
        endTime
        rewardsToken1Amount
        rewardsToken2Amount
        depositEndTime
        harvestStartTime
      }
    }
  `;
}

async function fetcher(config: ApplicationConfig | undefined) {
  const query = getQuery();

  const res = await request<{ nitroPools: NitroPool[] }>(
    `${config?.URLS.subgraphUrlNitroPools}`,
    query
  );

  return res.nitroPools;
}

export function useNitroPoolsList() {
  const config = useConfig();

  return useQuery(
    [QueryKeys.NITRO_POOLS_LIST, !!config],
    () => fetcher(config),
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      enabled: !!config?.URLS.subgraphUrlNitroPools
    }
  );
}
