import { useKpNftPositionDetails } from '@app/screens/positions/components/SpNftPositionListRowItem/hooks';
import { useFarmBaseApr } from '@app/hooks/plugins/useFarmBaseApr';
import { useMarketMakerApr } from '@app/hooks/merkl/useMarketMakerApr';
import { useNitroPoolPositionPendingRewards } from '@app/hooks/nitro/useNitroPoolPositionPendingRewards';
import { useKpNftLockDetails } from '@app/hooks/plugins/useKpNftLockDetails';
import { PairToken } from '@app/types/pool';
import { useYieldBoosterApr } from '@app/hooks/booster/useYieldBoosterApr';
import { useNitroPool } from '@app/hooks/nitro/useNitroPool';

export const usePositionKpNftDetailedData = (
  poolAddress: string,
  tokenId: string,
  tokenDetails: PairToken | undefined,
  isStakedToNitro?: boolean,
  nitroPoolId?: string
) => {
  const { vault, usdAmount, pendingRewards, data, isLoading } =
    useKpNftPositionDetails(tokenId, poolAddress, tokenDetails?.id ?? '');
  const {
    totalApr: rawTotalApr,
    lockBonusApr,
    farmBaseApr
  } = useFarmBaseApr(
    poolAddress,
    vault?.tvl,
    vault?.apr,
    (data?.lockDuration ?? 0) / 1000 / 3600 / 24
  );

  const marketMakerAPR = useMarketMakerApr(
    vault?.token0.id,
    vault?.token1.id,
    vault?.lpToken.id
  );

  const { totalRewardsUsd: nitroPoolPositionPendingRewards } =
    useNitroPoolPositionPendingRewards(
      poolAddress,
      !!isStakedToNitro,
      nitroPoolId
    );

  const lockDetails = useKpNftLockDetails(data);

  const bonusApr = useYieldBoosterApr({ poolAddress, tokenId, farmBaseApr });
  const totalApr = (rawTotalApr ?? 0) + bonusApr;

  const { data: nitroPool } = useNitroPool(poolAddress, nitroPoolId);

  return {
    data,
    vault,
    usdAmount,
    pendingRewards,
    isLoading,
    lockDetails,
    rawTotalApr,
    totalApr,
    bonusApr,
    lockBonusApr,
    farmBaseApr,
    nitroPoolPositionPendingRewards,
    marketMakerAPR,
    nitroPool
  };
};
