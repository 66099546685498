import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  HStack,
  Button,
  VStack
} from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { LabeledValue } from '@app/components/LabeledValue';
import { DebouncedInput } from '@app/components/DebouncedInput';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { SubmitButton } from '@app/components/SubmitButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { useWithdrawFromPosition } from '@app/screens/positions/components/SpNftControls/WithdrawFromPosition/hooks';
import { Vault } from '@app/hooks/plugins/useVaults';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { InfoRow } from '@app/screens/positions/components/SpNftControls/InfoRow/InfoRow';
import { formatNumber } from '@app/helpers/format';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
}

export const WithdrawFromPositionModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  onResult
}) => {
  const isMismatchedNetwork = useNetworkMismatch();

  const { state, handleAmountChange, resetStateValues, submit, submitting } =
    useWithdrawFromPosition(tokenDetails, poolAddress, tokenId);

  const isBalanceExceeded = deposit !== undefined && +deposit < +state.amount;
  const isLpToken = 'lpToken' in tokenDetails;

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        disabled={isBalanceExceeded || !+state.amount}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              resetStateValues();

              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction confirmed`,
                <ExternalLink txHash={txHash} />
              );

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Withdraw"
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '0px' }}>
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Withdraw from your position"
            subtitle="Recover underlying tokens from kpNFT yield"
          />
          <HStack justifyContent="space-between" mt={6}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Amount
            </Text>
            <LabeledValue
              label={`Deposit:`}
              isLoading={false}
              value={`${formatNumber(+deposit)} ${
                isLpToken ? tokenDetails.lpToken.symbol : tokenDetails.symbol
              }`}
            />
          </HStack>
          <HStack
            justifyContent="space-between"
            mt="18px"
            sx={{
              paddingRight: '8px',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: 'gray.5',
              background: 'black'
            }}
          >
            <DebouncedInput
              amount={state.amount}
              onChange={handleAmountChange}
              textAlign="left"
            />
            <Button
              variant="tertiary"
              onClick={() => {
                if (deposit === undefined) {
                  return;
                }

                handleAmountChange(deposit);
              }}
              sx={{
                color: 'orange',
                bg: 'gray.2',
                width: 'fit-content',
                fontSize: '14px',
                padding: '8px 14px'
              }}
            >
              MAX
            </Button>
          </HStack>
          <VStack mt={6} width="100%" alignItems="flex-start" gap={2}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Estimates
            </Text>
            <InfoRow
              label="Withdraw amount"
              value0={`${formatNumber(+state.amount)} ${
                isLpToken ? tokenDetails.lpToken.symbol : tokenDetails.symbol
              }`}
            />
            <InfoRow
              label="Remaining amount"
              value0={`${formatNumber(+deposit - +state.amount)} ${
                isLpToken ? tokenDetails.lpToken.symbol : tokenDetails.symbol
              }`}
            />
          </VStack>

          <HStack mt={6}>
            <Button
              variant="secondary"
              onClick={() => {
                resetStateValues();
                onClose();
              }}
              w="100%"
            >
              Cancel
            </Button>
            {renderSubmit()}
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};
