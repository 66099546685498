import { useAddress, useContract } from '@thirdweb-dev/react';
import { useAsyncFn } from 'react-use';
import { PairToken } from '@app/types/pool';
import nftPoolAbi from 'abis/nftPool.json';
import { Vault } from '@app/hooks/plugins/useVaults';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useNitroPool } from '@app/hooks/nitro/useNitroPool';
import NitroPool from '../../../../../abis/NitroPool.json';

export function useHarvestPosition(
  tokenDetails: PairToken | Vault,
  poolAddress: string,
  tokenId: string,
  isStakedToNitro: boolean,
  nitroPoolId?: string
) {
  const address = useAddress();
  const queryClient = useQueryClient();

  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const { data } = useNitroPool(poolAddress, nitroPoolId);

  const { contract: nitroPoolContract } = useContract(data?.id, NitroPool.abi);

  const [{ loading: submittingNitro }, submitNitro] = useAsyncFn(async () => {
    if (!nitroPoolContract || !address) {
      return;
    }

    try {
      const res = await nitroPoolContract?.call('harvest');

      await queryClient.invalidateQueries([QueryKeys.KP_NFT_PENDING_REWARDS]);

      return res;
    } catch (e) {
      console.error(e);
    }
  }, [nitroPoolContract, address, queryClient]);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address) {
      return;
    }

    try {
      const res = isStakedToNitro
        ? await poolContract?.call('harvestPositionTo', [tokenId, address])
        : await poolContract?.call('harvestPosition', [tokenId]);

      await queryClient.invalidateQueries([QueryKeys.KP_NFT_PENDING_REWARDS]);

      return res;
    } catch (e) {
      console.error(e);
    }
  }, [
    poolContract,
    address,
    tokenId,
    queryClient,
    poolAddress,
    isStakedToNitro
  ]);

  return {
    submit,
    submitting,
    submitNitro,
    submittingNitro
  };
}
