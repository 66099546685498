import { useAddress, useContract } from '@thirdweb-dev/react';
import { useCallback, useEffect, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { PairToken } from '@app/types/pool';
import nftPoolAbi from 'abis/nftPool.json';
import { Vault } from '@app/hooks/plugins/useVaults';

export function useLockPosition(
  tokenDetails: PairToken | Vault,
  poolAddress: string,
  tokenId: string,
  isOpen: boolean
) {
  const address = useAddress();
  const [state, setState] = useState<{
    duration: string;
  }>({
    duration: ''
  });

  const handleDurationChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      duration: val
    }));
  }, []);

  const resetStateValues = useCallback(() => {
    setState({
      duration: ''
    });
  }, []);

  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const [, getLockDuration] = useAsyncFn(async () => {
    if (!poolContract || !tokenId) {
      return;
    }

    const res = await poolContract.call('getStakingPosition', [tokenId]);

    const dur = Number(res.lockDuration.toString());

    if (dur !== 0) {
      const lockDurationInDays = dur / (3600 * 24);

      setState(prev => ({
        ...prev,
        duration: lockDurationInDays.toString()
      }));
    }
  }, [poolContract, tokenId]);

  useEffect(() => {
    if (isOpen) {
      getLockDuration();
    }
  }, [isOpen, getLockDuration]);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address) {
      return;
    }

    return poolContract?.call('lockPosition', [
      tokenId,
      +state.duration * 3600 * 24
    ]);
  }, [poolContract, address, state.duration, tokenId]);

  return {
    state,
    handleDurationChange,
    resetStateValues,
    submit,
    submitting,
    getLockDuration
  };
}
