import { useState } from 'react';

export const useReferenceElement = () => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );

  return {
    referenceElement,
    setReferenceElement
  };
};
