import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Box,
  HStack,
  Button
} from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { DebouncedInput } from '@app/components/DebouncedInput';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { SubmitButton } from '@app/components/SubmitButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { useTransferPosition } from '@app/screens/positions/components/SpNftControls/TransferPosition/hooks';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { Vault } from '@app/hooks/plugins/useVaults';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  poolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
}

export const TransferPositionModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  poolAddress,
  onResult
}) => {
  const isMismatchedNetwork = useNetworkMismatch();

  const { state, handleAddressChange, resetStateValues, submit, submitting } =
    useTransferPosition(poolAddress, tokenId);

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        disabled={!state.address}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              resetStateValues();

              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction confirmed`,
                <ExternalLink txHash={txHash} />
              );

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Transfer"
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '0px' }}>
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Transfer your position"
            subtitle="Send your kpNFT to a new address"
          />
          <HStack
            justifyContent="space-between"
            mt="18px"
            sx={{
              paddingRight: '14px',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: 'gray.5',
              background: 'black'
            }}
          >
            <DebouncedInput
              amount={state.address}
              onChange={handleAddressChange}
              textAlign="left"
              type="text"
              placeholder="0x..."
            />
          </HStack>
          <HStack mt={6}>
            <Button
              variant="secondary"
              onClick={() => {
                resetStateValues();
                onClose();
              }}
              w="100%"
            >
              Cancel
            </Button>
            {renderSubmit()}
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};
