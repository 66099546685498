import React, { FC } from 'react';
import { DetailsRow } from '@app/screens/positions/components/SpNftControls/DetailsRow';
import { TokensPair } from '@app/components/TokensPair';
import { HStack, Text, VStack } from '@chakra-ui/react';
import Countdown from 'react-countdown';

interface Props {
  token0Symbol: string | undefined;
  token1Symbol: string | undefined;
  tvl: string;
  apr: string;
  endTime: Date;
  minimumAmount: string;
  minimumLock: string;
  lockedUntil: string;
}

export const StakeIntoNitroPoolDetails: FC<Props> = ({
  token0Symbol,
  token1Symbol,
  tvl,
  apr,
  endTime,
  minimumAmount,
  minimumLock,
  lockedUntil
}) => {
  return (
    <VStack
      justifyContent="space-between"
      mt="18px"
      padding="12px"
      gap="12px"
      sx={{
        borderRadius: '10px',
        border: '1px solid',
        borderColor: 'gray.5',
        background: 'black'
      }}
    >
      <DetailsRow label="Rewards">
        <TokensPair token0Symbol={token0Symbol} token1Symbol={token1Symbol} />
      </DetailsRow>
      <DetailsRow label="TVL">
        <Text fontSize="14px" color="white">
          {tvl}
        </Text>
      </DetailsRow>
      <DetailsRow label="APR">
        <Text fontSize="14px" color="white">
          {apr}
        </Text>
      </DetailsRow>
      <DetailsRow label=" Ends in">
        <Countdown
          date={endTime}
          renderer={({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              return (
                <Text fontSize="16px" fontWeight={400} color="white">
                  Time is up!
                </Text>
              );
            } else {
              return (
                <HStack>
                  <Text fontSize="16px" fontWeight={400} color="white">
                    {days ? `${days}d ` : ''}
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="white">
                    {hours ? `${hours}h ` : ''}
                  </Text>
                  <Text fontSize="16px" fontWeight={400} color="white">
                    {minutes ? `${minutes}m ` : ''}
                  </Text>
                </HStack>
              );
            }
          }}
        />
      </DetailsRow>

      <DetailsRow label="Minimum amount">
        <Text fontSize="14px" color="white">
          {minimumAmount}
        </Text>
      </DetailsRow>
      <DetailsRow label="Minimum lock">
        <Text fontSize="14px" color="white">
          {minimumLock}
        </Text>
      </DetailsRow>
      <DetailsRow label="Locked until">
        <Text fontSize="14px" color="white">
          {lockedUntil}
        </Text>
      </DetailsRow>
    </VStack>
  );
};
