import { create } from 'zustand';

interface PositionsFiltersStore {
  readonly searchTerm: string;
  readonly provider: string;

  actions: {
    setSearchTerm: (val: string) => void;
    setProvider: (val: string) => void;
  };
}

export const usePositionsFiltersState = create<PositionsFiltersStore>(
  setState => ({
    searchTerm: '',
    provider: '',
    actions: {
      setSearchTerm: (val: string) => setState({ searchTerm: val }),
      setProvider: (val: string) => setState({ provider: val })
    }
  })
);
