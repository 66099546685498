import { Flex, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { SpNftPositionListRowItemProp } from '@app/screens/positions/components/SpNftPositionListRowItem';
import React, { FC } from 'react';
import { usePositionKpNftDetailedData } from '@app/hooks/positions/usePositionKpNftDetailedData';
import { StrategySection } from '@app/components/StrategySection';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import styles from '@app/screens/nft-marketplace/SpNftStatSection/SpNftStatSection.module.scss';
import { AmountSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/AmountSection';
import { TokensLogoSection } from '@app/components/TokensLogoSection';
import { TokenSymbolSection } from '@app/components/TokenSymbolSection';
import { ListNftButton } from '@app/screens/nft-marketplace/ListNftButton';
import { CancelNftListButton } from '@app/screens/nft-marketplace/CancelNftListButton';
import { useToggle } from 'react-use';
import { SellNftModal } from '@app/screens/nft-marketplace/SellNftModal';

interface SpNftModalRowItemProp extends SpNftPositionListRowItemProp {}

export type KpNftDetails = ReturnType<typeof usePositionKpNftDetailedData>;

export type Position = Pick<
  SpNftModalRowItemProp,
  'poolAddress' | 'tokenId' | 'tokenDetails' | 'isStakedToNitro'
> &
  KpNftDetails;

export const SpNftModalRowItem: FC<SpNftModalRowItemProp> = ({
  poolAddress,
  tokenId,
  tokenDetails,
  boosterView,
  isStakedToNitro,
  nitroPoolId
}) => {
  const [isListNftModalOpen, toggleIsListNftModalOpen] = useToggle(false);
  const handleListNftButtonClick = () => {
    toggleIsListNftModalOpen();
  };

  let kpNftDetails: KpNftDetails = usePositionKpNftDetailedData(
    poolAddress,
    tokenId,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId
  );

  let position = {
    tokenId,
    poolAddress,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId,
    ...kpNftDetails
  };

  let { data, vault, lockDetails, usdAmount } = position;

  if (!data || !tokenDetails) {
    return null;
  }

  if (!vault) {
    return null;
  }

  return (
    <>
      <Grid
        width="100%"
        borderRadius="16px"
        _hover={{
          border: '1px solid',
          borderColor: 'whiteOpacity.6',
          backgroundColor: 'gray.6',
          cursor: 'pointer'
        }}
        justifyItems="center"
        padding="21px"
        templateColumns="repeat(4, 1fr) 2fr"
        alignItems="center"
        gap={1}
      >
        <GridItem>
          <HStack>
            <TokensLogoSection
              token0Symbol={vault.token0.symbol}
              token1Symbol={vault.token1.symbol}
              size="md"
            />
            <VStack gap={0} alignItems="flex-start">
              <TokenSymbolSection
                className={styles.tokenSymbolSection}
                token0Symbol={vault.token0.symbol}
                token1Symbol={vault.token1.symbol}
                separator="/"
              />
              <Text
                fontSize="18px"
                fontWeight={400}
                lineHeight="28px"
                color="gray.4"
              >
                ID: {tokenId}
              </Text>
            </VStack>
          </HStack>
        </GridItem>
        <GridItem>
          <StrategySection vault={vault} />
        </GridItem>
        <GridItem>
          <PropertiesSection
            lockDetails={lockDetails}
            poolAddress={poolAddress}
            tokenId={tokenId}
            isStakedToNitro={isStakedToNitro}
          />
        </GridItem>
        <GridItem>
          <AmountSection amount={data.amount} usdAmount={usdAmount} />
        </GridItem>
        <GridItem>
          <HStack>
            <ListNftButton onClick={handleListNftButtonClick} />
            <CancelNftListButton />
          </HStack>
        </GridItem>
      </Grid>
      <SellNftModal
        position={position}
        isOpen={isListNftModalOpen}
        onClose={() => toggleIsListNftModalOpen(false)}
      />
    </>
  );
};
