import { useReferenceElement } from '@app/hooks/other/useReferenceElement';
import { useToggle } from 'react-use';

export const usePopperHook = () => {
  const { referenceElement, setReferenceElement } = useReferenceElement();
  const [showTooltip, setShowTooltip] = useToggle(false);

  return {
    referenceElement,
    setReferenceElement,
    showTooltip,
    setShowTooltip
  };
};
