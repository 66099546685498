import React, { FC, useEffect, useState } from 'react';
import { HStack, Button, VStack, Text } from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { Icon } from '@app/components/Icon';
import { usePopperHook } from '@app/hooks/other/usePopperHook';
import { SellNftTooltip } from '@app/screens/nft-marketplace/SellNftForm/SellNftTooltip';

type Props = {
  selectedDates: Date[];
  onChange: (val: Date[]) => void;
  tooltipMessage: string;
};

export const AuctionPeriodPicker: FC<Props> = ({
  tooltipMessage,
  selectedDates,
  onChange
}) => {
  const { referenceElement, setReferenceElement, setShowTooltip, showTooltip } =
    usePopperHook();

  const today = new Date();

  return (
    <VStack width="100%" ref={setReferenceElement} alignItems="flex-start">
      <SellNftTooltip
        isOpen={showTooltip}
        referenceElement={referenceElement}
        message={tooltipMessage}
      />
      <Text
        fontWeight="400"
        fontSize="16px"
        color="neutral.300"
        lineHeight="16px"
      >
        Select Auction period (optional)
      </Text>
      <HStack
        w="100%"
        bg="neutral.1000"
        padding="8px"
        borderRadius="12px"
        overflow="hidden"
        justifyContent="space-between"
        zIndex={120}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <RangeDatepicker
          selectedDates={selectedDates}
          onDateChange={onChange}
          minDate={today}
          propsConfigs={{
            triggerBtnProps: {
              as: Button,
              padding: '8px 16x',
              fontSize: 16,
              height: '48.5px',
              width: '100%',
              textAlign: 'left',
              justifyContent: 'flex-start'
            },
            calendarPanelProps: {
              wrapperProps: {
                backgroundColor: 'neutral.800',
                border: '1px solid var(--chakra-colors-neutral-700)',
                borderRadius: '12px'
              }
            },
            dayOfMonthBtnProps: {
              defaultBtnProps: {
                _hover: {
                  background: 'orange'
                }
              },
              todayBtnProps: {
                backgroundColor: 'orange'
              },
              selectedBtnProps: {
                backgroundColor: 'orange'
              },
              isInRangeBtnProps: {
                backgroundColor: 'danger.800'
              }
            }
          }}
        />
        <Icon name="calendar" />
      </HStack>
    </VStack>
  );
};
