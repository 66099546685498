import { Token } from '@cryptoalgebra/kim-sdk';
import { DEFAULT_CHAIN_ID } from './common';
// @ts-ignore
import weth from '@app/assets/images/tokens/weth.svg?url';
// @ts-ignore
import ankrETH from '@app/assets/images/tokens/ankrETH.svg?url';
// @ts-ignore
import pxETH from '@app/assets/images/tokens/pxETH.svg?url';
// @ts-ignore
import wrsETH from '@app/assets/images/tokens/rsETH.svg?url';
// @ts-ignore
import mBTC from '@app/assets/images/tokens/mbtc.svg?url';
// @ts-ignore
import weETH_old from '@app/assets/images/tokens/weETH_old.svg?url';
// @ts-ignore
import weETH_new from '@app/assets/images/tokens/weETH_new.svg?url';
import ezETH from '@app/assets/images/tokens/ethRenzoLogo.png';
import stone from '@app/assets/images/tokens/stone.png';
import { StaticImageData } from 'next/image';
// @ts-ignore
import kim from '@app/assets/images/tokens/kim.svg?url';
// @ts-ignore
import xkim from '@app/assets/images/tokens/xkim.svg?url';
// @ts-ignore
import ionic from '@app/assets/images/tokens/ionic.svg?url';
// @ts-ignore
import mode from '@app/assets/images/tokens/mode.svg?url';
// @ts-ignore
import dusd from '@app/assets/images/tokens/dusd.svg?url';
// @ts-ignore
import veth from '@app/assets/images/tokens/veth.png';
import uBTC from '@app/assets/images/tokens/uBTC.png';
// @ts-ignore
import mochad from '@app/assets/images/tokens/mochad.webp';
// @ts-ignore
import djump from '@app/assets/images/tokens/djump.svg?url';
// @ts-ignore
import gvec from '@app/assets/images/tokens/gvec.png';
import inETH from '@app/assets/images/tokens/inETH.png';
import pMode from '@app/assets/images/tokens/pMode.jpg';
import peas from '@app/assets/images/tokens/peas.jpg';
import inankrETH from '@app/assets/images/tokens/inankrETH.png';
// @ts-ignore
import eth from '@app/assets/images/tokens/eth.svg?url';
// @ts-ignore
import usdt from '@app/assets/images/tokens/usdt.svg?url';
// @ts-ignore
import usdc from '@app/assets/images/tokens/usdc.svg?url';
// @ts-ignore
import icl from '@app/assets/images/tokens/icl.svg?url';
// @ts-ignore
import op from '@app/assets/images/tokens/op.svg?url';
// @ts-ignore
import alex from '@app/assets/images/tokens/alex.svg?url';
// @ts-ignore
import abtc from '@app/assets/images/tokens/aBTC.svg?url';
// @ts-ignore
import usda from '@app/assets/images/tokens/USDA.svg?url';
// @ts-ignore
import iusd from '@app/assets/images/tokens/iusd.svg?url';
import ionx from '@app/assets/images/tokens/ionx.jpg';

export const STABLECOINS = {
  USDC: new Token(
    DEFAULT_CHAIN_ID,
    '0xd988097fb8612cc24eeC14542bC03424c656005f',
    6,
    'USDC',
    'USDC'
  ),
  USDT: new Token(
    DEFAULT_CHAIN_ID,
    '0xf0F161fDA2712DB8b566946122a5af183995e2eD',
    6,
    'USDT',
    'USDT'
  )
};

export const TOKEN_ICONS: Record<string, string | StaticImageData> = {
  'm-btc': mBTC,
  weth: weth,
  ankreth: ankrETH,
  '(new) weeth.mode': weETH_new,
  'weeth.mode': weETH_new,
  'weeth.base': weETH_new,
  weeth: weETH_new,
  '(old) weeth': weETH_old,
  ezeth: ezETH,
  pxeth: pxETH,
  stone: stone,
  wrseth: wrsETH,
  kim,
  xkim,
  ion: ionic,
  mode,
  veth,
  mochad,
  djump,
  gvec,
  ineth: inETH,
  pmode: pMode,
  peas,
  inankreth: inankrETH,
  dusd,
  eth,
  usdt,
  usdc,
  icl,
  iusd,
  ionx,
  op,
  alex,
  abtc,
  ubtc: uBTC,
  usda
};

export const OLD_WEETH_TOKEN =
  '0x028227c4dd1e5419d11Bb6fa6e661920c519D4F5'.toLowerCase();
export const NEW_WEETH_TOKEN =
  '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A'.toLowerCase();
