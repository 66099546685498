import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from 'src/constants/queryKeys';

import { getContract, useAddress } from '@thirdweb-dev/react';
import { Chain } from '@thirdweb-dev/chains';

import nftPoolAbi from 'abis/nftPool.json';
import { BigNumber } from 'ethers';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

type QueryParams = {
  poolAddress: string;
};

const fetcher = async (
  params: QueryParams,
  network: Chain | undefined,
  walletAddress: string | undefined
) => {
  if (!network || !walletAddress) {
    return undefined;
  }

  const poolContract = await getContract({
    address: params.poolAddress,
    network,
    contractTypeOrAbi: nftPoolAbi
  });

  // @ts-ignore
  const positionsCount = await poolContract.call('balanceOf', [walletAddress]);

  const positionsIndexes = new Array(Number(positionsCount.toString()))
    .fill(0)
    .map((_, i) => i);

  const res = await Promise.all(
    positionsIndexes.map(index => {
      // @ts-ignore
      return poolContract.call('tokenOfOwnerByIndex', [walletAddress, index]);
    })
  );

  return res.map((item: BigNumber) => item.toString());
};

export const usePoolTokenIds = (
  params: QueryParams,
  options: UseQueryOptions<string[]> = {}
) => {
  const chain = useSupportedChain();
  const address = useAddress();

  return useQuery<string[]>(
    [QueryKeys.POOL_TOKEN_IDS, { params, chainId: chain?.chainId, address }],
    async () => {
      return fetcher(params, chain, address);
    },
    {
      staleTime: 60000,
      ...options
    }
  );
};
