import { useKpNftFilteredList } from '@app/screens/positions/components/SpNftsList/hooks';
import { SpNftPositionsListRow } from '@app/screens/positions/components/SpNftPositionListRow';
import React from 'react';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { NoKpNftView } from '@app/screens/positions/components/NoKpNftView';

export const MySpNftTab = () => {
  const { filteredData, isLoading } = useKpNftFilteredList();

  const renderList = () => {
    if (isLoading) {
      return <ListLoader />;
    }

    if (!filteredData || filteredData.length === 0) {
      return <NoKpNftView />;
    }

    return (
      <>
        {filteredData?.map(item => {
          return (
            <SpNftPositionsListRow
              key={item.poolAddress}
              poolAddress={item.poolAddress}
              tokenAddress={item.tokenAddress}
              currentPage="my_kp_nft_tab"
            />
          );
        })}
      </>
    );
  };

  return <>{renderList()}</>;
};
