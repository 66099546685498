import { useGetMarketMakerCampaign } from '@app/hooks/merkl/useGetMarketMakerCampaign';
import { Campaign } from '@app/hooks/merkl/useMerklCampaigns';
import { Address } from '@thirdweb-dev/sdk';

export function useMarketMakerApr(
  token0: Address | undefined,
  token1: Address | undefined,
  poolAddress: Address | undefined
) {
  const { marketMakerCampaign } = useGetMarketMakerCampaign(
    token0,
    token1,
    poolAddress
  );

  return (
    marketMakerCampaign?.reduce((acc: number, curr: Campaign) => {
      let _apr;

      const forwarder = curr.forwarders.find(
        item => item.almAddress?.toLowerCase() === poolAddress?.toLowerCase()
      );

      return acc + (forwarder?.almAPR ?? curr.apr);
    }, 0) ?? 0
  );
}
