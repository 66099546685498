import { useContract } from '@thirdweb-dev/react-core';
import nftPoolAbi from 'abis/nftPool.json';
import vaultAbi from 'abis/steer/VaultErc20.json';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { getContract, useChain } from '@thirdweb-dev/react';
import { Chain } from '@thirdweb-dev/chains';
import { useSingleTokenQuery } from '@app/hooks/v3/useSingleTokenQuery';

async function fetcher(
  network: Chain | undefined,
  poolAddress: string | undefined
) {
  if (!network || !poolAddress) {
    return null;
  }

  const poolContract = await getContract({
    address: poolAddress,
    network,
    contractTypeOrAbi: nftPoolAbi
  });

  // @ts-ignore
  const res = await poolContract.call('getPoolInfo');

  const lpContract = await getContract({
    address: res.lpToken,
    network,
    contractTypeOrAbi: vaultAbi.abi
  });
  // @ts-ignore
  const token0 = await lpContract.call('token0');
  // @ts-ignore
  const token1 = await lpContract.call('token1');

  return {
    token0,
    token1,
    lpToken: res.lpToken
  };
}

export function useNftPoolDetails(poolAddress: string | undefined) {
  const { contract } = useContract(poolAddress, nftPoolAbi);
  const chain = useChain();

  const { data, isLoading: isLoadingPool } = useQuery(
    [QueryKeys.NFT_POOL, poolAddress, chain?.chainId],
    () => fetcher(chain, poolAddress),
    {
      enabled: !!contract
    }
  );

  const { data: token0, isLoading: isLoadingToken0 } = useSingleTokenQuery({
    tokenId: data?.token0?.toLowerCase()
  });
  const { data: token1, isLoading: isLoadingToken1 } = useSingleTokenQuery({
    tokenId: data?.token1?.toLowerCase()
  });

  const isLoading = isLoadingPool || isLoadingToken0 || isLoadingToken1;

  return {
    lpToken: data?.lpToken,
    token0,
    token1,
    isLoading
  };
}
