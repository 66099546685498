import { Button } from '@chakra-ui/react';

export const CancelNftListButton = () => {
  return (
    <Button
      fontSize="16px"
      fontWeight="500"
      lineHeight="26px"
      color="white"
      padding="8px 32px"
      borderRadius="8px"
      maxWidth="99px"
      backgroundColor="gray.11"
    >
      CANCEL
    </Button>
  );
};
