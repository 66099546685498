import React, { FC } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { SubmitButton } from '@app/components/SubmitButton';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';
import { useStakeIntoNitroPool } from '@app/screens/positions/components/SpNftControls/StakeToNitroPool/hooks';
import { PairToken } from '@app/types/pool';
import { Vault } from '@app/hooks/plugins/useVaults';
import { StakeIntoNitroPoolDetails } from '@app/screens/positions/components/SpNftControls/StakeToNitroPool/StakeIntoNitroPoolDetails';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';

interface Props {
  deposit: string;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  poolAddress: string;
  nitroPoolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;

  token0Symbol: string | undefined;
  token1Symbol: string | undefined;
  tvl: string;
  apr: string;
  endTime: Date;
  minimumAmount: string;
  minimumLock: string;
  lockedUntil: string;
}

export const StakeIntoNitroStep: FC<Props> = ({
  onClose,
  tokenId,
  poolAddress,
  onResult,
  nitroPoolAddress,
  token0Symbol,
  token1Symbol,
  tvl,
  apr,
  endTime,
  minimumLock,
  minimumAmount,
  lockedUntil
}) => {
  const isMismatchedNetwork = useNetworkMismatch();

  const { submit, submitting } = useStakeIntoNitroPool(
    poolAddress,
    tokenId,
    nitroPoolAddress
  );

  const queryClient = useQueryClient();

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        disabled={submitting}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction confirmed`,
                <ExternalLink txHash={txHash} />
              );

              await queryClient.invalidateQueries({
                queryKey: [QueryKeys.NITRO_POSITIONS_LIST]
              });

              await queryClient.invalidateQueries({
                queryKey: [QueryKeys.SP_NFTS_POSITIONS]
              });

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Stake"
      />
    );
  }

  return (
    <>
      <StakeIntoNitroPoolDetails
        token0Symbol={token0Symbol}
        token1Symbol={token1Symbol}
        tvl={tvl}
        apr={apr}
        endTime={endTime}
        minimumAmount={minimumAmount}
        minimumLock={minimumLock}
        lockedUntil={lockedUntil}
      />
      <HStack mt={6}>
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
          w="100%"
        >
          Cancel
        </Button>
        {renderSubmit()}
      </HStack>
    </>
  );
};
