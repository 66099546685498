import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from 'src/constants/queryKeys';

import { getContract, useAddress, useChain } from '@thirdweb-dev/react';
import { Chain, Mode } from '@thirdweb-dev/chains';

import nftPoolFactoryAbi from 'abis/nftPoolFactory.json';
import uniq from 'lodash/uniq';
import { getConfig } from '@app/config';
import { isSupportedChain } from '@app/helpers/chain';

type QueryParams = {
  tokens: string[] | undefined;
};

const fetcher = async (
  params: QueryParams,
  network: Chain | undefined,
  walletAddress: string | undefined
) => {
  const config = getConfig(network?.chainId);

  if (
    !config?.CONTRACTS.NFT_POOL_FACTORY ||
    !network ||
    !walletAddress ||
    !params.tokens?.length
  ) {
    return [];
  }

  const { tokens } = params;

  const poolFactoryContract = await getContract({
    address: config?.CONTRACTS.NFT_POOL_FACTORY,
    network,
    contractTypeOrAbi: nftPoolFactoryAbi
  });

  return Promise.all(
    uniq(tokens).map(async token => {
      // @ts-ignore
      const res = await poolFactoryContract.call('getPool', [token]);

      return {
        tokenAddress: token,
        poolAddress: res
      };
    })
  );
};

type PoolAddress = {
  tokenAddress: string;
  poolAddress: string;
};

export const usePoolAddresses = (
  params: QueryParams,
  options: UseQueryOptions<PoolAddress[]> = {}
) => {
  const address = useAddress();
  const _chain = useChain();
  const chain = isSupportedChain(_chain?.chainId) ? _chain : Mode;

  return useQuery<PoolAddress[]>(
    [QueryKeys.SP_NFTS_POSITIONS, { params }, chain?.chainId, address],
    async () => {
      return fetcher(params, chain, address);
    },
    {
      staleTime: 60000,
      ...options
    }
  );
};
