import React, { FC } from 'react';
import { TokensPair } from '@app/components/TokensPair';
import { TokenIcon } from '@app/components/TokenIcon';

interface Props {
  size: 'lg' | 'md' | 'sm';
  token0Symbol: string;
  token1Symbol: string | undefined;
}

export const TokensLogoSection: FC<Props> = ({
  token0Symbol,
  token1Symbol,
  size = 'md'
}) => {
  const dimension = size === 'lg' ? '48px' : size === 'md' ? '32px' : '18px';

  return token1Symbol ? (
    <TokensPair
      size={size}
      token0Symbol={token0Symbol}
      token1Symbol={token1Symbol}
    />
  ) : (
    <TokenIcon symbol={token0Symbol} width={dimension} height={dimension} />
  );
};
