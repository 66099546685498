import React, { FC, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useToggle } from 'react-use';
import { PairToken } from '@app/types/pool';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { Vault } from '@app/hooks/plugins/useVaults';
import { AddToPositionModal } from '@app/screens/booster/components/AddToPosition/AddToPositionModal';
import { Tooltip } from '@app/components/Tooltip';
import { GoRocket } from 'react-icons/go';

interface Props {
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  onOpen?: (val: boolean) => void;
  showLabel?: boolean;
  totalApr: number | undefined;
  lockBonusApr: number | undefined;
  farmBaseApr: number | undefined;
  swpaFeesApr: number | undefined;
  disabled: boolean;
}

export const BoostPosition: FC<Props> = ({
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  onOpen,
  showLabel,
  totalApr,
  lockBonusApr,
  farmBaseApr,
  swpaFeesApr,
  disabled
}) => {
  const [feedback, setFeedback] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <Tooltip tooltip="Boost Position" disabled={disabled}>
        <Button
          isDisabled={disabled}
          variant="secondary"
          onClick={e => {
            e.stopPropagation();
            toggleOpen(true);
            onOpen?.(true);
          }}
          _hover={{
            color: 'orange'
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '12px',
            width: '100%',
            gap: 1
          }}
        >
          <GoRocket size="16px" />
          {showLabel && 'Boost'}
        </Button>
      </Tooltip>
      <AddToPositionModal
        isOpen={open}
        onClose={() => {
          toggleOpen(false);
          onOpen?.(false);
        }}
        tokenDetails={tokenDetails}
        tokenId={tokenId}
        deposit={deposit}
        poolAddress={poolAddress}
        onResult={res => setFeedback(res)}
        totalApr={totalApr}
        lockBonusApr={lockBonusApr}
        farmBaseApr={farmBaseApr}
        swpaFeesApr={swpaFeesApr}
      />
      <TransactionResultModal
        isOpen={!!feedback}
        status={feedback?.status}
        title={feedback?.title}
        message={feedback?.message}
        txHash={feedback?.txHash}
        onClose={() => {
          setFeedback(null);
          toggleOpen(false);
          onOpen?.(false);
        }}
      />
    </>
  );
};
