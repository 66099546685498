import React, { FC } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  HStack,
  Button
} from '@chakra-ui/react';
import { PairToken } from '@app/types/pool';
import { DebouncedInput } from '@app/components/DebouncedInput';
import { Icon } from '@app/components/Icon';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import { TransactionError } from '@thirdweb-dev/react';
import { SubmitButton } from '@app/components/SubmitButton';
import { NetworkMismatchButton } from '@app/components/NetworkMismatchButton';
import { useLockPosition } from '@app/screens/positions/components/SpNftControls/LockPosition/hooks';
import { Vault } from '@app/hooks/plugins/useVaults';
import { KpNFTControlsHeader } from '@app/screens/positions/components/SpNftControls/KpNFTControlsHeader';
import { InfoRow } from '@app/screens/positions/components/SpNftControls/InfoRow/InfoRow';
import { formatNumber } from '@app/helpers/format';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  poolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;
}

export const LockPositionModal: FC<Props> = ({
  isOpen,
  onClose,
  tokenDetails,
  tokenId,
  deposit,
  poolAddress,
  onResult
}) => {
  const isMismatchedNetwork = useNetworkMismatch();
  const isLpToken = 'lpToken' in tokenDetails;
  const {
    state,
    resetStateValues,
    submit,
    submitting,
    handleDurationChange,
    getLockDuration
  } = useLockPosition(tokenDetails, poolAddress, tokenId, isOpen);

  function renderSubmit() {
    if (isMismatchedNetwork) {
      return <NetworkMismatchButton />;
    }

    return (
      <SubmitButton
        mt={0}
        onClick={async () => {
          try {
            const res = await submit();

            if (res['reason'] !== undefined) {
              showErrorToast(res.reason);
            } else if (res instanceof Error) {
              showErrorToast(res.message);
            } else {
              // resetStateValues();

              const txHash = res?.receipt?.transactionHash;

              showSuccessToast(
                `Transaction Confirmed`,
                <ExternalLink txHash={txHash} />
              );

              await getLockDuration();

              onResult({
                status: 'success',
                title: 'Transaction Confirmed',
                message: 'Your transaction has been successfully completed',
                txHash
              });
            }
          } catch (e) {
            if (e instanceof TransactionError) {
              showErrorToast(e.reason);
            } else {
              showErrorToast(`Transaction error`);
            }

            onResult({
              status: 'error',
              title: 'Transaction Error',
              message:
                'There was an error processing your transaction. Please check the details and try again'
            });
          }
        }}
        isLoading={submitting}
        label="Lock"
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '0px' }}>
        <Box p="24px">
          <KpNFTControlsHeader
            tokenDetails={tokenDetails}
            tokenId={tokenId}
            title="Lock your position"
            subtitle="Provide long-term liquidity to increase your yield"
          />
          <HStack justifyContent="space-between" mt={6}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Lock duration
            </Text>
            <HStack w="100%" justifyContent="flex-end">
              <Button
                variant="tertiary"
                onClick={() => {
                  if (+state.duration !== 0) {
                    handleDurationChange((+state.duration - 1).toString());
                  }
                }}
                sx={{
                  color: 'orange',
                  bg: 'gray.2',
                  width: 'fit-content',
                  fontSize: '12px',
                  padding: '4px 12px'
                }}
              >
                <Icon name="minus" />
              </Button>
              <HStack
                sx={{
                  paddingRight: '14px',
                  borderRadius: '10px',
                  border: '1px solid',
                  borderColor: 'gray.5',
                  background: 'black',
                  flex: 0.5
                }}
              >
                <DebouncedInput
                  amount={state.duration}
                  onChange={handleDurationChange}
                  placeholder="0"
                />
              </HStack>
              <Button
                variant="tertiary"
                onClick={() => {
                  handleDurationChange((+state.duration + 1).toString());
                }}
                sx={{
                  color: 'orange',
                  bg: 'gray.2',
                  width: 'fit-content',
                  fontSize: '12px',
                  padding: '4px 12px'
                }}
              >
                <Icon name="plus" />
              </Button>
              <Text fontSize="16px" fontWeight={400} lineHeight="26px">
                days
              </Text>
            </HStack>
          </HStack>

          <HStack justifyContent="space-between" mt={6}>
            <Text fontSize="16px" fontWeight={400} lineHeight="26px">
              Estimates
            </Text>
          </HStack>
          <InfoRow
            label="Deposit value"
            value0={`${formatNumber(+deposit)} ${
              isLpToken ? tokenDetails.lpToken.symbol : tokenDetails.symbol
            }`}
          />
          <HStack mt={6}>
            <Button
              variant="secondary"
              py="10px"
              px="20px"
              borderRadius="12px"
              onClick={() => {
                resetStateValues();
                onClose();
              }}
              w="100%"
            >
              Cancel
            </Button>
            {renderSubmit()}
          </HStack>
        </Box>
      </ModalContent>
    </Modal>
  );
};
