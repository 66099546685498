import React, { FC, useMemo, useState } from 'react';
import { useInfiniteTokensList } from '@app/hooks/swap/useInfiniteTokensList';
import {
  Box,
  Grid,
  HStack,
  ModalCloseButton,
  ModalHeader,
  Text,
  VStack
} from '@chakra-ui/react';
import { Search } from '@app/components/Search';
import s from '@app/components/Swap/components/TokenSelector/TokenSelector.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { TokenIcon } from '@app/components/TokenIcon';
import Link from 'next/link';
import { formatWalletAddress } from '@app/helpers/format';
import { CopyButton } from '@app/components/CopyButton';
import { StarIcon } from '@chakra-ui/icons';
import { Token } from '@app/types/token';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';
import { useVaults, Vault } from '@app/hooks/plugins/useVaults';
import { TokensPair } from '@app/components/TokensPair';

interface Props {
  onClose: () => void;
  onSelect: (token: Token | Vault) => void;
}

export const SelectTokenContent: FC<Props> = ({ onClose, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { explorer } = useChainExplorer();

  const {
    dataFlat,
    hasNextPage,
    fetchNextPage,
    totalLoaded,
    isFetching,
    isLoading
  } = useInfiniteTokensList(
    {
      q: searchTerm
    },
    true
  );

  const { data } = useVaults();

  const list = useMemo(() => {
    if (!dataFlat || !data) {
      return [];
    }

    const vaultPairs = data.filter(vault => {
      if (!searchTerm) {
        return true;
      }

      return (
        vault.token0.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vault.token1.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    return [...vaultPairs, ...dataFlat];
  }, [data, dataFlat, searchTerm]);

  return (
    <>
      <ModalHeader>
        <HStack justify="space-between" px={0} py={0} mb="16px">
          <Text fontSize="16px" color="white" fontWeight="500">
            Select token
          </Text>
          <ModalCloseButton
            onClick={() => {
              // setSearchTerm('');
              onClose();
            }}
          />
        </HStack>
      </ModalHeader>

      <VStack>
        <Search onSearch={val => setSearchTerm(val)} />
        <Box
          className={s.scrollWrapper}
          id="scrollableDiv"
          overflowY="auto"
          height={400}
          mt={4}
          w="100%"
        >
          <InfiniteScroll
            dataLength={totalLoaded}
            next={fetchNextPage}
            hasMore={hasNextPage ?? false}
            loader={<ListLoader />}
            scrollableTarget="scrollableDiv"
          >
            {isLoading ? (
              <ListLoader />
            ) : (
              list?.map(item => {
                return (
                  <Grid
                    onClick={() => {
                      onSelect(item);
                      // setSearchTerm('');
                    }}
                    style={{
                      cursor: 'pointer',
                      transition: 'background 0.2s ease-out'
                    }}
                    key={
                      'contractAddress' in item
                        ? item.contractAddress
                        : item.lpToken.id
                    }
                    gridTemplateColumns="64px 1fr 24px"
                    gridColumnGap="8px"
                    gridRowGap={0}
                    borderBottom="1px solid"
                    borderColor="whiteOpacity.6"
                    alignItems="center"
                    _hover={{ background: 'neutral.700' }}
                    w="100%"
                    gridTemplateAreas="'icon source details' 'icon symbol details'"
                    py="12px"
                    px="12px"
                  >
                    {'contractAddress' in item ? (
                      <>
                        <TokenIcon
                          symbol={item.symbol}
                          iconUrl={item.logoUri}
                          gridArea="icon"
                          width="40px"
                          height="40px"
                        />
                        <Text
                          fontSize="16px"
                          lineHeight="21px"
                          fontWeight={500}
                          color="neutral.100"
                          textTransform="capitalize"
                          gridArea="source"
                          textAlign="left"
                        >
                          {item.name}
                        </Text>
                        <HStack gridArea="symbol">
                          <Text
                            fontSize="14px"
                            color="neutral.400"
                            textTransform="capitalize"
                            textAlign="left"
                          >
                            {item.symbol}
                          </Text>
                          <Box width="1px" height="20px" bg="neutral.500" />
                          <Link
                            onClick={e => e.stopPropagation()}
                            href={`${explorer?.url}/address/${item.contractAddress}`}
                            target="_blank"
                          >
                            <Text
                              fontSize="14px"
                              color="neutral.400"
                              textTransform="capitalize"
                              textAlign="left"
                              _hover={{ color: 'neutral.100' }}
                            >
                              {formatWalletAddress(item.contractAddress)}
                            </Text>
                          </Link>
                          <CopyButton text={item.contractAddress} />
                        </HStack>
                        {item.whitelisted && (
                          <StarIcon
                            gridArea="details"
                            w="18px"
                            h="18px"
                            color="white"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <TokensPair
                          token0Symbol={item.token0.symbol}
                          token1Symbol={item.token1.symbol}
                        />
                        <Text
                          fontSize="16px"
                          lineHeight="21px"
                          fontWeight={500}
                          color="neutral.100"
                          textTransform="capitalize"
                          gridArea="source"
                          textAlign="left"
                        >
                          {item.token0.symbol}-{item.token1.symbol}
                        </Text>
                        <HStack gridArea="symbol">
                          <Text
                            fontSize="14px"
                            color="neutral.400"
                            textTransform="capitalize"
                            textAlign="left"
                          >
                            {item.lpToken.symbol}
                          </Text>
                          <Box width="1px" height="20px" bg="neutral.500" />
                          <Link
                            onClick={e => e.stopPropagation()}
                            href={`${explorer?.url}/address/${item.lpToken.id}`}
                            target="_blank"
                          >
                            <Text
                              fontSize="14px"
                              color="neutral.400"
                              textTransform="capitalize"
                              textAlign="left"
                              _hover={{ color: 'neutral.100' }}
                            >
                              {formatWalletAddress(item.lpToken.id)}
                            </Text>
                          </Link>
                          <CopyButton text={item.lpToken.id} />
                        </HStack>
                      </>
                    )}
                  </Grid>
                );
              })
            )}
          </InfiniteScroll>
        </Box>
      </VStack>
    </>
  );
};
