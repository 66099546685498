import localforage from 'localforage';
import { useCallback, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { NFT_TOKENS_KEY } from '@app/constants/swap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useAddress } from '@thirdweb-dev/react';

export type NftTokens = string[];

export async function getNftTokens(address: string | undefined) {
  if (!address) {
    return [];
  }

  const tokens = await localforage.getItem(`${NFT_TOKENS_KEY}__${address}`);

  return (tokens ?? []) as NftTokens;
}

export function useNftTokens(address: string | undefined): {
  tokens: NftTokens | undefined;
  updateNftTokens: (values: NftTokens) => Promise<void>;
} {
  const queryClient = useQueryClient();

  const { data } = useQuery([QueryKeys.FETCH_NFT_TOKENS, address], () =>
    getNftTokens(address)
  );

  const updateNftTokens = useCallback(
    async (values: NftTokens) => {
      await localforage.setItem(`${NFT_TOKENS_KEY}__${address}`, values);
      await queryClient.invalidateQueries([QueryKeys.FETCH_NFT_TOKENS]);
    },
    [address, queryClient]
  );

  return {
    tokens: data,
    updateNftTokens
  };
}
