import React, { FC, useEffect } from 'react';
import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps
} from '@chakra-ui/react';
import s from './MultiStepNitroStake.module.scss';
import { LockPositionStep } from '@app/screens/positions/components/MultiStepNitroStake/components/LockPositionStep/LockPositionStep';
import { PairToken } from '@app/types/pool';
import { Vault } from '@app/hooks/plugins/useVaults';
import { StakeIntoNitroStep } from '@app/screens/positions/components/MultiStepNitroStake/components/StakeIntoNitroStep';

const steps = [
  { title: 'Lock your position' },
  { title: 'Stake into a Nitro pool' }
];

interface Props {
  onClose: () => void;
  tokenDetails: PairToken | Vault;
  tokenId: string;
  deposit: string;
  reqLockDurationInDays: number;
  lockDurationInDays: number;
  poolAddress: string;
  onResult: (res: {
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  }) => void;

  token0Symbol: string | undefined;
  token1Symbol: string | undefined;
  tvl: string;
  apr: string;
  endTime: Date;
  minimumAmount: string;
  minimumLock: string;
  lockedUntil: string;

  nitroPoolAddress: string;
}

export const MultiStepNitroStake: FC<Props> = ({
  onClose,
  tokenDetails,
  tokenId,
  deposit,
  reqLockDurationInDays,
  lockDurationInDays,
  poolAddress,
  onResult,
  token0Symbol,
  token1Symbol,
  tvl,
  apr,
  endTime,
  minimumAmount,
  minimumLock,
  lockedUntil,
  nitroPoolAddress
}) => {
  const { activeStep, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length
  });

  useEffect(() => {
    if (activeStep === 0 && lockDurationInDays >= reqLockDurationInDays) {
      setActiveStep(1);
    }
  }, [activeStep, lockDurationInDays, reqLockDurationInDays, setActiveStep]);

  return (
    <Box>
      <Stepper index={activeStep} colorScheme="red" className={s.stepper}>
        {steps.map((step, index) => (
          <Step key={index} className={s.step}>
            <StepIndicator className={s.indicator}>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
              <Box flexShrink="0" className={s.titleWrapper}>
                <StepTitle className={s.title}>{step.title}</StepTitle>
              </Box>
            </StepIndicator>
            <StepSeparator className={s.separator} />
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <LockPositionStep
          onClose={onClose}
          onSuccess={() => {
            if (lockDurationInDays >= reqLockDurationInDays) {
              setActiveStep(1);
            }
          }}
          poolAddress={poolAddress}
          deposit={deposit}
          tokenId={tokenId}
          tokenDetails={tokenDetails}
          reqLockDurationInDays={reqLockDurationInDays}
          lockDurationInDays={lockDurationInDays}
        />
      )}
      {activeStep === 1 && (
        <StakeIntoNitroStep
          nitroPoolAddress={nitroPoolAddress}
          onClose={onClose}
          onResult={onResult}
          poolAddress={poolAddress}
          deposit={deposit}
          tokenId={tokenId}
          tokenDetails={tokenDetails}
          token0Symbol={token0Symbol}
          token1Symbol={token1Symbol}
          tvl={tvl}
          apr={apr}
          endTime={endTime}
          minimumAmount={minimumAmount}
          minimumLock={minimumLock}
          lockedUntil={lockedUntil}
        />
      )}
    </Box>
  );
};
