import React, { FC } from 'react';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { toOptionalFixed } from '@app/helpers/format';
import { formatUSD } from '@app/helpers/formatUSD';
import styles from './AmountSection.module.scss';
import cn from 'clsx';

interface Props {
  amount: number | string;
  usdAmount: number | null;
  classes?: {
    root?: string;
    amount?: string;
    usdAmount?: string;
  };
}

export const AmountSection: FC<Props> = ({ amount, usdAmount, classes }) => {
  return (
    <Flex className={cn(styles.root, classes?.root)}>
      <Text className={cn(styles.amount, classes?.amount)}>
        {toOptionalFixed(+amount, 2)}
      </Text>
      {usdAmount ? (
        <Text className={cn(styles.usdAmount, classes?.usdAmount)}>
          {formatUSD.format(usdAmount)}
        </Text>
      ) : null}
    </Flex>
  );
};
