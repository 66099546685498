import React, { FC, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { Box, Text } from '@chakra-ui/react';
import { useOnClickOutside } from '@app/hooks/other/useOnClickOutside';

type Props = {
  isOpen: boolean;
  referenceElement: HTMLElement | null;
  message: string;
};

export const SellNftTooltip: FC<Props> = ({
  isOpen,
  referenceElement,
  message
}) => {
  const [popperElement, setPopperElement] = useState<HTMLElement>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  const renderTooltip = () => {
    return (
      <Box
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        padding="8px 14px"
        backgroundColor="black"
        borderRadius="10px"
        width="fit-content"
        style={styles.popper}
        {...attributes.popper}
      >
        <Text fontWeight={400} color="white" fontSize="16px" lineHeight="20px">
          {message}
        </Text>
      </Box>
    );
  };

  return (
    referenceElement &&
    ReactDOM.createPortal(
      <AnimatePresence>{isOpen && renderTooltip()}</AnimatePresence>,
      referenceElement
    )
  );
};
