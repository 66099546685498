import { StakingPosition } from '@app/hooks/positions/useStakingPosition';
import { useMemo } from 'react';

export type LockDetails = {
  isLocked: boolean;
  lockedUntilDate: Date | null;
  lockDurationInDays: number;
};

export function useKpNftLockDetails(data: StakingPosition | undefined) {
  return useMemo(() => {
    if (!data) {
      return {
        isLocked: false,
        lockedUntilDate: null,
        lockDurationInDays: 0
      };
    }

    const isLocked =
      new Date() < new Date(data.startLockTime + data.lockDuration);

    const lockedUntilDate = isLocked
      ? new Date(data.startLockTime + data.lockDuration)
      : null;

    return {
      isLocked,
      lockedUntilDate,
      lockDurationInDays: isLocked ? data.lockDuration / 1000 / 24 / 3600 : 0
    };
  }, [data]);
}
