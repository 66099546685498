import { request, gql } from 'graphql-request';

import { PoolToken } from '@app/types/pool';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';

type Variables = {
  tokensSymbols: string[];
};

function getQuery() {
  return gql`
    query ($symbols: [String!]) {
      tokens(where: { symbol_in: $symbols }) {
        symbol
        id
        symbol
        name
        decimals
        derivedMatic
      }
    }
  `;
}

export const fetcher = async (
  symbols: string[],
  config: ApplicationConfig | undefined
) => {
  const query = getQuery();

  return await request<{ tokens: PoolToken[] }>(
    `${config?.URLS.subgraphUrlV3}`,
    query,
    {
      symbols
    }
  );
};

export const useMultipleTokensFromGraph = (
  params: Variables,
  options: UseQueryOptions<PoolToken[] | undefined> = {}
) => {
  const config = useConfig();

  return useQuery<PoolToken[] | undefined>(
    [QueryKeys.TOKENS_PRICES_FROM_GRAPH, { params }],
    async () => {
      const res = await fetcher(params.tokensSymbols, config);

      return res.tokens;
    },
    {
      enabled: !!config,
      ...options
    }
  );
};
