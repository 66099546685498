import { useAddress, useContract } from '@thirdweb-dev/react';
import { useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { PairToken } from '@app/types/pool';
import nftPoolAbi from 'abis/nftPool.json';

export function useTransferPosition(poolAddress: string, tokenId: string) {
  const address = useAddress();
  const [state, setState] = useState<{
    address: string;
  }>({
    address: ''
  });

  const handleAddressChange = useCallback((val: string) => {
    setState(prev => ({
      ...prev,
      address: val
    }));
  }, []);

  const resetStateValues = useCallback(() => {
    setState(prev => ({
      address: ''
    }));
  }, []);

  const { contract: poolContract } = useContract(poolAddress, nftPoolAbi);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    if (!poolContract || !address) {
      return;
    }

    return poolContract?.call('transferFrom', [
      address,
      state.address,
      tokenId
    ]);
  }, [poolContract, address, state.address, tokenId]);

  return {
    state,
    handleAddressChange,
    resetStateValues,
    submit,
    submitting
  };
}
