import { useContract } from '@thirdweb-dev/react-core';
import nftPoolAbi from '../../../abis/nftPool.json';
import { useAsync } from 'react-use';
import { ethers } from 'ethers';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { SmartContract } from '@thirdweb-dev/react';

async function fetcher(
  contract: SmartContract<ethers.BaseContract> | undefined,
  tokenId: string
) {
  if (!contract || !tokenId) {
    return;
  }

  const res = await contract.call('pendingRewards', [tokenId]);

  return ethers.utils.formatUnits(res, 18);
}

export function useKpNFTPendingRewards(
  nftPoolAddress: string,
  tokenId: string
) {
  const { contract } = useContract(nftPoolAddress, nftPoolAbi);

  const { data, isLoading } = useQuery(
    [QueryKeys.KP_NFT_PENDING_REWARDS, nftPoolAddress, tokenId],
    () => fetcher(contract, tokenId),
    {
      enabled: !!contract && !!tokenId
    }
  );

  return {
    data,
    isLoading
  };
}
